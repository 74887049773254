/* eslint-disable react/no-danger */
import React from 'react';
import Document, { Html, Head, Main, NextScript } from 'next/document';
import getConfig from 'next/config';
// import flush from 'styled-jsx/server'; Not possible to use in V5 https://github.com/vercel/styled-jsx/blob/main/Changelog.md#apis
import { flush as rnmq } from 'react-native-media-query';
import { AppRegistry } from 'react-native-web';
import { globalStyles } from '@24i/nxg-sdk-smartott/src/theme/styles/global';
import { getRuntimeConfig } from '@24i/nxg-sdk-smartott/src/Application/initApp';

import fonts from '../../../../../app@smartott/platformAssets/runtime/fonts';

const { publicRuntimeConfig } = getConfig();
const { customScripts = [] } = publicRuntimeConfig;

const toFontFace = ({ fontFamily, file }) => `@font-face {
    font-family: '${fontFamily}';
    src: url('${file.default}');
    font-display: swap;
}`;
export const getInitialProps = ({ renderPage }) => {
    AppRegistry.registerComponent('Main', () => Main);
    const { getStyleElement } = AppRegistry.getApplication('Main');
    const { html, head } = renderPage();

    const styles = [getStyleElement(), /* flush(), */ rnmq()];

    return {
        html,
        head,
        styles: React.Children.toArray(styles),
        runtimeScripts: getRuntimeConfig()?.customScripts,
    };
};

class MyDocument extends Document {
    getScripts = () => {
        let scripts = [];
        if (Array.isArray(customScripts)) {
            scripts = customScripts.map((v) => ({ url: v, async: true }));
        }

        const { runtimeScripts } = this.props;
        if (Array.isArray(runtimeScripts)) {
            scripts = [...scripts, ...runtimeScripts];
        }

        return scripts;
    };

    render() {
        const scripts = this.getScripts();
        const description =
            this.props.metaDescription ||
            'Watch movies and TV shows online or stream right to your smart TV, PC, Mac, mobile, tablet and more.';
        const favHref = this.props.faviconIconName
            ? `/icons/${this.props.faviconIconName}`
            : '/icons/favicon.ico';
        return (
            <Html lang="en">
                <Head>
                    <meta name="description" content={description} />
                    {scripts.map((v, index) => (
                        <script
                            src={v.url}
                            key={`script-key-${index.toString()}`}
                            defer={v.defer || undefined}
                            async={v.async || undefined}
                        />
                    ))}
                    <link rel="shortcut icon" href={favHref} />
                    <link rel="preconnect" href="https://cdn.backstage-api.com" />
                    <link rel="preconnect" href="https://backstage-api.com" />
                    <style
                        dangerouslySetInnerHTML={{
                            __html: `${fonts.map(toFontFace).join('\n')}`,
                        }}
                    />
                    <style dangerouslySetInnerHTML={{ __html: globalStyles }} />
                </Head>
                <body>
                    <Main />
                    <NextScript />
                </body>
            </Html>
        );
    }
}

export default MyDocument;
