import { Interactable, Text, View, ScrollView, ThemeIcon } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import React, { ReactElement, useState } from 'react';
import Modal from '@24i/nxg-sdk-gluons/src/components/containers/Modal';
import getStyles from '../styles';
import { SelectViewProps } from './types';

const Select: <V>(props: SelectViewProps<V>) => ReactElement = ({
    title,
    options,
    initialValue,
    onChange,
}) => {
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedValue, setSelectedValue] = useState(
        initialValue
            ? options.find((option) => option.value === initialValue)?.value ?? options[0].value
            : options[0].value
    );

    const selectedItem = options.find((option) => option.value === selectedValue);

    const handleOnChange = (value: typeof options[0]['value']) => {
        setSelectedValue(value);
        setIsModalVisible(false);
        onChange(value);
    };

    return (
        <>
            <Interactable onPress={() => setIsModalVisible(true)} activeOpacity={1}>
                <View style={styles.pickerRow}>
                    <Text style={styles.pickerTitleText}>{title}</Text>
                    <View style={styles.currentLanguage}>
                        <Text style={styles.pickerButtonText}>{selectedItem?.title}</Text>

                        <ThemeIcon
                            iconName="onPrimary-select"
                            size={20}
                            iconStyles={styles.expandMoreIcon}
                        />
                    </View>
                </View>
            </Interactable>
            <Modal
                visible={isModalVisible}
                blurType="dark"
                onRequestClose={() => setIsModalVisible(false)}
            >
                <ScrollView style={{ margin: 'auto' }}>
                    <View style={styles.modalContent}>
                        {options.map((option) => {
                            const isSelected = option.value === selectedValue;

                            return (
                                <View style={{ marginBottom: 48 }} key={String(option.value)}>
                                    <Interactable
                                        onPress={() => handleOnChange(option.value)}
                                        activeOpacity={1}
                                        testID={option.testID}
                                    >
                                        <View style={styles.textContent}>
                                            <Text
                                                style={
                                                    isSelected
                                                        ? styles.selectedPickerItem
                                                        : styles.pickerItem
                                                }
                                            >
                                                {option.title}
                                            </Text>
                                            {option.subtitle && (
                                                <Text
                                                    style={
                                                        isSelected
                                                            ? styles.selectedPickerSubtitleItem
                                                            : styles.pickerSubtitleItem
                                                    }
                                                >
                                                    {option.subtitle}
                                                </Text>
                                            )}
                                        </View>
                                    </Interactable>
                                </View>
                            );
                        })}
                    </View>
                </ScrollView>
            </Modal>
        </>
    );
};

export default Select;
