import { rgbaToHex, transformColorToArgb as utilsTransformColorToArgb } from '@24i/nxg-core-utils';
import {
    LandscapeCellStyles,
    LandscapeContainerStyles,
    LandscapeCurrentTimeStyles,
    LandscapeSidebarStyles,
    LandscapeStyles,
    LandscapeTimelineStyles,
} from '@24i/nxg-sdk-epg/src/types';
import { getBorderRadius, getFont } from '@24i/nxg-sdk-higgs';
import { Breakpoint } from '@24i/nxg-sdk-smartott/src/utils/styles/constants';
import { Theme } from '@24i/nxg-sdk-photon';
import { isPlatformAndroid } from 'renative';
import { LandscapeStylesGetterProps } from './types';

const transformColorToArgb = (color: string) => {
    if (isPlatformAndroid) return utilsTransformColorToArgb(color);
    return color;
};

export const defaultGetContainerStyles = (theme: Theme): LandscapeContainerStyles => ({
    grid: {
        backgroundColor: isPlatformAndroid ? '#00ffffff' : 'transparent',
    },
    divider: {
        height: 1,
        top: -1,
        position: 'relative',
        backgroundColor: transformColorToArgb(theme.color.contrast4),
        zIndex: 10,
    },
    noDataStyle: {
        color: 'white',
        textAlign: 'center',
        padding: 10,
    },
});

export const defaultGetCellStyles = (theme: Theme): LandscapeCellStyles => ({
    container: {
        backgroundColor: transformColorToArgb(`${rgbaToHex(theme.color.epgRowInactive)}`),
        borderRadius: 6,
        ...getBorderRadius(theme.elements, 'epgItem-cornerRadius'),
        height: 72,
        paddingHorizontal: 15,
        borderStyle: 'solid',
        borderWidth: isPlatformAndroid ? 3 : 2,
        borderColor: transformColorToArgb(theme.color.epgBackground),
    },
    activeContainer: {
        backgroundColor: transformColorToArgb(`${rgbaToHex(theme.color.epgRowActive)}`),
    },
    activePassedContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        height: '100%',
        backgroundColor: transformColorToArgb(`${rgbaToHex(theme.color.epgRowActive)}`),
    },
    title: {
        ...getFont(theme, 'h4'),
        color: transformColorToArgb(theme.color.textPrimary),
        width: '90%',
    },
    infoContainer: {
        flexDirection: 'column',
        width: '100%',
        paddingHorizontal: 18,
    },
    titleContainerWithIcon: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    icon: {
        width: 15,
        height: 15,
    },
    lockedTitle: {
        color: theme.color.textPrimary, // Color not added in Backstage theme section
    },
    description: {
        color: transformColorToArgb(`${theme.color.textPrimary}99`),
        ...getFont(theme, 'h6'),
    },
});

export const defaultGetSidebarStyles = (
    theme: Theme,
    screenWidth?: number
): LandscapeSidebarStyles => {
    const width =
        screenWidth != null && screenWidth <= Breakpoint.SM ? (screenWidth / 100) * 8 : 244;

    return {
        container: {
            maxWidth: 244,
            minWidth: 88,
            width,

            /*
        FIXME: according to the designs, background color for the sidebar should be darkerBlur1, but in the current layout implementation of the EPG
        this would cause the sidebar to show under the timeline when sliding up
        */

            // backgroundColor: transformColorToArgb(theme.color.darkerBlur1.color),
            backgroundColor: transformColorToArgb(theme.color.darker1),
        },
        text: {
            ...getFont(theme, 'h5'),
            color: transformColorToArgb(theme.color.textPrimary),
            fontWeight: 'bold',
            maxWidth: 155,
            marginLeft: 14,
            opacity: 0.6,
        },
        logo: {
            borderRadius: 4,
            width: 48,
            height: 48,
        },
    };
};

export const defaultGetTimelineStyles = (theme: Theme): LandscapeTimelineStyles => ({
    container: {
        backgroundColor: transformColorToArgb(theme.color.darkerBlur1.color),
        borderBottomWidth: 0.7,
        borderStyle: 'solid',
        borderBottomColor: transformColorToArgb(theme.color.contrast4),
        height: 40,
    },
    text: {
        ...getFont(theme, 'h6'),
        color: transformColorToArgb(theme.color.textPrimary),
    },
    hoursLine: {
        fontSize: 6,
        color: transformColorToArgb(theme.color.textPrimary),
        opacity: 0.3,
        top: 23,
    },
});

export const defaultGetCurrentTimeStyles = (theme: Theme): LandscapeCurrentTimeStyles => ({
    line: {
        zIndex: 99,
        position: 'absolute',
        top: 0,
        opacity: 0.6,
        width: 2,
        backgroundColor: transformColorToArgb(theme.color.progressBarLive),
    },
    arrow: {
        width: 0,
        height: 0,
        zIndex: 99,
        position: 'absolute',
        borderWidth: 6,
        borderStyle: 'solid',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderTopColor: transformColorToArgb(theme.color.progressBarLive),
        borderBottomWidth: 0,
    },
    label: {
        backgroundColor: transformColorToArgb(theme.color.progressBarLive),
        borderRadius: 4,
        ...getBorderRadius(theme.elements, 'label-cornerRadius'),
        height: 20,
        bottom: 0,
        width: 50,
        shadowColor: 'rgba(0,0,0, 0.36)',
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowRadius: 6.68,
        elevation: 11,
    },
    labelText: {
        color: transformColorToArgb(theme.color.textPrimary),
        ...getFont(theme, 'h6'),
        textAlign: 'center',
        width: '100%',
    },
});

export const defaultGetLandscapeStyles = (
    theme: Theme,
    {
        getLandscapeCellStyles = defaultGetCellStyles,
        getLandspaceSidebarStyles = defaultGetSidebarStyles,
        getLandscapeTimelineStyles = defaultGetTimelineStyles,
        getLandscapeCurrentTimeStyles = defaultGetCurrentTimeStyles,
        getLandscapeContainerStyles = defaultGetContainerStyles,
    }: LandscapeStylesGetterProps,
    screenWidth?: number
): LandscapeStyles => ({
    ...getLandscapeContainerStyles(theme),
    cell: getLandscapeCellStyles(theme),
    sidebar: getLandspaceSidebarStyles(theme, screenWidth),
    timeline: getLandscapeTimelineStyles(theme),
    currentTime: getLandscapeCurrentTimeStyles(theme),
});
