import { TextStyle, ViewStyle } from 'react-native';
import { getFont, getBorderRadius } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';

interface PickerButtonStyle {
    container: ViewStyle;
    wrapper: ViewStyle;
    maskedView: ViewStyle;
    maskElement: ViewStyle;
    title: TextStyle;
    linearGradient: ViewStyle;
    mainIcon: ViewStyle;
    dropdownIcon: ViewStyle;
}

const PickerButton = (theme: Theme): PickerButtonStyle => ({
    container: {
        flex: 1,
        marginRight: 8,
    },
    wrapper: {
        maxWidth: '45%',
        height: 40,
        paddingHorizontal: 12,
        backgroundColor: theme.color.buttonTertiary,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        ...getBorderRadius(theme.elements, 'textField-cornerRadius'),
    },
    maskedView: {
        flex: 1,
        marginLeft: 6,
    },
    maskElement: {
        flex: 1,
        justifyContent: 'center',
    },
    title: {
        ...getFont(theme, 'tertiaryButton'),
        color: theme.color.textButtonTertiary,
        flex: 1,
        marginLeft: 6,
    },
    linearGradient: {
        flex: 1,
    },
    mainIcon: {
        transform: [{ rotateY: '180deg' }],
    },
    dropdownIcon: {
        opacity: 0.6,
    },
});

export default PickerButton;
