import { extendGuard } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { Episode, EpisodeGuard } from './episode';
import { ASSET_TYPE } from '../enums';
import { Asset, AssetGuard } from './asset';

const NewsSeriesGuard = extendGuard(
    'NewsSeries',
    AssetGuard,
    { type: t.literal(ASSET_TYPE.NEWS_SERIES) },
    {}
);

export type NewsSeries = t.TypeOf<typeof NewsSeriesGuard>;

export const getIsNewsSeries = (asset: Asset): asset is NewsSeries =>
    asset && asset.type === ASSET_TYPE.NEWS_SERIES;

export type AssetPossiblyNewsEpisode = Asset & Partial<Pick<Episode, 'seriesType'>>;

const NewsEpisodeGuard = extendGuard(
    'NewsEpisode',
    EpisodeGuard,
    { seriesType: t.literal('news_series') },
    {}
);
export type NewsEpisode = t.TypeOf<typeof NewsEpisodeGuard>;

export const getIsNewsEpisode = (asset: AssetPossiblyNewsEpisode): asset is NewsEpisode =>
    asset.type === ASSET_TYPE.EPISODE && asset.seriesType === ASSET_TYPE.NEWS_SERIES;

export const NEWS_SERIES_BACKSTAGE_LABEL = 'news-series';
