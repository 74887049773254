import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { Payload, VideoCompleteResult } from '../types';
import { EVENTS } from '../constants';
import { mapBase } from './mapBase';

export const mapVideoComplete = (
    triggerName: ANALYTICS_TRIGGERS,
    payload: Payload
): VideoCompleteResult => {
    return {
        ...mapBase(payload),
        action: EVENTS.VIDEO_COMPLETE,
        event_trigger: triggerName,
        thing_id: payload.payload.asset?.id || '',
        progress_pct: 90,
        duration: payload.payload.duration,
    };
};
