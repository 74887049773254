var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.useShared=exports.isOrganization=exports.isBackstageService=void 0;var _regenerator=_interopRequireDefault(require("@babel/runtime/regenerator"));var _toConsumableArray2=_interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));var _slicedToArray2=_interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));var _react=require("react");

var _renative=require("renative");
var _isEmpty=_interopRequireDefault(require("lodash/isEmpty"));
var _reactNativeRestart=_interopRequireDefault(require("react-native-restart"));
var _reactI18next=require("react-i18next");








var _initApp=require("@24i/nxg-sdk-smartott/src/Application/initApp");
var _logger=require("@24i/nxg-core-utils/src/logger");
var _AppSettingsData=require("@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData");
var _ApplicationStore=require("@24i/nxg-sdk-smartott/src/context/ApplicationStore");
var _NextRouter=require("@24i/nxg-core-router/src/NextRouter");

var _useUserData=require("../../../../hooks/useUserData");
var _utils=require("../../../../utils");


var isOrganization=function isOrganization(org){
return org&&org.organisationId==='string';
};exports.isOrganization=isOrganization;

var isBackstageService=function isBackstageService(serv){
return serv&&serv.organisationId&&serv.id;
};exports.isBackstageService=isBackstageService;

var useShared=function useShared(){var _getRuntimeConfig,_getRuntimeConfig$ser,_getRuntimeConfig$ser2;
var router=(0,_NextRouter.useRouter)();
var userDataClient=(0,_useUserData.useExperienceUserData)();
var initialApiService=(0,_utils.getInitialApiService)();
var isBackstageLoginEnabled=Boolean((_getRuntimeConfig=
(0,_initApp.getRuntimeConfig)('features'))==null?void 0:(_getRuntimeConfig$ser=_getRuntimeConfig.serviceSwitcher)==null?void 0:(_getRuntimeConfig$ser2=_getRuntimeConfig$ser.backstageLogin)==null?void 0:_getRuntimeConfig$ser2.enabled
);
var _useTranslation=(0,_reactI18next.useTranslation)(),t=_useTranslation.t;
var _useAppSettingsData=(0,_AppSettingsData.useAppSettingsData)(),appSettingsDataClient=_useAppSettingsData.client;
var _useStore=





(0,_ApplicationStore.useStore)(),backstageUserData=_useStore.backstageUserData,setBackstageUserData=_useStore.setBackstageUserData,setServiceData=_useStore.setServiceData,setSelectedUserProfile=_useStore.setSelectedUserProfile,serviceData=_useStore.serviceData;
var defaultServices=(0,_utils.getDefaultApiServices)();

var _useState=(0,_react.useState)(

undefined),_useState2=(0,_slicedToArray2.default)(_useState,2),organisationServices=_useState2[0],setOrganisationServices=_useState2[1];
var _useState3=(0,_react.useState)(),_useState4=(0,_slicedToArray2.default)(_useState3,2),selectedService=_useState4[0],setSelectedService=_useState4[1];
var _useState5=(0,_react.useState)(undefined),_useState6=(0,_slicedToArray2.default)(_useState5,2),serviceOptions=_useState6[0],setServiceOptions=_useState6[1];

var reload=function reload(){
if(_renative.isPlatformWeb){
router.reload();
}else{
_reactNativeRestart.default.Restart();
}
};

var getBackstageServices=function getBackstageServices(){var services,list;return _regenerator.default.async(function getBackstageServices$(_context2){while(1)switch(_context2.prev=_context2.next){case 0:
services=[];_context2.next=3;return _regenerator.default.awrap(

userDataClient.getBackstageOrganisationListForUser());case 3:list=_context2.sent;if(
(0,_isEmpty.default)(list)){_context2.next=7;break;}_context2.next=7;return _regenerator.default.awrap(
Promise.all(
list.data.map(function _callee(organisation){var backstageServices;return _regenerator.default.async(function _callee$(_context){while(1)switch(_context.prev=_context.next){case 0:_context.next=2;return _regenerator.default.awrap(
userDataClient.getBackstageServicesForUser(
organisation.organisationId
));case 2:backstageServices=_context.sent;
setOrganisationServices(backstageServices);
services=(0,_toConsumableArray2.default)(backstageServices);case 5:case"end":return _context.stop();}},null,null,null,Promise);}
)
));case 7:return _context2.abrupt("return",

services);case 8:case"end":return _context2.stop();}},null,null,null,Promise);};


var getSelectedService=function getSelectedService(){var backstageService,service,defaultSelectedService;return _regenerator.default.async(function getSelectedService$(_context3){while(1)switch(_context3.prev=_context3.next){case 0:if(!(
backstageUserData&&organisationServices)){_context3.next=5;break;}
backstageService=organisationServices.find(
function(backsStageService){var _appSettingsDataClien;return(
backsStageService.id===(appSettingsDataClient==null?void 0:(_appSettingsDataClien=appSettingsDataClient.opts)==null?void 0:_appSettingsDataClien.serviceId));}
);
service={
id:(backstageService==null?void 0:backstageService.organisationId)||'',
title:(backstageService==null?void 0:backstageService.name)||'',
appId:(backstageService==null?void 0:backstageService.id)||''
};
setSelectedService(service);return _context3.abrupt("return");case 5:



defaultSelectedService=defaultServices.find(
function(defaultService){var _appSettingsDataClien2;return(
defaultService.id===(appSettingsDataClient==null?void 0:(_appSettingsDataClien2=appSettingsDataClient.opts)==null?void 0:_appSettingsDataClien2.serviceId));}
);if(!
defaultSelectedService){_context3.next=9;break;}
setSelectedService(defaultSelectedService);return _context3.abrupt("return");case 9:


setSelectedService(serviceData||initialApiService);case 10:case"end":return _context3.stop();}},null,null,null,Promise);};


var setService=function setService(newService){var backstageApps,backstageApp;return _regenerator.default.async(function setService$(_context4){while(1)switch(_context4.prev=_context4.next){case 0:_context4.prev=0;if(!

isBackstageService(newService)){_context4.next=21;break;}_context4.next=4;return _regenerator.default.awrap(



userDataClient.getBackstageApplicationsForService(
newService.organisationId,
newService.id
));case 4:backstageApps=_context4.sent;


backstageApp=backstageApps.data.find(function(app){var _app$name;return(_app$name=
app.name)==null?void 0:_app$name.toLowerCase().match(_renative.Api.platform.toLowerCase());}
);if(!(


backstageApp&&!(0,_isEmpty.default)(backstageApp)&&setServiceData)){_context4.next=17;break;}
setSelectedUserProfile(null);_context4.next=10;return _regenerator.default.awrap(
setServiceData({
id:backstageApp.serviceId,
appId:backstageApp.applicationId,
title:newService.name
}));case 10:_context4.next=12;return _regenerator.default.awrap(
userDataClient.logoutWithBackstage());case 12:_context4.next=14;return _regenerator.default.awrap(
userDataClient.logout());case 14:
reload();_context4.next=19;break;case 17:


alert(newService.name+" "+t('error.A03.body'));return _context4.abrupt("return");case 19:_context4.next=28;break;case 21:if(!


setServiceData){_context4.next=28;break;}_context4.next=24;return _regenerator.default.awrap(

userDataClient.logout());case 24:
setSelectedUserProfile(null);_context4.next=27;return _regenerator.default.awrap(
setServiceData(newService));case 27:
reload();case 28:_context4.next=33;break;case 30:_context4.prev=30;_context4.t0=_context4["catch"](0);


(0,_logger.log)(_context4.t0);case 33:case"end":return _context4.stop();}},null,null,[[0,30]],Promise);};



var handleOnSignoutOfBackstage=function handleOnSignoutOfBackstage(){return _regenerator.default.async(function handleOnSignoutOfBackstage$(_context5){while(1)switch(_context5.prev=_context5.next){case 0:_context5.prev=0;if(!(

setBackstageUserData&&setServiceData)){_context5.next=6;break;}_context5.next=4;return _regenerator.default.awrap(
userDataClient.logoutWithBackstage());case 4:
setBackstageUserData(null);
setServiceData((0,_utils.getInitialApiService)());case 6:_context5.next=11;break;case 8:_context5.prev=8;_context5.t0=_context5["catch"](0);


(0,_logger.log)(_context5.t0);case 11:case"end":return _context5.stop();}},null,null,[[0,8]],Promise);};



var getOptions=function getOptions(){var services,backstageServices;return _regenerator.default.async(function getOptions$(_context6){while(1)switch(_context6.prev=_context6.next){case 0:
services=[];if(!

backstageUserData){_context6.next=8;break;}_context6.next=4;return _regenerator.default.awrap(
getBackstageServices());case 4:backstageServices=_context6.sent;

services=(0,_toConsumableArray2.default)(
backstageServices.map(function(service){return{
testID:t(""+service.name).toLowerCase()+"_button",
title:service.name,
value:service.name
};}));_context6.next=9;break;case 8:


services=(0,_toConsumableArray2.default)(
defaultServices.map(function(service){return{
testID:t(""+service.title).toLowerCase()+"_button",
title:service.title,
value:service.title
};}));case 9:



setServiceOptions(services);case 10:case"end":return _context6.stop();}},null,null,null,Promise);};


var onSelectedOption=function onSelectedOption(selected){


var defaultOption=defaultServices==null?void 0:defaultServices.find(function(item){return item.title===selected;});
var backstageOption=organisationServices==null?void 0:organisationServices.find(function(item){return item.name===selected;});

if(backstageUserData&&backstageOption){
setService(backstageOption);
}else if(defaultOption){
setService(defaultOption);
}else{
alert(selected+" "+t('error.A03.body'));
}
};

(0,_react.useEffect)(function(){
getOptions();
getSelectedService();
},[backstageUserData]);

return{
handleOnSignoutOfBackstage:handleOnSignoutOfBackstage,
serviceOptions:serviceOptions,
signedInWithBackstage:!(0,_isEmpty.default)(backstageUserData),
selectedService:selectedService,
isBackstageLoginEnabled:isBackstageLoginEnabled,
onSelectedOption:onSelectedOption
};
};exports.useShared=useShared;