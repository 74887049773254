import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { View } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useDimensions } from '@24i/nxg-sdk-quantum';

import DropdownMenu from '../../../../DropdownPicker/components/DropdownMenu';
import Modal from '../../../../../containers/Modal';
import getContentModalStyles from '../styles';

const ContentModal = ({
    deviceHeight,
    onCancel,
    onValueChange,
    options,
    returnIndex,
    selectedValue,
    styles: getStyles,
    modalSafeAreaViewStyle,
    visible,
    setVisible,
    noMargins,
}) => {
    const { theme } = useTheme();
    const { orientation } = useDimensions();
    const styles = getStyles(theme, orientation);
    const { additionalContainerStyles } = styles;
    const [optionsHeight, setOptionsHeight] = useState();
    const spRef = useRef(null);

    const { paddingVertical } = additionalContainerStyles;

    const calculateOptionsHeight = (optionHeight) => {
        let itemsHeight = optionHeight * options.length;

        if (additionalContainerStyles) {
            const { paddingTop, paddingBottom, marginTop, marginBottom } =
                additionalContainerStyles;

            const paddingValues = [paddingTop, paddingBottom, marginTop, marginBottom];

            paddingValues.forEach((item) => {
                if (typeof item === 'number') {
                    itemsHeight += item;
                }
            });
        }

        setOptionsHeight(itemsHeight);
    };

    const handleOptionClick = (index) => {
        spRef?.current?.scrollToIndex(index);
        returnIndex ? onValueChange(index) : onValueChange(options[index]);
    };

    const prefixKey = 'menuItem';
    const handleTestID = (index) => `${prefixKey}_${index}`;

    return (
        <Modal
            modalSafeAreaViewStyle={modalSafeAreaViewStyle}
            visible={visible}
            onRequestClose={onCancel}
            disableBlurAndroid
            noMargins={noMargins}
        >
            <View style={styles.container}>
                <DropdownMenu
                    options={options}
                    getItemsTestID={handleTestID}
                    selectedItem={selectedValue}
                    onSelectionPress={handleOptionClick}
                    setListOpen={() => setVisible}
                    // we need to check weather the content fits in between the gradients where the paddingVertical is equal with the gradient to make the items pressable
                    scrollEnabled={optionsHeight > deviceHeight - paddingVertical * 2}
                    itemsPrefixKey={prefixKey}
                    completeScrollBarHeight="15"
                    styles={() => getStyles(theme, orientation)}
                    calculateOptionsHeight={calculateOptionsHeight}
                />
            </View>
        </Modal>
    );
};

ContentModal.propTypes = {
    options: PropTypes.arrayOf(PropTypes.string),
    onValueChange: PropTypes.func,
    styles: PropTypes.func,
    onCancel: PropTypes.func,
    visible: PropTypes.bool,
    selectedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ContentModal.defaultProps = {
    options: [],
    onValueChange: () => {},
    styles: getContentModalStyles,
    onCancel: () => {},
    visible: false,
    selectedValue: '',
};

export { getContentModalStyles };
export default overridable(ContentModal, 'ContentModal');
