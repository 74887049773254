import type { TFunction } from 'i18next';
import { Asset, ASSET_TYPE, getIsNewsEpisode } from '@24i/nxg-sdk-photon';
import { BannerCarouselItemData } from '../../types';

const formatGenres = (item: Asset) => {
    const { genres } = item;

    if (genres && genres?.length > 0) {
        return [...genres.slice(0, 2)].map((g) => g.label).join(', ');
    }

    return '';
};

export const getCarouselItemInfo = (item: BannerCarouselItemData, t: TFunction) => {
    let metadata1;
    let assetTitle = item.data.title;

    if (item.data.type === ASSET_TYPE.EPISODE) {
        if (getIsNewsEpisode(item.data)) {
            metadata1 = formatGenres(item.data);
        } else {
            const { seasonNumber, episodeNumber, title } = item.data;
            assetTitle = item.data?.seriesName || item.data.podcastName;
            const seasonNumberText = seasonNumber
                ? `${t('asset.series.seasonInitial')}${seasonNumber} `
                : '';
            const episodeNumberText = episodeNumber
                ? `${t('asset.series.episode.initial')}${episodeNumber} `
                : '';
            const episodeTitleText =
                (seasonNumber || episodeNumber) && title ? `- ${title}` : title;

            metadata1 = `${seasonNumberText}${episodeNumberText}${episodeTitleText}`;
        }
    }

    if (item.data.type === ASSET_TYPE.NEWS_SERIES) {
        metadata1 = formatGenres(item.data);
    }

    return { metadata1, assetTitle };
};
