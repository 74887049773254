import {
    ASSET_TYPE,
    Asset,
    getIsPodcastEpisode,
    PodcastEpisode,
    getIsPodcastSeries,
} from '@24i/nxg-sdk-photon';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { SOTT_DEFAULT_WEB_SCREENS } from '../../navigation/constants';
import useShared, { AssetActionsProps, EmptyAssetActionsProps } from './shared';
import { usePodcastPlayer } from '../../context/PodcastPlayerProvider';

const useAssetActions = (props: AssetActionsProps = EmptyAssetActionsProps) => {
    const shared = useShared(props);
    const router = useRouter();
    const contentDataClient = useContentData();
    const { playPodcastEpisode, handlePodcastPlayerFromOutside, getIsEpisodeInPlayer } =
        usePodcastPlayer();

    const navigateToPlayer = (asset: Asset, isStartOver: boolean): void => {
        const { editionId, isTrailer, id, type, sectionLabel } = asset;
        const assetLink = SOTT_DEFAULT_WEB_SCREENS.Playback.getLink({
            type,
            id,
        });
        const channelId = asset.channelId || asset?.channel?.id;
        router.push(
            {
                pathname: assetLink.href,
                query: {
                    type,
                    id,
                    ...(asset.type === ASSET_TYPE.BROADCAST && { channelId }),
                    ...(isStartOver && { isStartOver }),
                    ...(editionId && { editionId }),
                    ...(isTrailer && { isTrailer }),
                    ...(sectionLabel && { sectionLabel }),
                },
            },
            assetLink.as
        );
    };

    const decideHowToPlayPodcastEpisode = (asset: Asset) => {
        const isEpisodeInPlayer = getIsEpisodeInPlayer(asset.id);
        if (isEpisodeInPlayer) {
            handlePodcastPlayerFromOutside(asset.id);
        } else {
            playPodcastEpisode(asset as PodcastEpisode);
        }
    };

    const startPlayback = async (asset: Asset, isStartOver = false) => {
        if (!asset) return;

        const isPodcastEpisode = getIsPodcastEpisode(asset);
        const isPodcastSeries = getIsPodcastSeries(asset);
        const { isTrailer } = asset;

        switch (true) {
            case isPodcastEpisode && !isTrailer: {
                decideHowToPlayPodcastEpisode(asset);
                break;
            }

            case isPodcastSeries && !isTrailer: {
                const episodeToReproduce = await shared.fetchEpisodeToReproduce(asset, 'latest');

                if (episodeToReproduce && getIsPodcastEpisode(episodeToReproduce)) {
                    decideHowToPlayPodcastEpisode(episodeToReproduce);
                }

                break;
            }

            case asset.type === ASSET_TYPE.SERIES && !asset.isTrailer: {
                const episodeToReproduce = await shared.fetchEpisodeToReproduce(asset);
                if (episodeToReproduce) episodeToReproduce.sectionLabel = asset.sectionLabel;
                navigateToPlayer(episodeToReproduce || asset, isStartOver);
                break;
            }

            case asset.type === ASSET_TYPE.NEWS_SERIES && !asset.isTrailer: {
                const episodeToReproduce = await contentDataClient.fetchLatestNewsEpisode(asset.id);
                if (episodeToReproduce)
                    navigateToPlayer(
                        { ...episodeToReproduce, sectionLabel: asset.sectionLabel },
                        isStartOver
                    );

                break;
            }

            default:
                navigateToPlayer(asset, isStartOver);
        }
    };

    return {
        ...shared,
        startPlayback,
    };
};

export default useAssetActions;
