import { createGuard, nullable } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { ASSET_TYPE_GUARD, ROW_TYPES_GUARD } from '../enums';
import { AssetGuard } from './asset';
import { ImageGuard } from './image';

const AdditionalItemsGuard = createGuard('AdditionalItems', undefined, {
    textPlacement: nullable(t.string),
    position: nullable(t.string),
    title: nullable(t.string),
    description: t.string,
    images: t.array(ImageGuard),

    target: nullable(t.type({ type: ASSET_TYPE_GUARD, link: nullable(t.string) })),
    type: t.string,
    id: t.string,
});

export const PageSectionGuard = createGuard(
    'PageSection',
    {},
    {
        items: t.array(AssetGuard),
        type: nullable(ROW_TYPES_GUARD),
        id: nullable(t.string),
        display: t.union([t.literal('portrait'), t.literal('landscape')]),
        label: nullable(t.string),
        metadata: nullable(
            t.type({
                show: nullable(t.boolean),
            })
        ),
        playlistId: nullable(t.string),
        computedPlaylist: nullable(t.unknown),
        actionForAllItems: nullable(t.union([t.literal('player'), t.literal('detail')])),
        sorting: nullable(
            t.type({
                field: nullable(t.string),
                order: nullable(t.string),
            })
        ),
        _links: nullable(
            t.type({
                href: nullable(t.string),
            })
        ),
        pinnedItems: nullable(t.array(t.string)),
        actions: nullable(t.unknown),
        additionalItems: nullable(t.array(AdditionalItemsGuard)),
        itemLimit: nullable(t.number),
        isGenre: nullable(t.boolean),
        rowId: nullable(t.string),
    }
);

export type AdditionalItems = t.TypeOf<typeof AdditionalItemsGuard>;

export type PageSection = t.TypeOf<typeof PageSectionGuard>;
