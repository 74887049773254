import React from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from '@24i/nxg-sdk-gluons/src/components/ui/ProgressBar';
import { Text } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { getProgressBarEpisodeLabel } from '@24i/nxg-sdk-photon';

import { DETAILS_SCREEN_TEST_IDS } from '../../../test-utils';
import { DetailsProgressBarViewProps } from '../types';
import getDetailsProgressBarStyles from '../styles';

const DetailsProgressBar = ({
    isLive,
    episodeToWatch,
    progress,
    styles: getStyles = getDetailsProgressBarStyles,
}: DetailsProgressBarViewProps) => {
    const { theme } = useTheme();
    const styles = getStyles(theme, isLive);
    const { i18n } = useTranslation();

    return (
        <>
            <ProgressBar
                testID={DETAILS_SCREEN_TEST_IDS.PROGRESS_BAR}
                additionalTrackStyles={styles.progressBarTrack}
                additionalProgressStyles={styles.progressContainer}
                progress={progress}
                theme={theme}
            />
            {episodeToWatch && (
                <Text style={styles.progressText}>
                    {getProgressBarEpisodeLabel(episodeToWatch, i18n.language)}
                </Text>
            )}
        </>
    );
};

export default DetailsProgressBar;
