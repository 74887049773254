import React from 'react';
import { useTranslation } from 'react-i18next';

import { View, Text } from '@24i/nxg-sdk-quarks';
import GenericModal from '@24i/nxg-sdk-smartott/src/components/GenericModal';
import IconButton from '@24i/nxg-sdk-gluons/src/components/buttons/IconButton';
import ActionButton from '@24i/nxg-sdk-gluons/src/components/buttons/ActionButton';
import { getFont, useTheme } from '@24i/nxg-sdk-higgs';
import { getTelegramWebApp } from '@24i/nxg-sdk-higgs/src/utils';

interface TelegramDRMWorkaroundProps {
    closeModal: () => void;
    onBackHandler: () => void;
}

const TelegramDRMWorkaround: React.FC<TelegramDRMWorkaroundProps> = ({
    closeModal,
    onBackHandler,
}) => {
    const { theme } = useTheme();
    const { t, i18n } = useTranslation();
    const buttonGlobalStyles = {
        marginBottom: 20,
        width: '100%',
        alignContent: 'space-around',
    };

    const requestedLink = window?.location?.href.replace(window?.location?.hash, '');

    return (
        <GenericModal
            customContainerStyle={{
                width: '100% !important',
            }}
            requiresModalWrapper={false}
            title={{
                text: i18n.exists('drmWorkAround.title')
                    ? t('drmWorkAround.title')
                    : `Full functionality coming soon on Android`,
            }}
            description={{
                text: i18n.exists('drmWorkAround.description')
                    ? t('drmWorkAround.description')
                    : `Please open in one of the supported browsers:`,
                customStyle: { color: theme.color.textPrimary },
            }}
        >
            <View
                style={{
                    width: '100%',
                    flexDirection: 'column',
                    flex: 1,
                    alignContent: 'space-around',
                }}
            >
                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        width: '100%',
                        alignContent: 'space-around',
                        marginBottom: 20,
                    }}
                >
                    <View style={{ flex: 1, flexGrow: 1 }}>
                        <Text
                            style={{
                                ...getFont(theme, 'h5'),
                                color: theme.color.contrast2,
                                borderWidth: 1,
                                borderColor: theme.color.lighter1,
                                flex: 1,
                                padding: 5,
                            }}
                        >
                            {requestedLink}
                        </Text>
                    </View>
                    <View
                        style={{
                            borderWidth: 1,
                            borderColor: theme.color.lighter1,
                            width: 30,
                            flexDirection: 'column',
                            justifyContent: 'center',
                            backgroundColor: theme.color.buttonPrimary,
                        }}
                    >
                        <IconButton
                            iconName="onPrimary-edit"
                            additionalIconStyles={{
                                textAlign: 'center',
                                alignSelf: 'center',
                            }}
                            additionalContainerStyles={{
                                height: 20,
                                alignSelf: 'center',
                            }}
                            onPress={() => {
                                navigator.clipboard.writeText(requestedLink);
                            }}
                            iconSize={20}
                        />
                    </View>
                </View>
                <View>
                    <ActionButton
                        title={
                            i18n.exists('drmWorkAround.openInChrome')
                                ? t('drmWorkAround.openInChrome')
                                : 'Open in Chrome'
                        }
                        onPress={async () => {
                            getTelegramWebApp()?.openLink(requestedLink, {
                                try_instant_view: false,
                                try_browser: 'chrome',
                            });
                            closeModal();
                            onBackHandler();
                        }}
                        additionalContainerStyles={buttonGlobalStyles}
                    />
                    <ActionButton
                        title={
                            i18n.exists('drmWorkAround.openInSamsung')
                                ? t('drmWorkAround.openInSamsung')
                                : 'Open in Samsung Browser'
                        }
                        onPress={async () => {
                            getTelegramWebApp()?.openLink(requestedLink, {
                                try_instant_view: false,
                                try_browser: 'samsung',
                            });
                            closeModal();
                            onBackHandler();
                        }}
                        additionalContainerStyles={buttonGlobalStyles}
                    />
                    <ActionButton
                        title={t('common.cancel')}
                        onPress={async () => {
                            closeModal();
                            onBackHandler();
                        }}
                        additionalContainerStyles={{
                            ...buttonGlobalStyles,
                            backgroundColor: 'transparent',
                        }}
                    />
                </View>
            </View>
        </GenericModal>
    );
};

export default TelegramDRMWorkaround;
