import { isFactorTv } from 'renative';
import { TFunction } from 'i18next';
import { DeviceInfo } from '@24i/nxg-sdk-quantum';
import { formatReleaseDateTime, getDurationString, timeDateText } from '@24i/nxg-core-utils';
import {
    Broadcast,
    Asset,
    isLiveEvent,
    ASSET_TYPE,
    getIsPodcastEpisode,
    getIsNewsEpisode,
} from '@24i/nxg-sdk-photon';
import { GridItem } from '../types';

// TODO: add `portrait`, `landscape` to separate constants
export const PACKSHOT_ASPECT_RATIOS = {
    portrait: 0.665,
    landscape: 1.77,
};

// Could define these values as separate constants but decided to leave it as an
// object in case it will need to be exported and used somewhere else
const BREAKPOINTS = {
    S: 500,
    M: 800,
    L: 1200,
    XL: 1600,
};

const { S, M, L, XL } = BREAKPOINTS;

export const TILES_PER_ROW = {
    LANDSCAPE: {
        [S]: 2,
        [M]: 3,
        [L]: 4,
        [XL]: 4,
    },
    PORTRAIT: {
        [S]: 3,
        [M]: 4,
        [L]: 6,
        [XL]: 7,
    },
};

export const getTilesPerRow = (
    screenWidth: number,
    displayType: string,
    isHighlighted?: boolean
) => {
    const isTwoByThree = displayType === 'portrait';

    const getTilesPerRowNumber = (breakpoint) => {
        const getLandscapeTilesPerRow = () =>
            isHighlighted
                ? TILES_PER_ROW.LANDSCAPE[breakpoint] - 1
                : TILES_PER_ROW.LANDSCAPE[breakpoint];

        const getPortraitTilesPerRow = () =>
            isHighlighted
                ? TILES_PER_ROW.PORTRAIT[breakpoint] - 1
                : TILES_PER_ROW.PORTRAIT[breakpoint];

        return isTwoByThree ? getPortraitTilesPerRow() : getLandscapeTilesPerRow();
    };

    if (screenWidth <= S) return getTilesPerRowNumber(S);
    if (screenWidth <= M) return getTilesPerRowNumber(M);
    if (screenWidth <= L) return getTilesPerRowNumber(L);
    return getTilesPerRowNumber(XL);
};

// Get packshot width according to this table https://24imedia.atlassian.net/wiki/spaces/PRJ001NXG/pages/944570489/Row+grids+system
export const getDynamicPackshotWidth = (
    { displayType, isHighlighted, screenWidth, spacing = 0, margin = 0 },
    { getTilesPerRow: getTilesPerRowNumber = getTilesPerRow } = { getTilesPerRow }
) => {
    if (!screenWidth) return 0;

    const tilesPerRow = getTilesPerRowNumber(screenWidth, displayType, isHighlighted);
    const screenWidthWithoutMargins = screenWidth - spacing * (tilesPerRow + 1) - margin * 2;

    const packshotWidth = Math.floor(screenWidthWithoutMargins / tilesPerRow);

    return packshotWidth;
};

export const getSpacing = () => {
    if (isFactorTv) return 40;
    if (DeviceInfo.isTablet()) return 16;
    return 12;
};

export const getTimeTextForAsset = (asset: Asset | Broadcast, is12HourClock: boolean) => {
    const { start, startsAt, end, endsAt } = asset;

    const startTime = start || startsAt;
    const endTime = end || endsAt;
    if (!startTime || !endTime) return undefined;
    const startDate = new Date(start || (startsAt || 0) * 1000);
    const endDate = new Date(end || (endsAt || 0) * 1000);

    return timeDateText(startDate, endDate, is12HourClock, isLiveEvent(asset));
};

export const getSubtitle = <T extends Asset>(
    item: T,
    subtitle: string | null | undefined,
    t: TFunction,
    currentLocale: string
): string | undefined => {
    const { seasonNumber, episodeNumber, podcastName, duration, type } = item;
    const isPodcastEpisode = getIsPodcastEpisode(item);
    const isNewsEpisode = getIsNewsEpisode(item);
    const isSeriesEpisode = !!(
        type === ASSET_TYPE.EPISODE &&
        !isPodcastEpisode &&
        seasonNumber &&
        episodeNumber
    );
    const isClip = type === ASSET_TYPE.CLIP;
    const isChannel = type === ASSET_TYPE.CHANNEL;

    const defaultReturn = subtitle ?? undefined;

    switch (true) {
        case isNewsEpisode: {
            if (item.releaseDateTime) {
                return formatReleaseDateTime(item.releaseDateTime, currentLocale, 'complete');
            }
            return undefined;
        }

        case isPodcastEpisode:
            return podcastName ?? '';

        case isSeriesEpisode:
            return t('asset.series.episode.number', {
                seasonNumber,
                episodeNumber,
                episodeTitle: item.title,
            }) as string;

        case isClip && duration != null:
            return getDurationString(duration as number, t) || defaultReturn;

        case isChannel:
            return undefined;

        default:
            return defaultReturn;
    }
};

export const mapAssetToGridItem = (asset: Asset | Broadcast): GridItem => {
    const {
        id,
        type,
        title,
        startsAt,
        endsAt,
        continueWatchingOffset,
        duration,
        progress,
        subtitle,
        poster,
        still,
        background,
        channelLogo,
        label,
        assetLabel,
        editionId,
        seasonNumber,
        episodeNumber,
        isAdult,
    } = asset;

    return {
        id,
        type,
        title: title ?? '',
        startsAt: startsAt ?? 0,
        endsAt: endsAt ?? 0,
        continueWatchingOffset: continueWatchingOffset ?? 0,
        duration: duration ?? 0,
        progress: progress ?? 0,
        subtitle: subtitle ?? '',
        poster: poster ?? '',
        still: still ?? '',
        background: background ?? '',
        channelLogo: channelLogo ?? undefined,
        label: label ?? undefined,
        assetLabel: assetLabel ?? undefined,
        editionId: editionId ?? undefined,
        seasonNumber: seasonNumber ?? undefined,
        episodeNumber: episodeNumber ?? undefined,
        isAdult,
    };
};

export const findAssetByGridItem = (
    item: GridItem,
    assets: Asset[] | Broadcast[]
): Asset | Broadcast => {
    const asset =
        assets.find((ass) => {
            if (item.editionId) {
                return ass.id === item.id && ass.editionId === item.editionId;
            }
            return ass.id === item.id;
        }) || assets[0];
    return asset;
};
