import type { TFunction } from 'i18next';
import {
    Broadcast,
    Asset,
    isLiveEvent,
    ASSET_TYPE,
    PageSection,
    getIsPodcastEpisode,
    getIsNewsEpisode,
} from '@24i/nxg-sdk-photon';
import {
    formatReleaseDateTime,
    getDurationString,
    isPlatformTvos,
    timeDateText,
} from '@24i/nxg-core-utils';
import { ItemRender } from '../View/types';
import { SingleRowTextUnderRowProps } from '../components/types';

export const SEE_ALL_ITEM_ID = 'See-all';

export const getTimeTextForAsset = (
    asset: Asset | Broadcast,
    is12HourClock: boolean,
    showOnlyHours
) => {
    const { start, startsAt, end, endsAt } = asset;
    const startTime = start || startsAt;
    const endTime = end || endsAt;
    if (!startTime || !endTime) return undefined;
    const startDate = new Date(start || (startsAt || 0) * 1000);
    const endDate = new Date(end || (endsAt || 0) * 1000);

    return timeDateText(
        startDate,
        endDate,
        is12HourClock,
        isLiveEvent(asset),
        !!asset.isLive,
        true,
        showOnlyHours
    );
};

export const getSubtitle = <T extends Asset>(
    item: T,
    subtitle: string | null | undefined,
    t: TFunction,
    currentLocale: string
): string | undefined => {
    const { seasonNumber, episodeNumber, podcastName, duration, type } = item;
    const isPodcastEpisode = getIsPodcastEpisode(item);
    const isNewsEpisode = getIsNewsEpisode(item);
    const isSeriesEpisode = !!(
        type === ASSET_TYPE.EPISODE &&
        !isPodcastEpisode &&
        seasonNumber &&
        episodeNumber
    );
    const isClip = type === ASSET_TYPE.CLIP;
    const isChannel = type === ASSET_TYPE.CHANNEL;

    const defaultReturn = subtitle ?? undefined;

    switch (true) {
        case isNewsEpisode: {
            if (item.releaseDateTime) {
                return formatReleaseDateTime(item.releaseDateTime, currentLocale, 'complete');
            }
            return undefined;
        }

        case isPodcastEpisode:
            return podcastName ?? '';

        case isSeriesEpisode:
            return t('asset.series.episode.number', {
                seasonNumber,
                episodeNumber,
                episodeTitle: item.title,
            }) as string;

        case isClip && duration != null:
            return getDurationString(duration as number, t) || defaultReturn;

        case isChannel:
            return undefined;

        default:
            return defaultReturn;
    }
};

export const getPackshotImage = (item: ItemRender, section?: PageSection): string => {
    let {
        poster,
        heroPortrait,
        heroLandscape,
        backgroundPortrait,
        still,
        background,
        packshotLandscape,
    } = item;
    const { type, broadcastMetadata } = item;
    // assets of type channel have an extra property with metadata
    // from the broacasted program of the moment
    if (item && type === ASSET_TYPE.CHANNEL && broadcastMetadata?.id) {
        ({
            poster,
            heroPortrait,
            heroLandscape,
            backgroundPortrait,
            still,
            background,
            packshotLandscape,
        } = broadcastMetadata);
    }

    // TO DO: asset of type custom_item have only a background property
    // for both landscape and portrait
    // for now we can only address this here but we need to align
    // the asset property with the other asset in the app
    if (item?.type === ASSET_TYPE.CUSTOM && item.id !== 'See-all') {
        return backgroundPortrait || background || '';
    }

    return section?.display === 'portrait'
        ? poster || heroPortrait || backgroundPortrait || still || ''
        : packshotLandscape || still || heroLandscape || background || '';
};

export const transformItems = (
    initialSection: PageSection,
    itemLimit: number,
    renderShowAllButton?: boolean
): PageSection['items'] => {
    const items = initialSection.items?.slice(0, itemLimit) || [];
    if (initialSection.additionalItems) {
        const { additionalItems } = initialSection;
        additionalItems
            .slice()
            .reverse()
            .forEach(
                (
                    {
                        textPlacement,
                        position,
                        images,
                        target,
                        title: additionalItemTitle,
                        description,
                    },
                    i
                ) => {
                    // According to typing images does not match here.
                    // using as to get going without major changes as
                    // I have so much to do while updating some 3rd party
                    // packages.
                    //
                    // TODO: when you see this, check why images property
                    // does not work. According to typeguards (which I'm
                    // propably misunderstanding) types for both are the
                    // same.
                    const item = {
                        title: additionalItemTitle,
                        subtitle: description,
                        background: images?.[0].url,
                        images,
                        textPlacement,
                        type: ASSET_TYPE.CUSTOM,
                        target,
                        id: additionalItemTitle || i.toString(),
                    } as Exclude<PageSection['items'], void>[number];
                    if (position === 'start') items.unshift(item);
                    if (position === 'end') items.push(item);
                }
            );
    }
    if (renderShowAllButton)
        items.push({
            id: SEE_ALL_ITEM_ID,
            type: ASSET_TYPE.CUSTOM,
        });

    return items;
};

export const getSingleRowTextUnderRowSubtitle = ({
    focusedItem,
    durationAsSubtitle,
    t,
    currentLocale,
}: Pick<SingleRowTextUnderRowProps, 'durationAsSubtitle' | 'focusedItem'> & {
    t: TFunction;
    currentLocale: string;
}) => {
    if (!focusedItem) return undefined;

    if (durationAsSubtitle && focusedItem.type === ASSET_TYPE.EPISODE && focusedItem.duration) {
        const duration = getDurationString(focusedItem?.duration, t);
        const date =
            getIsNewsEpisode(focusedItem) && isPlatformTvos && focusedItem.releaseDateTime
                ? `${formatReleaseDateTime(
                      focusedItem.releaseDateTime,
                      currentLocale,
                      'date-only'
                  )} · `
                : '';

        return `${date}${duration}`;
    }

    return getSubtitle(focusedItem, focusedItem.subtitle, t, currentLocale);
};
