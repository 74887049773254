Object.defineProperty(exports,"__esModule",{value:true});exports.default=void 0;var _default=[{
fontFamily:'AntDesign',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/AntDesign.ttf')
},{
fontFamily:'Entypo',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/Entypo.ttf')
},{
fontFamily:'FontAwesome',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/FontAwesome.ttf')
},{
fontFamily:'HK Grotesk Black Italic',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/HK Grotesk Black Italic.otf')
},{
fontFamily:'HK Grotesk Black',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/HK Grotesk Black.otf')
},{
fontFamily:'HK Grotesk Bold Italic',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/HK Grotesk Bold Italic.otf')
},{
fontFamily:'HK Grotesk Bold',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/HK Grotesk Bold.otf')
},{
fontFamily:'HK Grotesk Extra Bold Italic',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/HK Grotesk Extra Bold Italic.otf')
},{
fontFamily:'HK Grotesk Extra Bold',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/HK Grotesk Extra Bold.otf')
},{
fontFamily:'HK Grotesk Italic',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/HK Grotesk Italic.otf')
},{
fontFamily:'HK Grotesk Light Italic',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/HK Grotesk Light Italic.otf')
},{
fontFamily:'HK Grotesk Light',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/HK Grotesk Light.otf')
},{
fontFamily:'HK Grotesk Medium Italic',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/HK Grotesk Medium Italic.otf')
},{
fontFamily:'HK Grotesk Medium',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/HK Grotesk Medium.otf')
},{
fontFamily:'HK Grotesk Regular',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/HK Grotesk Regular.otf')
},{
fontFamily:'HK Grotesk Semi Bold Italic',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/HK Grotesk Semi Bold Italic.otf')
},{
fontFamily:'HK Grotesk Semi Bold',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/HK Grotesk Semi Bold.otf')
},{
fontFamily:'HK Grotesk',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/HK Grotesk.otf')
},{
fontFamily:'Ionicons',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/Ionicons.ttf')
},{
fontFamily:'MaterialCommunityIcons',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/MaterialCommunityIcons.ttf')
},{
fontFamily:'MaterialIcons',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/MaterialIcons.ttf')
},{
fontFamily:'Roboto Bold',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/Roboto Bold.ttf')
},{
fontFamily:'Roboto Light',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/Roboto Light.ttf')
},{
fontFamily:'Roboto Medium',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/Roboto Medium.ttf')
},{
fontFamily:'Roboto',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/base/fonts/Roboto.ttf')
},{
fontFamily:'Readex Pro Bold',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/appstage/fonts/Readex Pro Bold.ttf')
},{
fontFamily:'Readex Pro ExtraLight',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/appstage/fonts/Readex Pro ExtraLight.ttf')
},{
fontFamily:'Readex Pro Light',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/appstage/fonts/Readex Pro Light.ttf')
},{
fontFamily:'Readex Pro Medium',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/appstage/fonts/Readex Pro Medium.ttf')
},{
fontFamily:'Readex Pro Regular',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/appstage/fonts/Readex Pro Regular.ttf')
},{
fontFamily:'Readex Pro SemiBold',
file:require('/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/appConfigs/appstage/fonts/Readex Pro SemiBold.ttf')
}];exports.default=_default;