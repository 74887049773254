import { Platform, ViewStyle } from 'react-native';
import { Dispatch, SetStateAction } from 'react';
import type { TFunction } from 'i18next';

import { getDurationString } from '@24i/nxg-core-utils';
import { DateTime } from 'luxon';
import {
    RECORDING_STATUS,
    Asset,
    EPG_TYPE,
    Season,
    ASSET_TYPE,
    Broadcast,
    RemindersClient,
    EpgDataClient,
    Episode,
    User,
} from '@24i/nxg-sdk-photon';

import { SEPARATORS } from '@24i/nxg-sdk-smartott/src/components/TitleView/components/TextWithSeparator';
import { showToast } from '@24i/nxg-sdk-gluons/src/components/ui/Toast';
import {
    ReminderNotificationPayload,
    REMINDERS_NOTIFICATION_CHANNEL_ID,
} from '@24i/nxg-sdk-smartott/src/hooks/usePushNotifications/types';

import {
    PageDetailsContentMobileStyles,
    PageDetailsContentTvStyles,
    PageDetailsContentWebStyles,
} from '../components/DetailsScreenContent/styles/types';

export const handleSharePress = (
    asset: Asset,
    onSharePress: (asset: Asset) => void
): void | undefined => {
    if (onSharePress) {
        return onSharePress(asset);
    }
    return undefined;
};

export const handleOnMyListPress = (
    isAddedToMyList: boolean,
    t: (text: string, options?: unknown) => string,
    onMyListPress?: () => Promise<void>
): void => {
    let message;

    if (isAddedToMyList) {
        message = t('favorites.manage.delete.success');
    } else {
        message = t('favorites.manage.add.success');
    }

    showToast(message);

    onMyListPress?.();
};

export const getEpgType = (asset: Asset): EPG_TYPE => {
    if (asset.type === ASSET_TYPE.EPG || asset.type === ASSET_TYPE.BROADCAST) {
        if (asset.end && asset.end < +new Date()) return EPG_TYPE.PAST;
        if (asset.start && asset.start > +new Date()) return EPG_TYPE.FUTURE;
    }
    return EPG_TYPE.NONE;
};

export const formatGenres = (asset: Asset) => {
    const genres = asset?.genres
        // make sure there's a label and only use the first two genres
        ?.filter((genre, index) => genre.label && index < 2)
        .map((genre) => genre.label)
        .join(', ');

    return genres;
};

export const transformSubtitle = (asset: Asset, seasons: Season[], t: TFunction): string => {
    const subtitleParts: string[] = [];
    const genres = formatGenres(asset);

    const totalSeasonCount =
        seasons && seasons[0]?.seasonNumber === 0 ? seasons?.length - 1 : seasons?.length;

    const duration = asset.duration ? getDurationString(asset.duration, t) : null;
    switch (asset.type) {
        case ASSET_TYPE.PODCAST_SERIES: {
            const totalEpisodeCount = seasons.reduce((acc, curr) => acc + curr.episodes.length, 0);

            if (totalEpisodeCount > 0)
                // FIXME: this should be localized
                subtitleParts.push(
                    `${totalEpisodeCount} episode${totalEpisodeCount > 1 ? 's' : ''}`
                );

            if (genres) subtitleParts.push(genres);
            break;
        }

        case ASSET_TYPE.SERIES:
            if (seasons) {
                const seasonCountLocalisedString = t('asset.series.numberOfSeasons', {
                    number: totalSeasonCount,
                });
                subtitleParts.push(seasonCountLocalisedString);
            }
            if (genres) subtitleParts.push(genres);
            if (asset.releaseDate) subtitleParts.push(asset.releaseDate);
            break;
        case ASSET_TYPE.EPG:
        case ASSET_TYPE.BROADCAST:
            if (genres) subtitleParts.push(genres);
            if (asset.releaseDate) subtitleParts.push(asset.releaseDate);
            break;
        case ASSET_TYPE.LIVE_EVENT:
            if (asset.subtitle) subtitleParts.push(asset.subtitle);
            break;
        case ASSET_TYPE.MOVIE:
        default:
            if (duration) subtitleParts.push(duration);
            if (genres) subtitleParts.push(genres);
            if (asset.releaseDate) subtitleParts.push(asset.releaseDate);
            break;
    }

    return subtitleParts.filter((x) => !!x).join(` ${SEPARATORS.DOT} `);
};

export const actionButtonStyles = (
    stylesObj:
        | PageDetailsContentMobileStyles
        | PageDetailsContentTvStyles
        | PageDetailsContentWebStyles,
    asset: Asset
): ViewStyle | undefined => {
    const isMovie = asset?.type === 'movie';
    const isSeries = asset?.type === 'series';
    const isEpisode = asset?.type === 'episode';
    if (isMovie) return stylesObj.additionalMovieActionButtonContainerStyles;
    if (isEpisode) return stylesObj.additionalEpisodeActionButtonContainerStyles;
    if (getEpgType(asset) === EPG_TYPE.FUTURE)
        return stylesObj.additionalEpgActionButtonContainerStyles;
    if (isSeries) return stylesObj.additionalSeriesActionButtonContainerStyles;
    if (asset?.isLive || asset?.type === 'broadcast' || asset?.type === 'live_event')
        return stylesObj.additionalActionButtonContainerStyles;
    return undefined;
};

export const getToastMessage = (
    t: (text: string, options?: unknown) => string,
    recordingState?: RECORDING_STATUS,
    activeRecordState?: RECORDING_STATUS
): string => {
    switch (recordingState) {
        case RECORDING_STATUS.PARTIAL:
        case RECORDING_STATUS.SCHEDULED:
            return t('recordings.add.planned.success');
        case RECORDING_STATUS.COMPLETED:
            return t('recordings.add.success');
        default:
            return activeRecordState === RECORDING_STATUS.COMPLETED
                ? t('recordings.delete.success', { count: 1 })
                : t('recordings.delete.planned.success', { count: 1 });
    }
};

export const getErrorModalMesage = (
    t: (text: string, options?: unknown) => string,
    assetName?: string,
    activeRecordState?: RECORDING_STATUS
): string => {
    switch (activeRecordState) {
        case RECORDING_STATUS.PARTIAL:
        case RECORDING_STATUS.SCHEDULED:
            return t('error.F06.body', {
                assetTitle: assetName,
            });
        case RECORDING_STATUS.COMPLETED:
            return t('error.F07.body', {
                assetTitle: assetName,
            });
        default:
            return t('error.F01.body');
    }
};

export const mapAssetToTrailer = (asset: Asset, t: (label: string) => string): Asset => {
    // One Clip can have multiple streams (fulls or trailers) and all will have the same title and image
    if (asset.isTrailer && asset.type !== ASSET_TYPE.CLIP) {
        return {
            ...asset,
            title: t('asset.tab.trailer'),
            subtitle: '',
        };
    }
    return asset;
};

export async function utilsOnRemindPress(
    broadcast: Broadcast,
    profileId: string,
    remindersDataClient: RemindersClient,
    reminderIsSet: boolean,
    setReminderIsSet: Dispatch<SetStateAction<boolean>>,
    logReminderError: (error: unknown) => void,
    t: (text: string, options?: unknown) => string
): Promise<void> {
    if (!reminderIsSet) {
        try {
            await remindersDataClient.setReminderForBroadcast(broadcast, profileId);
            setReminderIsSet(true);
            showToast(t('reminders.add.success'));
        } catch (error: unknown) {
            logReminderError(error);
        }
    } else {
        try {
            const { id: assetId } = broadcast;
            await remindersDataClient.deleteReminderForBroadcast(assetId, profileId);
            setReminderIsSet(false);
            showToast(t('reminders.remove.success'));
        } catch (error: unknown) {
            logReminderError(error);
        }
    }
}

export async function utilsSchedulePushNotifications(
    broadcast: Broadcast,
    profileId: string,
    showThumbnail: boolean,
    reminderIsSet: boolean,
    epgDataClient: EpgDataClient,
    getScheduledLocalNotificationDate: (startTime: number) => Date,
    scheduleLocalPushNotification: (
        message: string,
        scheduledNotificationTime: Date,
        title: string,
        invokeApp: boolean,
        channelId: typeof REMINDERS_NOTIFICATION_CHANNEL_ID,
        pushNotificationId: number,
        userInfo?: ReminderNotificationPayload,
        thumbnailImageUrl?: string
    ) => void,
    cancelLocalPushNotification: (broadcastId: string, profileId: string) => Promise<void>,
    t: (text: string, options?: unknown) => string
): Promise<void> {
    if (!reminderIsSet) {
        // schedule a local push notification for 5 minute before the broadcast starts
        const scheduledNotificationTime = getScheduledLocalNotificationDate(broadcast.start);
        // id must be a number
        const pushNotificationId = Math.floor(Math.random() * 1000000000);

        const { id, type, channelId, start, end } = broadcast;

        const startDate = DateTime.fromMillis(start).toISO();
        const endDate = DateTime.fromMillis(end).toISO();
        const brodacastData = await epgDataClient.getEpgData(startDate, endDate);
        const [channelData] = brodacastData.filter((channel) => channel.id === channelId);
        const channelName = channelData.title;
        const notificationMessage = t('reminders.show.descriptionShort', {
            title: broadcast.title,
            channel: channelName,
        });

        const notificationAssetParams: ReminderNotificationPayload = {
            id,
            type,
            channelId,
            start,
            end,
            profileId,
        };

        const thumbnailImageUrl =
            // do not pass thumbnail to notification on Android until this is resolved.
            // there are version mismatches using the library com.squareup.okhttp3:okhttp
            // or com.squareup.okhttp3:okhttp-urlconnection
            // we are using 2.1, 3.12.1, 3.12.12 and most recent is 5.
            // https://github.com/zo0r/react-native-push-notification/issues/2238
            showThumbnail && broadcast.still && Platform.OS === 'ios' ? broadcast.still : undefined;

        scheduleLocalPushNotification(
            notificationMessage,
            scheduledNotificationTime,
            t('reminders.show.title'),
            true,
            REMINDERS_NOTIFICATION_CHANNEL_ID,
            pushNotificationId,
            notificationAssetParams,
            thumbnailImageUrl
        );
    } else {
        await cancelLocalPushNotification(broadcast.id, profileId);
    }
}

export const mapUserSeasons = (
    seasons: Season[] = [],
    continueWatchingPlaylist?: Asset[],
    userData?: User | null
): Season[] => {
    if (!userData) return seasons;

    return seasons.map((season: Season) => {
        const episodes = season.episodes.map((ep: Episode) => {
            const contWatch = continueWatchingPlaylist?.find(({ id }) => id === ep.id);
            return contWatch
                ? {
                      ...ep,
                      ...{
                          continueWatchingLastTime: contWatch?.continueWatchingLastTime || 0,
                          continueWatchingOffset: contWatch?.continueWatchingOffset || 0,
                          duration: contWatch?.duration,
                      },
                  }
                : ep;
        });
        return { ...season, ...{ episodes } };
    });
};
