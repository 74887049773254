/* eslint-disable react/display-name */
import React from 'react';
import { getTelegramWebApp } from '@24i/nxg-sdk-higgs/src/utils';
import View from './View';
import { useViewModel } from './viewModel';
import * as seeAllPrerenderFunctions from '../../pages/see-all/[pageId]/[sectionId]';
import getSeeAllScreenStyles from './styles/index.web';
import { SeeAllScreenProps } from './types';
import { WithWebHeaderProps } from '../../navigation/components/withWebHeader/types';

export default {
    Main: (props: SeeAllScreenProps) => {
        const viewModelProps = useViewModel(props);
        const webHeaderProps: WithWebHeaderProps = {
            webHeaderTitle: viewModelProps.assetsLabel ?? '',
            fullWidth: true,
            forceShowHeader: false,
            hasMargin: false,
            noVerticalMargin: true,
            backButtonTitle: viewModelProps.assetsLabel ?? '',
            isBackButtonHeaderText: true,
            forceDisable: !getTelegramWebApp(),
        };
        return <View {...viewModelProps} {...webHeaderProps} />;
    },
    View,
    useViewModel,
    page: {
        'see-all': {
            '[pageId]': {
                '[sectionId]': seeAllPrerenderFunctions,
            },
        },
    },
    // default styles getter
    getDefaultStyles: getSeeAllScreenStyles,
};
