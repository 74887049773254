import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs';
import { TextWithSepratorStyles } from '../types';
import { SubtitleFontSize, TitleColorVariant } from '../../../types';

const getStyles = (
    theme: Theme,
    variant?: TitleColorVariant,
    subtitleFontSize?: SubtitleFontSize
): TextWithSepratorStyles => {
    const colorToUse =
        variant === 'secondaryColor' ? theme.color.textSecondary : theme.color.textPrimary;
    const subtitleFontSizeToUse = subtitleFontSize ?? 'h5';

    return {
        separator: {
            color: colorToUse,
            ...getFont(theme, subtitleFontSizeToUse),
        },
        subtitle: {
            color: colorToUse,
            fontWeight: 'bold',
            ...getFont(theme, subtitleFontSizeToUse),
            alignItems: 'flex-start',
            lineHeight: 24,
        },
    };
};

export default getStyles;
