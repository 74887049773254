import { getGradientColors, rgbaToHex } from '@24i/nxg-core-utils/src';
import { logError } from '@24i/nxg-core-utils/src/logger';
import { createTheme, Icon, IconMapItem, MergedDefaultIcons, Theme } from '@24i/nxg-sdk-photon';
import deepmerge from 'deepmerge';
import { isFactorMobile } from 'renative';

import {
    CustomIcons,
    DefaultIcons,
    IconState,
    PartialIconMap,
    ThemeIcon,
    ThemeResponseItem,
} from './types';
import { DefaultTheme } from './backstageDefaultTheme';

const hasValidUrlProtocol = (url = '') =>
    ['http://', 'https://', 'ftp://'].some((protocol) => url.startsWith(protocol));

const mergeThemes = (defaultTheme: DefaultTheme, theme: ThemeResponseItem): DefaultTheme => {
    // TODO: Think if there is a way to abstract this function to work not only with `clientLogo` key
    const mergeClientLogo = (clientLogoLeft, clientLogoRight) => {
        if (clientLogoRight.value === null) return clientLogoLeft;
        return clientLogoRight;
    };

    const mergeSvgIcons = (svgLeft: ThemeIcon['svg'], svgRight: ThemeIcon['svg']) =>
        svgRight && svgRight.length ? svgRight : svgLeft;

    return deepmerge(defaultTheme, theme, {
        customMerge: (key: string) => {
            if (key === 'clientLogo') {
                return mergeClientLogo;
            }
            if (key === 'svg') {
                return mergeSvgIcons;
            }

            return undefined;
        },
    });
};

const mapThemeIconToIcon = (icon?: ThemeIcon): Icon | undefined => {
    if (!icon) return undefined;
    const path = icon.svg?.[0]?.path;
    const localIconPath = '/icons/';
    return {
        width: icon.width ?? 24,
        height: icon.height ?? 24,
        iconImage: !hasValidUrlProtocol(path) ? localIconPath + path : path,
        // placeholder icon because font icons are required in current implementation
        iconFont: 'materialIcons',
        iconName: 'info',
    };
};

const removeEmpty = (obj: any): any => {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([_, v]) => v != null)
            .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
    );
};

export const transformThemeGenericIcons = (icons: ThemeResponseItem['icons']): PartialIconMap => {
    const result: PartialIconMap = {
        player: {
            play: mapThemeIconToIcon(icons['onButtonPrimary-play']),
            cast: mapThemeIconToIcon(icons['onPrimary-chromecast']),
            settings: undefined,
            seekForward: undefined,
            seekBackward: undefined,
            fullSchedule: undefined,
            fullscreen: undefined,
            audioAndSubs: undefined,
            moreEpisodes: undefined,
            previousEpisode: undefined,
            nextEpisode: undefined,
            otherChannels: undefined,
            record: mapThemeIconToIcon(icons['onButtonPrimary-record']),
            startOver: mapThemeIconToIcon(icons['onButtonTertiary-startOver']),
            volumeUp: undefined,
            volumeDown: undefined,
            volumeOff: undefined,
        },
        settings: {
            back: mapThemeIconToIcon(icons['onPrimary-back']),
            checkmark: mapThemeIconToIcon(icons['onPrimary-select']),
            chevronRight: undefined,
            check: mapThemeIconToIcon(icons['onPrimary-select']),
            close: mapThemeIconToIcon(icons['onPrimary-close']),
            iphone: mapThemeIconToIcon(icons['onPrimary-device_smartphone']),
            ipad: mapThemeIconToIcon(icons['onPrimary-device_tablet']),
            laptop: mapThemeIconToIcon(icons['onPrimary-device_laptop']),
            language: undefined,
            info: undefined,
            service: undefined,
            appTheme: undefined,
        },
        search: {
            dropdownItem: undefined,
            input: undefined,
            clear: mapThemeIconToIcon(icons['onPrimary-clear']),
            cancel: mapThemeIconToIcon(icons['onPrimary-close']),
        },
        details: {
            addToList: undefined,
            removeFromList: undefined,
            record: mapThemeIconToIcon(icons['onButtonPrimary-record']),
            share: mapThemeIconToIcon(icons['onButtonSecondary-share']),
            play: mapThemeIconToIcon(icons['onButtonPrimary-play']),
            remind: mapThemeIconToIcon(icons['onButtonSecondary-reminder_add']),
            unrated: undefined,
            startOver: mapThemeIconToIcon(icons['onButtonSecondary-startOver']),
            rated: undefined,
            geoBlocked: undefined,
            cancelRecording: mapThemeIconToIcon(icons['onButtonPrimary-record_cancel']),
            deleteRecording: undefined,
            setReminder: undefined,
            reminderIsSet: undefined,
            purchase: mapThemeIconToIcon(icons['onButtonPrimary-purchase']),
            rent: mapThemeIconToIcon(icons['onButtonPrimary-rent']),
        },
        myContent: {
            myList: mapThemeIconToIcon(icons['onPrimary-seeAll']),
            downloads: mapThemeIconToIcon(icons['onButtonSecondary-download']),
            recordings: mapThemeIconToIcon(icons['onButtonPrimary-record']),
            purchases: undefined,
            reminders: undefined,
            recentlyWatched: undefined,
            pencil: undefined,
            trash: mapThemeIconToIcon(icons['onPrimary-delete']),
        },
        auth: {
            incorrect: undefined,
        },
        packshot: {
            moreInfo: mapThemeIconToIcon(icons['onButtonSecondary-info']),
            play: mapThemeIconToIcon(icons['onButtonPrimary-play']),
            check: undefined,
            delete: mapThemeIconToIcon(icons['onPrimary-delete']),
            cancel: undefined,
        },
        epg: {
            chevronRight: undefined,
            portraitChannelView: undefined,
            portraitTimeView: undefined,
            filter: mapThemeIconToIcon(icons['onPrimary-filter']),
            calendar: undefined,
        },
        common: {
            modalClose: mapThemeIconToIcon(icons['onPrimary-close']),
            dropdownExpand: undefined,
            arrowUp: undefined,
            arrowDown: undefined,
        },
        about: {
            buttonIcon: mapThemeIconToIcon(icons['onButtonSecondary-info']),
        },
        pinControl: {
            checkmark: undefined,
            backspace: undefined,
        },
        account: {
            user: undefined,
            subscriptions: undefined,
            parentalControl: undefined,
            signin: undefined,
            // devices: undefined,
        },
        chromecast: {
            tv: undefined,
            volumeUp: undefined,
            volumeOff: undefined,
            volumeDown: undefined,
            castConnected: undefined,
            skipPrev: undefined,
            related: undefined,
            subtitles: undefined,
        },
    };
    return removeEmpty(result) as PartialIconMap;
};

const transformIconsMap = (
    iconMenu: DefaultIcons['menu']
): MergedDefaultIcons['menu']['iconsMap'] => {
    const { iconsMap = {}, navbarFont: font } = iconMenu ?? {};

    const outputIconsMap: MergedDefaultIcons['menu']['iconsMap'] = {};
    Object.entries(iconsMap).forEach(([key, value]) => {
        if (typeof value === 'string') {
            outputIconsMap[key] = {
                name: value,
                font,
            };
        } else if (typeof value === 'object') {
            outputIconsMap[key] = value as IconMapItem;
        }
    });
    return outputIconsMap;
};

const embedCustomIcons = (iconsMap, customIcons: CustomIcons['icons']) => {
    const icons = Object.keys(customIcons).reduce((acc, key) => {
        const pngSizeIndex = isFactorMobile ? 2 : 4;
        const customActiveIcon = (customIcons[key].states as IconState)?.active;

        const svgUri = customIcons[key].svg;
        const { url: pngUri, size: pngSize } = customIcons[key].png[pngSizeIndex];

        const activeSvgUri = customActiveIcon?.svg;
        const activePngUri = customActiveIcon?.png[pngSizeIndex].url;

        const size = svgUri ? undefined : pngSize;
        const uri = svgUri || pngUri;
        const activeUri = activeSvgUri || activePngUri;

        acc[key] = {
            size,
            uri,
            activeUri,
        };

        return acc;
    }, {});

    return { ...iconsMap, ...icons };
};

const transformThemeMenuIcons = (
    theme: DefaultTheme,
    customIcons: CustomIcons['icons']
): DefaultTheme => {
    const iconMap = transformIconsMap((theme as DefaultTheme).icons.menu);
    const tehemeIconsCopy = { ...theme.icons };
    [
        'about',
        'auth',
        'chromecast',
        'common',
        'epg',
        'myContent',
        'packshot',
        'player',
        'search',
        'settings',
        'account',
        'pinControl',
        'details',
        'menu',
    ].forEach((e) => delete tehemeIconsCopy[e]);

    return {
        ...theme,
        icons: {
            ...tehemeIconsCopy,
            about: theme.icons.about,
            auth: theme.icons.auth,
            chromecast: theme.icons.chromecast,
            common: theme.icons.common,
            epg: theme.icons.epg,
            myContent: theme.icons.myContent,
            packshot: theme.icons.packshot,
            player: theme.icons.player,
            search: theme.icons.search,
            settings: theme.icons.settings,
            account: theme.icons.account,
            pinControl: theme.icons.pinControl,
            details: theme.icons.details,
            menu: {
                ...theme.icons.menu,
                iconsMap: embedCustomIcons(iconMap, customIcons),
            },
        },
    };
};
const parseLocalThemeIconToIcons = (localIcons: ThemeResponseItem['icons']) => ({
    'onButtonPrimary-locked': mapThemeIconToIcon(localIcons['onButtonPrimary-locked']),
    'onButtonPrimary-play': mapThemeIconToIcon(localIcons['onButtonPrimary-play']),
    'onButtonPrimary-purchase': mapThemeIconToIcon(localIcons['onButtonPrimary-purchase']),
    'onButtonPrimary-purchase_multipleOptions': mapThemeIconToIcon(
        localIcons['onButtonPrimary-purchase_multipleOptions']
    ),
    'onButtonPrimary-record': mapThemeIconToIcon(localIcons['onButtonPrimary-record']),
    'onButtonPrimary-recordMultiple': mapThemeIconToIcon(
        localIcons['onButtonPrimary-recordMultiple']
    ),
    'onButtonPrimary-recordMultiple_cancel': mapThemeIconToIcon(
        localIcons['onButtonPrimary-recordMultiple_cancel']
    ),
    'onButtonPrimary-record_cancel': mapThemeIconToIcon(
        localIcons['onButtonPrimary-record_cancel']
    ),
    'onButtonPrimary-rent': mapThemeIconToIcon(localIcons['onButtonPrimary-rent']),
    'onButtonPrimary-upsell': mapThemeIconToIcon(localIcons['onButtonPrimary-upsell']),
    'onButtonSecondary-download': mapThemeIconToIcon(localIcons['onButtonSecondary-download']),
    'onButtonSecondary-info': mapThemeIconToIcon(localIcons['onButtonSecondary-info']),
    'onButtonSecondary-record': mapThemeIconToIcon(localIcons['onButtonSecondary-record']),
    'onButtonSecondary-recordMultiple': mapThemeIconToIcon(
        localIcons['onButtonSecondary-recordMultiple']
    ),
    'onButtonSecondary-recordMultiple_cancel': mapThemeIconToIcon(
        localIcons['onButtonSecondary-recordMultiple_cancel']
    ),
    'onButtonSecondary-record_cancel': mapThemeIconToIcon(
        localIcons['onButtonSecondary-record_cancel']
    ),
    'onButtonSecondary-reminder_add': mapThemeIconToIcon(
        localIcons['onButtonSecondary-reminder_add']
    ),
    'onButtonSecondary-reminder_added': mapThemeIconToIcon(
        localIcons['onButtonSecondary-reminder_added']
    ),
    'onButtonSecondary-share': mapThemeIconToIcon(localIcons['onButtonSecondary-share']),
    'onButtonSecondary-startOver': mapThemeIconToIcon(localIcons['onButtonSecondary-startOver']),
    'onButtonSecondary-watchlist_add': mapThemeIconToIcon(
        localIcons['onButtonSecondary-watchlist_add']
    ),
    'onButtonSecondary-watchlist_added': mapThemeIconToIcon(
        localIcons['onButtonSecondary-watchlist_added']
    ),
    'onButtonSecondary-geoblocked': mapThemeIconToIcon(localIcons['onButtonSecondary-geoblocked']),
    'onButtonTertiary-download': mapThemeIconToIcon(localIcons['onButtonTertiary-download']),
    'onButtonTertiary-info': mapThemeIconToIcon(localIcons['onButtonTertiary-info']),
    'onButtonTertiary-record': mapThemeIconToIcon(localIcons['onButtonTertiary-record']),
    'onButtonTertiary-recordMultiple': mapThemeIconToIcon(
        localIcons['onButtonTertiary-recordMultiple']
    ),
    'onButtonTertiary-recordMultiple_cancel': mapThemeIconToIcon(
        localIcons['onButtonTertiary-recordMultiple_cancel']
    ),
    'onButtonTertiary-record_cancel': mapThemeIconToIcon(
        localIcons['onButtonTertiary-record_cancel']
    ),
    'onButtonTertiary-reminder_add': mapThemeIconToIcon(
        localIcons['onButtonTertiary-reminder_add']
    ),
    'onButtonTertiary-reminder_added': mapThemeIconToIcon(
        localIcons['onButtonTertiary-reminder_added']
    ),
    'onButtonTertiary-share': mapThemeIconToIcon(localIcons['onButtonTertiary-share']),
    'onButtonTertiary-startOver': mapThemeIconToIcon(localIcons['onButtonTertiary-startOver']),
    'onButtonTertiary-watchlist_add': mapThemeIconToIcon(
        localIcons['onButtonTertiary-watchlist_add']
    ),
    'onButtonTertiary-watchlist_added': mapThemeIconToIcon(
        localIcons['onButtonTertiary-watchlist_added']
    ),
    'onDark-airplay': mapThemeIconToIcon(localIcons['onDark-airplay']),
    'onDark-allChannels': mapThemeIconToIcon(localIcons['onDark-allChannels']),
    'onDark-allEpisodes': mapThemeIconToIcon(localIcons['onDark-allEpisodes']),
    'onDark-back': mapThemeIconToIcon(localIcons['onDark-back']),
    'onDark-backToLive': mapThemeIconToIcon(localIcons['onDark-backToLive']),
    'onDark-back_rtl': mapThemeIconToIcon(localIcons['onDark-back_rtl']),
    'onDark-catchup': mapThemeIconToIcon(localIcons['onDark-catchup']),
    'onDark-chromecast': mapThemeIconToIcon(localIcons['onDark-chromecast']),
    'onDark-chromecast_connected': mapThemeIconToIcon(localIcons['onDark-chromecast_connected']),
    'onDark-close': mapThemeIconToIcon(localIcons['onDark-close']),
    'onDark-confirm': mapThemeIconToIcon(localIcons['onDark-confirm']),
    'onDark-control_fastForward': mapThemeIconToIcon(localIcons['onDark-control_fastForward']),
    'onDark-control_forward10': mapThemeIconToIcon(localIcons['onDark-control_forward10']),
    'onDark-control_info': mapThemeIconToIcon(localIcons['onDark-control_info']),
    'onDark-control_pause': mapThemeIconToIcon(localIcons['onDark-control_pause']),
    'onDark-control_play': mapThemeIconToIcon(localIcons['onDark-control_play']),
    'onDark-control_replay10': mapThemeIconToIcon(localIcons['onDark-control_replay10']),
    'onDark-control_rewind': mapThemeIconToIcon(localIcons['onDark-control_rewind']),
    'onDark-control_skipNext': mapThemeIconToIcon(localIcons['onDark-control_skipNext']),
    'onDark-control_skipPrevious': mapThemeIconToIcon(localIcons['onDark-control_skipPrevious']),
    'onDark-control_stop': mapThemeIconToIcon(localIcons['onDark-control_stop']),
    'onDark-control_upsell': mapThemeIconToIcon(localIcons['onDark-control_upsell']),
    'onDark-control_volume_down': mapThemeIconToIcon(localIcons['onDark-control_volume_down']),
    'onDark-control_volume_mute': mapThemeIconToIcon(localIcons['onDark-control_volume_mute']),
    'onDark-control_volume_off': mapThemeIconToIcon(localIcons['onDark-control_volume_off']),
    'onDark-control_volume_up': mapThemeIconToIcon(localIcons['onDark-control_volume_up']),
    'onDark-filter': mapThemeIconToIcon(localIcons['onDark-filter']),
    'onDark-fullscreen': mapThemeIconToIcon(localIcons['onDark-fullscreen']),
    'onDark-fullscreen_exit': mapThemeIconToIcon(localIcons['onDark-fullscreen_exit']),
    'onDark-overflow': mapThemeIconToIcon(localIcons['onDark-overflow']),
    'onDark-pictureInPicture': mapThemeIconToIcon(localIcons['onDark-pictureInPicture']),
    'onDark-rating': mapThemeIconToIcon(localIcons['onDark-rating']),
    'onDark-record': mapThemeIconToIcon(localIcons['onDark-record']),
    'onDark-recordMultiple': mapThemeIconToIcon(localIcons['onDark-recordMultiple']),
    'onDark-recordMultiple_cancel': mapThemeIconToIcon(localIcons['onDark-recordMultiple_cancel']),
    'onDark-record_cancel': mapThemeIconToIcon(localIcons['onDark-record_cancel']),
    'onDark-seeAll': mapThemeIconToIcon(localIcons['onDark-seeAll']),
    'onDark-select': mapThemeIconToIcon(localIcons['onDark-select']),
    'onDark-settings': mapThemeIconToIcon(localIcons['onDark-settings']),
    'onDark-share': mapThemeIconToIcon(localIcons['onDark-share']),
    'onDark-subtitles': mapThemeIconToIcon(localIcons['onDark-subtitles']),
    'onDark-tvGuide': mapThemeIconToIcon(localIcons['onDark-tvGuide']),
    'onLabel-catchup': mapThemeIconToIcon(localIcons['onLabel-catchup']),
    'onLabel-locked': mapThemeIconToIcon(localIcons['onLabel-locked']),
    'onLabel-record': mapThemeIconToIcon(localIcons['onLabel-record']),
    'onLabel-recordMultiple': mapThemeIconToIcon(localIcons['onLabel-recordMultiple']),
    'onLabel-reminder': mapThemeIconToIcon(localIcons['onLabel-reminder']),
    'onPrimary-airplay': mapThemeIconToIcon(localIcons['onPrimary-airplay']),
    'onPrimary-back': mapThemeIconToIcon(localIcons['onPrimary-back']),
    'onPrimary-back_rtl': mapThemeIconToIcon(localIcons['onPrimary-back_rtl']),
    'onPrimary-chromecast': mapThemeIconToIcon(localIcons['onPrimary-chromecast']),
    'onPrimary-chromecast_connected': mapThemeIconToIcon(
        localIcons['onPrimary-chromecast_connected']
    ),
    'onPrimary-clear': mapThemeIconToIcon(localIcons['onPrimary-clear']),
    'onPrimary-close': mapThemeIconToIcon(localIcons['onPrimary-close']),
    'onPrimary-confirm': mapThemeIconToIcon(localIcons['onPrimary-confirm']),
    'onPrimary-delete': mapThemeIconToIcon(localIcons['onPrimary-delete']),
    'onPrimary-device_family': mapThemeIconToIcon(localIcons['onPrimary-device_family']),
    'onPrimary-device_gameConsole': mapThemeIconToIcon(localIcons['onPrimary-device_gameConsole']),
    'onPrimary-device_laptop': mapThemeIconToIcon(localIcons['onPrimary-device_laptop']),
    'onPrimary-device_smartphone': mapThemeIconToIcon(localIcons['onPrimary-device_smartphone']),
    'onPrimary-device_smartwatch': mapThemeIconToIcon(localIcons['onPrimary-device_smartwatch']),
    'onPrimary-device_tablet': mapThemeIconToIcon(localIcons['onPrimary-device_tablet']),
    'onPrimary-device_tv': mapThemeIconToIcon(localIcons['onPrimary-device_tv']),
    'onPrimary-error': mapThemeIconToIcon(localIcons['onPrimary-error']),
    'onPrimary-expiration': mapThemeIconToIcon(localIcons['onPrimary-expiration']),
    'onPrimary-field_clear': mapThemeIconToIcon(localIcons['onPrimary-field_clear']),
    'onPrimary-field_search': mapThemeIconToIcon(localIcons['onPrimary-field_search']),
    'onPrimary-filter': mapThemeIconToIcon(localIcons['onPrimary-filter']),
    'onPrimary-overflow': mapThemeIconToIcon(localIcons['onPrimary-overflow']),
    'onPrimary-rating': mapThemeIconToIcon(localIcons['onPrimary-rating']),
    'onPrimary-scroll_down': mapThemeIconToIcon(localIcons['onPrimary-scroll_down']),
    'onPrimary-scroll_up': mapThemeIconToIcon(localIcons['onPrimary-scroll_up']),
    'onPrimary-seeAll': mapThemeIconToIcon(localIcons['onPrimary-seeAll']),
    'onPrimary-select': mapThemeIconToIcon(localIcons['onPrimary-select']),
    'onPrimary-share': mapThemeIconToIcon(localIcons['onPrimary-share']),
    'onPrimary-signOut': mapThemeIconToIcon(localIcons['onPrimary-signOut']),
    'onPrimary-spinner': mapThemeIconToIcon(localIcons['onPrimary-spinner']),
    'onPrimary-subpage': mapThemeIconToIcon(localIcons['onPrimary-subpage']),
    'onPrimary-subpage_rtl': mapThemeIconToIcon(localIcons['onPrimary-subpage_rtl']),
    'onTileMainButton-edit': mapThemeIconToIcon(localIcons['onTileMainButton-edit']),
    'onTileMainButton-play': mapThemeIconToIcon(localIcons['onTileMainButton-play']),
    'onTileMainButton-pause': mapThemeIconToIcon(localIcons['onTileMainButton-pause']),
    'onTileMainButton-cancel': mapThemeIconToIcon(localIcons['onTileMainButton-cancel']),
    'onTileMainButton-delete': mapThemeIconToIcon(localIcons['onTileMainButton-delete']),
    'onTileMainButton-purchase': mapThemeIconToIcon(localIcons['onTileMainButton-purchase']),
});

const mergeResponseWithDefaultTheme = (
    theme: ThemeResponseItem,
    defaultTheme: DefaultTheme,
    customIconResponse: CustomIcons
): DefaultTheme => {
    const parsedThemeIcons = removeEmpty(
        parseLocalThemeIconToIcons(theme.icons)
    ) as unknown as DefaultTheme['icons'];

    const mergedIcons = { ...defaultTheme.icons, ...parsedThemeIcons };

    const themeWithIcons: DefaultTheme = {
        ...defaultTheme,
        icons: mergedIcons,
    };
    const mergedTheme: DefaultTheme = mergeThemes(themeWithIcons, theme);
    return transformThemeMenuIcons(mergedTheme, customIconResponse.icons);
};

export const mapThemeResponse = (
    theme: ThemeResponseItem,
    defaultTheme: DefaultTheme,
    customIconResponse: CustomIcons
): Theme => {
    const { id, colors, name, fonts, elements, icons }: DefaultTheme =
        mergeResponseWithDefaultTheme(theme, defaultTheme, customIconResponse);

    const themeObj = {
        id,
        name,
        fonts,
        elements,
        icons,
        logo: elements.clientLogo?.value,
        backgroundImage: elements.backgroundImage?.value,
        color: {
            background1: colors.background1?.color
                ? rgbaToHex(colors.background1.color).substring(0, 7)
                : undefined,

            primary1: colors.primary1?.color,
            primary2: colors.primary2?.color,
            primary3: colors.primary3?.color,
            primary4: colors.primary4?.color,

            primaryBlur1: {
                ...colors.primaryBlur1,
                blur: colors.primaryBlur1?.blur
                    ? parseInt(String(colors.primaryBlur1.blur), 10)
                    : 10,
            },
            primaryBlur1Fallback: colors.primaryBlur1Fallback?.color,
            primaryBlur2: {
                ...colors.primaryBlur2,
                blur: colors.primaryBlur2?.blur
                    ? parseInt(String(colors.primaryBlur2.blur), 10)
                    : undefined,
            },
            primaryBlur2Fallback: colors.primaryBlur2Fallback?.color,

            lighter1: colors.lighter1?.color,
            lighter2: colors.lighter2?.color,
            lighter3: colors.lighter3?.color,
            lighter4: colors.lighter4?.color,

            lighterBlur1: {
                ...colors.lighterBlur1,
                blur: colors.lighterBlur1?.blur
                    ? parseInt(String(colors.lighterBlur1.blur), 10)
                    : undefined,
            },
            lighterBlur1Fallback: colors.lighterBlur1Fallback?.color,
            lighterBlur2: {
                ...colors.lighterBlur2,
                blur: colors.lighterBlur2?.blur
                    ? parseInt(String(colors.lighterBlur2.blur), 10)
                    : undefined,
            },
            lighterBlur2Fallback: colors.lighterBlur2Fallback?.color,

            darker1: colors.darker1?.color,
            darker2: colors.darker2?.color,
            darker3: colors.darker3?.color,
            darker4: colors.darker4?.color,

            darkerBlur1: {
                ...colors.darkerBlur1,
                blur: colors.darkerBlur1?.blur
                    ? parseInt(String(colors.darkerBlur1.blur), 10)
                    : undefined,
            },
            darkerBlur1Fallback: colors.darkerBlur1Fallback?.color,
            darkerBlur2: {
                ...colors.darkerBlur2,
                blur: colors.darkerBlur2?.blur
                    ? parseInt(String(colors.darkerBlur2.blur), 10)
                    : undefined,
            },
            darkerBlur2Fallback: colors.darkerBlur2Fallback?.color,

            contrast1: colors.contrast1?.color,
            contrast2: colors.contrast2?.color,
            contrast3: colors.contrast3?.color,
            contrast4: colors.contrast4?.color,
            contrast5: colors.contrast5?.color,

            contrastBlur1: {
                ...colors.contrastBlur1,
                blur: colors.contrastBlur1?.blur
                    ? parseInt(String(colors.contrastBlur1.blur), 10)
                    : undefined,
            },
            contrastBlur1Fallback: colors.contrastBlur1Fallback?.color,
            contrastBlur2: {
                ...colors.contrastBlur2,
                blur: colors.contrastBlur2?.blur
                    ? parseInt(String(colors.contrastBlur2.blur), 10)
                    : undefined,
            },
            contrastBlur2Fallback: colors.contrastBlur2Fallback?.color,
            gradient1: {
                ...colors.gradient1,
                ...getGradientColors(colors.gradient1?.color, true),
            },
            gradient2: {
                ...colors.gradient2,
                ...getGradientColors(colors.gradient2?.color, true),
            },
            gradient3: {
                ...colors.gradient3,
                ...getGradientColors(colors.gradient3?.color, true),
            },
            error: colors.error?.color,
            warning: colors.warning?.color,
            success: colors.success?.color,

            textPrimary: colors.textPrimary?.color
                ? rgbaToHex(colors.textPrimary.color).substring(0, 7)
                : undefined,
            textSecondary: colors.textSecondary?.color
                ? rgbaToHex(colors.textSecondary.color).substring(0, 7)
                : undefined,
            textButtonPrimary: colors.textButtonPrimary?.color,
            textButtonSecondary: colors.textButtonSecondary?.color,
            textButtonTertiary: colors.textButtonTertiary?.color,
            buttonPrimary: colors.buttonPrimary?.color,
            buttonSecondary: colors.buttonSecondary?.color,
            buttonTertiary: colors.buttonTertiary?.color,

            labelPrimary: colors.labelPrimary?.color,
            labelSecondary: colors.labelSecondary?.color,

            progressBarAccent: colors['progressBar-accent']?.color,
            progressBarPreAccent: colors['progressBar-preAccent']?.color,
            progressBarLive: colors['progressBar-live']?.color,
            progressBarBackground: colors['progressBar-background']?.color,

            menuItemActive: colors['textMenuItem-active']?.color
                ? rgbaToHex(colors['textMenuItem-active'].color).substring(0, 7)
                : undefined,
            menuItemInactive: colors['textMenuItem-inactive']?.color
                ? colors['textMenuItem-inactive'].color
                : undefined,
            menuFocus: colors.menuFocus?.color,

            tileMainButton: colors.tileMainButton?.color,

            epgRowActive: colors['epgItem-active']?.color,
            epgRowInactive: colors['epgItem-inactive']?.color,
            epgRowActiveWhole: colors['epgItem-active']?.color,
            epgRowActivePassed: colors['epgItem-active']?.color,
            epgTop: colors.darkerBlur1?.color,
            epgSide: colors.background1?.color,
            epgBackground: colors.background1?.color,

            // not sure if this is correct
            statusBarTheme: colors.statusBarTheme,
        },
    };

    let resultTheme: typeof themeObj | Theme = themeObj;
    try {
        resultTheme = createTheme(themeObj);
    } catch (error) {
        logError(`Theme ${themeObj.id} does not pass validation`, error);
    }

    return resultTheme as unknown as Theme;
};
