import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

export const usePreviousRoute = () => {
    const { asPath, events } = useRouter();
    const prevUrlRef = useRef<string | null>(null);

    useEffect(() => {
        const handleRouteChange = () => {
            prevUrlRef.current = asPath;
        };

        events.on('routeChangeStart', handleRouteChange);

        return () => {
            events.off('routeChangeStart', handleRouteChange);
        };
    }, [asPath, events]);

    return prevUrlRef.current;
};
