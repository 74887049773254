import { createGuard } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { Episode, EpisodeGuard } from './episode';
import { ASSET_TYPE } from '../enums';
import { Asset } from './asset';
import { getIsNewsSeries, getIsNewsEpisode } from './news';
import { getIsPodcastSeries } from './podcast';

export const SeasonGuard = createGuard(
    'Season',
    {
        name: t.string,
        episodes: t.array(EpisodeGuard),
    },
    {
        seasonNumber: t.number,
    }
);

export type Season = t.TypeOf<typeof SeasonGuard>;

export const getAssetCanHaveSeasons = (assetType: ASSET_TYPE) =>
    [ASSET_TYPE.SERIES, ASSET_TYPE.PODCAST_SERIES, ASSET_TYPE.NEWS_SERIES].includes(assetType);

export const getEpisodesSorting = (asset: Asset) => {
    if (getIsNewsSeries(asset) || getIsNewsEpisode(asset)) {
        return (a: Episode, b: Episode) => (b.releaseDateTime ?? 0) - (a.releaseDateTime ?? 0);
    }

    if (getIsPodcastSeries(asset)) {
        return (a: Episode, b: Episode) => b.episodeNumber - a.episodeNumber;
    }

    return (a: Episode, b: Episode) => a.episodeNumber - b.episodeNumber;
};
