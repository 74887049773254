import React, { ReactElement } from 'react';
import { MoreScreenAuthSectionProps } from './types';
import View from './View';
import { useViewModel } from './viewModel';

export { default as View, getMoreScreenAuthSectionStyles } from './View';

const Main = (props: MoreScreenAuthSectionProps): ReactElement => {
    const viewModelProps = useViewModel(props);
    return <View {...viewModelProps} />;
};

export default Main;
