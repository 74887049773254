import React, { FC, useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from '@cleeng/mediastore-sdk';

import { CleengSettings } from '@24i/nxg-sdk-photon';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';

import { areValidCleengSettings, initialiseCleeng } from '../../utils/cleeng';

/*
Cleeng Mediastore SDK documentation: Documentation https://24imedia.atlassian.net/wiki/spaces/PRJ001NXG/pages/3373891585/Cleeng+MediaStore+SDK+integration+inside+24i
*/

export const CleengProvider: FC = ({ children }) => {
    const subscriptionsProvider = useFeature('subscriptionsProvider');
    const isCleeng = subscriptionsProvider?.name === 'cleeng';

    useEffect(() => {
        if (isCleeng && areValidCleengSettings(subscriptionsProvider?.settings)) {
            initialiseCleeng(subscriptionsProvider?.settings as CleengSettings);
        }
    }, [isCleeng, subscriptionsProvider?.settings]);

    if (!isCleeng) return <>{children}</>;

    return <Provider store={store}>{children}</Provider>;
};
