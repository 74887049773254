/* eslint-disable camelcase */
import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { Payload, VideoProgressResult } from '../types';
import { EVENTS } from '../constants';
import { mapBase } from './mapBase';

export const mapVideoProgress = (
    triggerName: ANALYTICS_TRIGGERS,
    payload: Payload
): VideoProgressResult => {
    const progressPct = Math.round(Number(triggerName.split('_')[1]));
    return {
        ...mapBase(payload),
        action: EVENTS.VIDEO_PROGRES,
        event_trigger: triggerName,
        thing_id: payload.payload.asset?.id || '',
        progress_pct: progressPct,
        duration: payload.payload.duration,
    };
};
