import { useCallback, useRef } from 'react';
import { AnalyticsEvent } from '@24i/nxg-sdk-photon/src/analytics/base';
import { PlaybackEvent } from '@24i/nxg-sdk-photon/src/analytics/player';
import { PLAYBACK_TYPE } from '@24i/nxg-sdk-photon/src/analytics/eventTypes';
import { useCommonEventParams } from '../../hooks';
import { usePlayerSessionId } from '../usePlayerSessionId';
import { PlaybackState } from './playbackState';

export type BuildPlaybackEventFunction = (
    type: PLAYBACK_TYPE,
    playbackState?: PlaybackState
) => PlaybackEvent | undefined;

export const buildPlaybackEvent = (
    type: PLAYBACK_TYPE,
    playerSessionId = '',
    playbackState?: PlaybackState,
    commonEventParams?: AnalyticsEvent
): PlaybackEvent | undefined => {
    if (!playbackState || !playbackState.asset || !playbackState.source) return undefined;
    const { asset, source, seriesData, currentTime = 0, duration = 0 } = playbackState;
    if (!asset || !source) return undefined;
    const percentOfPlayback = currentTime >= 0 && duration > 0 ? (currentTime / duration) * 100 : 0;
    const assetDuration = Math.round(duration / 1000 || asset.duration || 0);
    return {
        ...commonEventParams,
        type,
        asset,
        source,
        seriesData,
        currentPlaybackTimeMs: currentTime,
        duration: assetDuration,
        percentOfPlayback,
        playerSessionId,
        timestamp: Date.now(),
    };
};

export const useBuildPlaybackEvent = (): BuildPlaybackEventFunction => {
    const commonEventParams = useRef<AnalyticsEvent>();
    commonEventParams.current = useCommonEventParams();
    const { getPlayerSessionIdFor } = usePlayerSessionId();
    return useCallback<BuildPlaybackEventFunction>((type, playbackState) => {
        const playbackEvent = buildPlaybackEvent(
            type,
            undefined,
            playbackState,
            commonEventParams.current
        );
        if (playbackEvent) {
            playbackEvent.playerSessionId = getPlayerSessionIdFor(playbackEvent) || '';
        }
        return playbackEvent;
    }, []);
};
