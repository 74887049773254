import React, { FC } from 'react';
import { Text } from '@24i/nxg-sdk-quarks';
import { formatDurationText } from '@24i/nxg-core-utils';
import { useTranslation } from 'react-i18next';
import { EpisodePickerItemProps } from '../types';
import { getEpisodeReleaseDateInfo } from '../../utils';

type Props = Pick<EpisodePickerItemProps, 'item' | 'styles'>;

export const EpisodeSubtitle: FC<Props> = ({ styles, item }) => {
    const { duration, fullReleaseDate } = item;
    const { i18n } = useTranslation(['sott']);

    if (!duration && !fullReleaseDate) return null;

    const { releaseDate, separator } = getEpisodeReleaseDateInfo(item, i18n.language);
    const durationToRender = duration ? formatDurationText(duration * 1000) : '';

    return (
        <Text style={styles?.episodeDurationText} numberOfLines={2}>
            {`${releaseDate}${separator}${durationToRender}`}
        </Text>
    );
};
