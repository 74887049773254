import {
    ACCESS_MODE,
    AppSettings,
    QUERY_KEYS,
    SECTION_ITEMS_ACTION,
} from '@24i/nxg-sdk-photon/src';
import { Linking } from 'react-native';
import { QueryClient, QueryFunction } from 'react-query';
import {
    SOTT_DEFAULT_ROOT_SCREENS,
    SOTT_DEFAULT_SCREENS,
} from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { WebScreenLink } from '@24i/nxg-sdk-smartott/src/navigation/types';
import { getPaymentGateResultFromUrl } from '@24i/nxg-sdk-smartott/src/hooks/useWebPurchaseCheckout/utils';

const DEFAULT_ACCESS_MODE = ACCESS_MODE.USER;
const LANDINGPATH = SECTION_ITEMS_ACTION.NAVIGATE_TO_LANDING;

/**
 * Checks that screen can be accessed without authentication based on
 * the current screen access mode and guestMode setting.
 */
export const isAllowedByScreenMode = (
    config: AppSettings | undefined,
    screenAccessMode: ACCESS_MODE = DEFAULT_ACCESS_MODE
) => {
    if (screenAccessMode === ACCESS_MODE.ALL) {
        return true;
    }

    if (screenAccessMode === ACCESS_MODE.GUEST && config?.features.guestMode?.enabled) {
        return true;
    }

    return false;
};

export const fetch = async <T>(
    queryKey: QUERY_KEYS,
    fetchFunction: QueryFunction<T>,
    queryClient: QueryClient
): Promise<T | undefined> => {
    await queryClient.prefetchQuery<T>(queryKey, fetchFunction, { staleTime: 10000 });
    return queryClient.getQueryData(queryKey);
};

export const goTo = (
    navigation,
    router,
    screen?: SOTT_DEFAULT_ROOT_SCREENS,
    subScreen?: SOTT_DEFAULT_SCREENS,
    link?: WebScreenLink<unknown>,
    queryParams?: { [key: string]: unknown }
) => {
    if (router) {
        if (link) {
            if (queryParams) {
                router.replace({
                    pathname: link.href,
                    query: queryParams,
                });
                return;
            }
            router.replace(link.href, link.as);
        } else {
            router.replace(subScreen);
        }
        return;
    }

    if (navigation) {
        setTimeout(
            () => {
                navigation.reset({
                    index: 0,
                    routes: [
                        {
                            name: screen,
                            params: { screen: subScreen, params: queryParams },
                        },
                    ],
                });
            }
            // TODO: I have no idea why but if the navigation event occurs immediately an error is thrown.
            // TODO: This only manifests on TvOS and is related to focus guides
        );
    }
};

// Function that checks if user has made a payment through onboarding flow. If so, it will come back to onboarding step which came from.
export const isRedirectFromOnboardingPaymentGate = (path: string): boolean => {
    const paymentGateResult = getPaymentGateResultFromUrl(path);
    return !!paymentGateResult && !!paymentGateResult.onboarding;
};

export const getUrl = async () => {
    const url = await Linking.getInitialURL();
    return url;
};
export function parseUrl(url) {
    // eslint-disable-next-line no-useless-escape
    const regex = /(details|player)\/(\w+)\/([^\/?]+)/;
    const match = url.match(regex);

    if (!match || match.length !== 4) {
        throw new Error('Invalid URL format');
    }

    const [, pageType, type, id] = match;

    return { pageType, type, id };
}

export const getParams = async (path) => {
    const url = typeof path === 'string' ? path : await getUrl();
    const params = typeof path === 'object' ? path : parseUrl(url);

    return params;
};

export const isRedirectFromOnboardingIntro = (path: string): boolean => path.includes(LANDINGPATH);
