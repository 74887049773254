import { isPlatformWeb } from 'renative';
import { isMobile, isTablet } from 'react-device-detect';
import { BASE_GAP } from '@24i/nxg-core-utils/src/constants';

export const isEmptyString = (s) => typeof s !== 'string' || s.length === 0;
export const isInCorrectBucket = (width, currentBucket, nextBucket) => {
    if (!nextBucket) {
        return true;
    }

    const maxWidth = currentBucket.value?.maxWidth;
    const minWidth = currentBucket.value?.minWidth;
    const nextMinWidth = nextBucket.value?.minWidth;

    let isCorrect;
    if (maxWidth && minWidth) {
        isCorrect = width <= maxWidth && width >= minWidth;
    } else if (maxWidth) {
        isCorrect = width <= maxWidth;
    } else if (minWidth) {
        isCorrect = width >= minWidth && (nextMinWidth ? width < nextMinWidth : true);
    } else {
        throw new Error('missing either "minWidth" or "maxWidth"');
    }

    return isCorrect;
};

export const transformToArray = (sizeBucketsObject) =>
    Object.keys(sizeBucketsObject).map((key) => ({
        key,
        value: sizeBucketsObject[key],
    }));

export const sortSizeBuckets = (sizesArray) => {
    // order sizes by smallest width
    sizesArray.sort((a, b) => {
        const sizeA = a.value?.maxWidth || a.value?.minWidth;
        const sizeB = b.value?.maxWidth || b.value?.minWidth;

        return sizeA - sizeB;
    });

    return sizesArray;
};

// Function to detect if web environment is running on a webview on iOS or Android
const webViewrules = [
    // if it says it's a webview, let's go with that
    'WebView',
    // iOS webview will be the same as safari but missing "Safari"
    '(iPhone|iPod|iPad|Macintosh)(?!.*Safari)',
    // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
    // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
    'Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})',
    // Android
    'Telegram-Android',
    // old chrome android webview agent
    'Linux; U; Android',
];

const webviewRegExp = new RegExp(`(${webViewrules.join('|')})`, 'ig');

export const isWebView = () => {
    if (!isPlatformWeb) return false;

    const { userAgent } = navigator;

    return Boolean(userAgent.match(webviewRegExp));
};

export const isDesktopWebView = () => {
    if (!isPlatformWeb) return false;

    const { userAgent } = navigator;
    return Boolean(userAgent.match('(Macintosh)(?!.*Safari)'));
};

export const getTelegramWebApp = () => {
    const initParams = window.Telegram?.WebView?.initParams;
    const webApp =
        initParams?.tgWebAppVersion && window.Telegram?.WebApp ? window.Telegram?.WebApp : null;

    return webApp;
};

export const getTelegramWebAppSafeAreaInsets = () => {
    const TelegramWebApp = getTelegramWebApp();
    return TelegramWebApp?.safeAreaInset;
};

export const getTelegramWebAppBottomSafeAreaInset = () => {
    const insets = getTelegramWebAppSafeAreaInsets();
    return insets?.bottom ?? 0;
};

export const getTelegramWebAppInsets = () => {
    const TelegramWebApp = getTelegramWebApp();
    return TelegramWebApp?.contentSafeAreaInset;
};

export const getTelegramWebAppTopInset = () => {
    const insets = getTelegramWebAppInsets();
    return insets?.top ?? 0;
};

export const isWebMobile = () =>
    // Tablet also tests true for mobile
    isMobile && !isTablet;

export const calculateTelegramGap = (baseGapRepetitions = 1) => {
    const telegramTopInset = getTelegramWebAppTopInset();

    if (telegramTopInset < 1) return 0;

    return BASE_GAP * baseGapRepetitions + telegramTopInset;
};

export const expandTelegramApp = () => {
    const TelegramApp = getTelegramWebApp();

    if (TelegramApp) {
        TelegramApp.expand();
    }
};
