import { extendGuard } from '@24i/nxg-core-utils/src/guards';
import * as t from 'io-ts';
import { formatReleaseDateTime } from '@24i/nxg-core-utils';
import { ASSET_TYPE, BLOCKING_REASON_TYPES } from '../enums';
import { Asset, AssetGuard } from './asset';
import { SeasonGuard } from './season';
import { Episode } from './episode';
import { getIsNewsEpisode } from './news';
import { getIsPodcastEpisode } from './podcast';

export const SeriesGuard = extendGuard(
    'Series',
    AssetGuard,
    {
        seasons: t.array(SeasonGuard),
        year: t.number,
    },
    {}
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSeries = (asset: any): asset is Series => {
    return asset && asset.type === ASSET_TYPE.SERIES;
};

export const isAnnouncedSeries = (asset: any): asset is Series => {
    return (
        isSeries(asset) &&
        (asset.blocked || []).some((block) => block.reason === BLOCKING_REASON_TYPES.BLOCKED_WINDOW)
    );
};

export type Series = t.TypeOf<typeof SeriesGuard>;
export type isAnnouncedSeries = Series;

export const resolveSeriesTypeToFetch = (asset: Asset | Episode | Series) => {
    const isPodcastEpisode = getIsPodcastEpisode(asset);
    const isNewsEpisode = getIsNewsEpisode(asset);

    if (isPodcastEpisode) return ASSET_TYPE.PODCAST_SERIES;
    if (isNewsEpisode) return ASSET_TYPE.NEWS_SERIES;
    return ASSET_TYPE.SERIES;
};

export const getProgressBarEpisodeLabel = (episodeToWatch: Asset, currentLocale: string) => {
    if (getIsNewsEpisode(episodeToWatch)) {
        if (!episodeToWatch.releaseDateTime) return episodeToWatch.title;
        return `${formatReleaseDateTime(episodeToWatch.releaseDateTime, currentLocale)} - ${
            episodeToWatch.title
        }`;
    }

    return `S${episodeToWatch.seasonNumber} E${episodeToWatch.episodeNumber} - ${episodeToWatch.title}`;
};
