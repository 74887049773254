import React from 'react';
import { useTranslation } from 'react-i18next';

import View from './View';
import { WithWebHeaderProps } from '../../navigation/components/withWebHeader/types';
import { useShouldShowTopNavbar } from '../../hooks/useShouldShowTopNavbar';

export { default as View, getLanguageScreenStyles } from './View';

const Main = (props) => {
    const { shouldShowTopNavbar } = useShouldShowTopNavbar();
    const { t } = useTranslation();
    const webHeaderProps: WithWebHeaderProps = {
        fullWidth: !shouldShowTopNavbar,
        hasMargin: !shouldShowTopNavbar,
        noVerticalMargin: !shouldShowTopNavbar,
        backButtonTitle: !shouldShowTopNavbar ? t('common.back') : undefined,
    };
    return <View {...props} {...webHeaderProps} />;
};

export default Main;
