import EStyleSheet from 'react-native-extended-stylesheet';
import {
    isPlatformWeb,
    isFactorBrowser,
    isFactorTv,
    isPlatformIos,
    isPlatformTvos,
    isPlatformAndroidtv,
} from 'renative';
import { IsTablet } from '@24i/nxg-core-utils/src/globalSingletons';
import Ratio from '../Ratio';

const IS_APPLE = isPlatformIos || isPlatformTvos;

// eslint-disable-next-line no-underscore-dangle
export const _getFontObj = (fonts, size, variant, noLineHeight) => {
    let fontWeight = String(fonts[size][variant]?.weight);
    let fontFamily = fonts[size][variant]?.family || 'HK Grotesk';

    // we need to check isApple because if "${fontFamily} ${fontWeightName}" does not exist
    // in source code it will execute error in ios devices
    if (!IS_APPLE) {
        let fontWeightName;
        const fontWeightNumber = parseInt(fontWeight, 10);

        switch (fontWeightNumber) {
            case 100:
                fontWeightName = 'Thin';
                break;
            case 200:
                fontWeightName = 'ExtraLight';
                break;
            case 300:
                fontWeightName = 'Light';
                break;
            case 400:
                fontWeightName = 'Regular';
                break;
            case 500:
                fontWeightName = 'Medium';
                break;
            case 600:
                fontWeightName = 'SemiBold';
                break;
            case 700:
                fontWeightName = 'Bold';
                break;
            case 800:
                fontWeightName = 'ExtraBold';
                break;
            case 900:
                fontWeightName = 'Black';
                break;
            default:
                fontWeightName = 'Regular';
        }
        fontWeight = 'normal';
        fontFamily = `${fontFamily} ${fontWeightName}`;
    }

    const fontObj = {
        fontFamily,
        fontSize: isPlatformWeb
            ? `${fonts[size][variant]?.size}px`
            : Ratio(fonts[size][variant]?.size),
        fontWeight,
        letterSpacing: fonts[size][variant]?.character,
        fontStyle: fonts[size][variant]?.style,
        textTransform: fonts[size][variant]?.transform,
    };

    if (!noLineHeight) {
        fontObj.lineHeight = isPlatformWeb
            ? `${fonts[size][variant].line}px`
            : Ratio(fonts[size][variant]?.line);
    }

    return fontObj;
};

export const getMargins = (
    property = 'padding',
    spacingPercentage = '4%',
    allBreakPoints = false
) => {
    const left = `${property}Left`;
    const right = `${property}Right`;

    const margins = !allBreakPoints
        ? {
              '@media (max-width: 800px)': {
                  [left]: 20,
                  [right]: 20,
                  boxSizing: 'border-box',
              },
              '@media (min-width: 801px) and (max-width: 1200px)': {
                  [left]: 32,
                  [right]: 32,
                  boxSizing: 'border-box',
              },
              '@media (min-width: 1201px) and (max-width: 1600px)': {
                  [left]: 48,
                  [right]: 48,
                  boxSizing: 'border-box',
              },
          }
        : {};

    return {
        ...margins,
        ...{
            [left]: spacingPercentage,
            [right]: spacingPercentage,
        },
    };
};

export const getFont = ({ fonts }, variant, noLineHeight = false) => {
    if (!fonts || fonts?.length < 1) return {};
    const size = IsTablet.get() ? 'M' : isFactorBrowser ? 'L' : isFactorTv ? 'XL' : 'S';

    if (isPlatformWeb) {
        return {
            '@media (max-width: 800px)': _getFontObj(fonts, 'S', variant, noLineHeight),
            '@media (min-width: 801px) and (max-width: 1200px)': _getFontObj(
                fonts,
                'M',
                variant,
                noLineHeight
            ),
            ..._getFontObj(fonts, 'L', variant, noLineHeight),
        };
    }

    return _getFontObj(fonts, size, variant, noLineHeight);
};

/**
 * For when converting this file to TS
 * elements - object with corner radiuses, client logo and app background images
 * variant - string text defining which of the corner radiuses to return
 * return - object with border radius or empty
 */
export const getBorderRadius = (elements, variant) => {
    if (!elements || !variant) return {};
    const element = elements[variant];
    if (!element || !element.value) return {};
    if (isPlatformWeb) return { borderRadius: element.value };
    if (isPlatformAndroidtv)
        return {
            borderBottomLeftRadius: Ratio(parseInt(element.value, 10)),
            borderBottomRightRadius: Ratio(parseInt(element.value, 10)),
            borderTopLeftRadius: Ratio(parseInt(element.value, 10)),
            borderTopRightRadius: Ratio(parseInt(element.value, 10)),
        };
    if (isFactorTv) return { borderRadius: Ratio(parseInt(element.value, 10)) };
    return { borderRadius: parseInt(element.value, 10) };
};

export const initialize = (params) => {
    EStyleSheet.build(params);
};

export default EStyleSheet;
