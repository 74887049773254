import { useTheme } from '@24i/nxg-sdk-higgs';
import { Text, View } from '@24i/nxg-sdk-quarks';
import React, { memo, useEffect, useState } from 'react';
import { Animated, Easing, Platform } from 'react-native';
import { PACKSHOT_TEST_IDS } from '../../test-utils';
import CustomItemTitle from '../CustomItemTitle';
import { getPackshotTitleStyles } from './styles';
import { PackshotTitleProps } from './types';

const animation = (element, toValue) =>
    Animated.timing(element, {
        toValue,
        duration: 150,
        delay: 100,
        useNativeDriver: true,
        easing: Easing.ease,
    });

const PackshotTitle = (props: PackshotTitleProps) => {
    const {
        isGenre = false,
        isChannel = false,
        textPlacement = 'below',
        styles: getStyles = getPackshotTitleStyles,
        subtitle = '',
        time = '',
        title = '',
        testID = '',
        infoTestId,
        isEpisode = false,
        bottomDescription,
        isCustom,
        textColor,
    } = props;

    const [fadeAnim] = useState(new Animated.Value(Platform.isTV ? 0 : 1));
    useEffect(() => animation(fadeAnim, 1).start(), []);
    const { theme } = useTheme();
    const styles = getStyles(theme, textColor);
    const displayOver = isGenre || textPlacement === 'over';
    const titleStyles = displayOver ? styles.displayOver : styles.itemText;

    const getTitleContainerStyles = () => {
        if (displayOver) {
            return styles.titleContainer;
        }
        return styles.textWrapper;
    };

    const customItemTitleProps = {
        ...props,
        displayOver,
    };

    if (isCustom) {
        return <CustomItemTitle {...customItemTitleProps} />;
    }
    return (
        <Animated.View
            testID={testID}
            style={[
                getTitleContainerStyles(),
                {
                    opacity: fadeAnim,
                    scaleY: fadeAnim,
                },
            ]}
        >
            {!!time && (
                <Text
                    style={styles.itemTextSecondarySubs}
                    numberOfLines={1}
                    allowFontScaling={false}
                >
                    {time}
                </Text>
            )}
            {!!title && (
                <View style={styles.textContainer}>
                    <Text
                        style={titleStyles}
                        numberOfLines={1}
                        allowFontScaling={false}
                        testID={`${infoTestId}_${PACKSHOT_TEST_IDS.TITLE}`}
                    >
                        {title}
                    </Text>
                </View>
            )}
            {!!subtitle && !isChannel && (
                <Text
                    testID={`${infoTestId}_${isEpisode ? 'duration' : PACKSHOT_TEST_IDS.METADATA}`}
                    style={styles.itemTextSubs}
                    numberOfLines={1}
                    allowFontScaling={false}
                >
                    {subtitle}
                </Text>
            )}
            {!!bottomDescription && (
                <Text style={styles.bottomDescription} numberOfLines={2}>
                    {bottomDescription}
                </Text>
            )}
        </Animated.View>
    );
};

export default memo(PackshotTitle);
