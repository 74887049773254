import { ParamListBase } from '@react-navigation/native';
import type { Asset } from '@24i/nxg-sdk-photon';
import { OfferType } from '@24i/nxg-sdk-photon/src/enums';

import { ScreenLinkGetter } from './types';
import { ReferralAssetData } from '../utils/cleeng/types';

export const SOTT_HOME_FALLBACK_REFERENCE = '__home_fallback__';

/**
 * Special screens added by the SOTT navigation config builder
 */
export enum SOTT_DEFAULT_ROOT_SCREENS {
    MENU_STACK_SCREEN = 'MenuStack',
    OTHER_SCREENS_STACK_SCREEN = 'OtherStack',
}

// This is intended to be a list of all the screen names in Backstage.
// TO CONSIDER: -> This names might change from project to project.
// We should review this process and see if we can make it more generic.
export enum BACKSTAGE_SCREENS {
    SIGN_IN = 'sign-in',
}

export const SOTT_DEFAULT_SCREENS_PREFIX = 'appstage-screen-';

// TODO: This should be unified into a single enumeration const object that would contain all the screens and their versions under a specific ID
export enum SOTT_DEFAULT_SCREENS {
    // This screen is special for SOTT as the SOTT has no "home" screen setup by default
    // The navigation client is expected to designate one of the screens as the "home" screen
    HOME = 'appstage-screen-home',
    INDEX = 'appstage-screen-index',
    GENRE = 'appstage-screen-genre',
    PLAYBACK = 'appstage-screen-player',
    SIGN_IN = 'appstage-screen-signin',
    SIGN_IN_ADOBE_PRIMETIME = 'appstage-screen-signin-adobe-primetime',
    SIGN_UP_QR = 'appstage-screen-qr-register',
    SIGN_UP = 'appstage-screen-register',
    SIGN_UP_SUCCESS = 'appstage-screen-signup-success',
    SEARCH = 'appstage-screen-search',
    FORGOT_PASS = 'appstage-screen-forgotpassword',
    MORE = 'appstage-screen-more',
    MANAGE_PROFILES = 'appstage-screen-manageprofiles',
    WHO_IS_WATCHING = 'appstage-screen-profile',
    DETAILS = 'appstage-screen-details',
    ACCOUNT = 'appstage-screen-account',
    MY_CONTENT = 'appstage-screen-my-content',
    MY_LIST = 'appstage-screen-my-list',
    MY_PURCHASES = 'appstage-screen-my-purchases',
    RECORDINGS = 'appstage-screen-recordings',
    RECENTLY_WATCHED = 'appstage-screen-recently-watched',
    LANDING = 'appstage-screen-landing',
    TV_GUIDE = 'appstage-screen-tvguide',
    SETTINGS = 'appstage-screen-settings-page',
    WELCOME = 'appstage-screen-welcome',
    SIGN_OUT = 'appstage-screen-sign-out',
    UPSERT_PROFILE = 'appstage-screen-upsert-profile',
    ENTER_PIN = 'appstage-screen-enter-pin',
    PLAYLIST = 'appstage-screen-playlist',
    PAGE = 'appstage-screen-page',
    TEXT_PAGE = 'appstage-screen-text-page',
    MANAGE_DEVICES = 'appstage-screen-manage-devices',
    REMOVE_DEVICE = 'appstage-screen-remove-device',
    PASSWORD_RESET = 'appstage-screen-password-reset',
    SEE_ALL = 'appstage-screen-see-all',
    CREATE_PIN = 'appstage-screen-create-pin',
    TERMS_CONDITIONS = 'appstage-screen-terms-conditions',
    ADD_SUBSCRIPTION = 'appstage-screen-add-subscription',
    PURCHASE_SUBSCRIPTION = 'appstage-screen-purchase-subscription',
    PAYMENT_METHOD = 'appstage-screen-payment-method',
    PAYMENT_HISTORY = 'appstage-screen-payment-history',
    PAYMENT_RESULT = 'appstage-screen-payment-result',
    SUBSCRIPTIONS_LIST = 'appstage-screen-subscriptions-list',
    ONBOARDING_INTRO = 'appstage-screen-onboarding-intro',
    ADDITIONAL_INFO = 'appstage-screen-additional-info',
    ACCOUNT_DETAILS = 'appstage-screen-account-details',
    DELETE_ACCOUNT = 'appstage-screen-delete-account',
    DELETE_ACCOUNT_SUCCESS = 'appstage-screen-delete-account-success',
    ACCOUNT_SUBSCRIPTION = 'appstage-screen-account-subscription',
    ACCOUNT_PARENTAL_CONTROLS = 'appstage-screen-account-parental-controls',
    MY_SUBSCRIPTIONS = 'appstage-screen-my-subscriptions',
    FALLBACK = 'appstage-screen-fallback',
    LANGUAGE = 'appstage-screen-language',
    TECHNICAL_INFO = 'appstage-screen-technical-info',
    THEME = 'appstage-screen-theme',
    STREAMING = 'appstage-screen-streaming',
    SERVICE = 'appstage-screen-service',
    MODAL = 'appstage-screen-modal',
    SETTINGS_SCREEN = 'appstage-screen-settings',
    MVPD_SIGNIN = 'appstage-screen-mvpd-signin',
    SECOND_SCREEN_ACTIVATION = 'appstage-second-screen-activation',
}

export const SOTT_HOME_ROUTE = '/home';

export const SCREENS_WITH_CAST = [
    SOTT_DEFAULT_SCREENS.DETAILS,
    SOTT_DEFAULT_SCREENS.HOME,
    SOTT_DEFAULT_SCREENS.TV_GUIDE,
    SOTT_DEFAULT_ROOT_SCREENS.MENU_STACK_SCREEN,
];

const Home: ScreenLinkGetter = {
    getLink: () => ({ href: '/[slug]', as: SOTT_HOME_ROUTE }),
    getScreen: () => SOTT_DEFAULT_SCREENS.HOME,
};

export type DynarowScreenParams = { slug: string };

const Dynarow: ScreenLinkGetter<DynarowScreenParams> = {
    getLink: (params) => ({ href: '/[slug]', as: `/${params.slug}` }),
    getScreen: () => {
        throw new Error('not implemented');
    },
};

const NotFound: ScreenLinkGetter = {
    uniqueWebId: 'not-found',
    getLink: () => ({ href: '/404', as: '/404' }),
    getScreen: () => {
        throw new Error('not implemented');
    },
};

const CreateProfile: ScreenLinkGetter = {
    getLink: () => ({
        href: '/profile/create',
        as: `/profile/create`,
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.UPSERT_PROFILE,
};

export type ProfileEditScreenParams = { profileId: string };

const ProfileEdit: ScreenLinkGetter<ProfileEditScreenParams> = {
    getLink: (params) => ({
        href: '/profile/[profileId]/edit',
        as: `/profile/${params.profileId}/edit`,
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.UPSERT_PROFILE,
};

const ManageProfiles: ScreenLinkGetter = {
    getLink: () => ({ href: '/profile/manage', as: '/profile/manage' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.MANAGE_PROFILES,
};

const WhoIsWatching: ScreenLinkGetter = {
    getLink: () => ({ href: '/profile', as: '/profile' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.WHO_IS_WATCHING,
};

const RemoveDevice: ScreenLinkGetter = {
    getLink: () => ({ href: '/remove-device', as: '/remove-device' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.REMOVE_DEVICE,
};

const Search: ScreenLinkGetter = {
    getLink: () => ({ href: '/search', as: '/search' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.SEARCH,
};

const TvGuide: ScreenLinkGetter = {
    getLink: () => ({ href: '/tvguide', as: '/tvguide' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.TV_GUIDE,
};

const Settings: ScreenLinkGetter = {
    getLink: () => ({
        href: `/settings`,
        as: `/settings`,
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.SETTINGS,
};

export type PlaybackScreenParams = { type?: string; id?: string; asset?: Asset };

const Playback: ScreenLinkGetter<ParamListBase> = {
    getLink: (params) => ({
        href: `/player/[type]/[id]`,
        as: `/player/${params?.type}/${params?.id}`,
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.PLAYBACK,
};

export type DetailsScreenParams = { type: string; id: string; channelId?: string | null };

const Details: ScreenLinkGetter<ParamListBase> = {
    getLink: (params) => ({
        href: `/details/[type]/[id]`,
        as: `/details/${params.type}/${params.id}?channelId=${params.channelId ?? ''}`,
        query: {
            type: params.type,
            id: params.id,
            channelId: params.channelId,
        },
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.DETAILS,
};

export type PageScreenParams = { id: string };

const Page: ScreenLinkGetter<PageScreenParams> = {
    getLink: (params) => ({
        href: `/page/[id]`,
        as: `/page/${params.id}`,
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.PAGE,
};

export type GenreScreenParams = { genre: string; id: string; type: string };

const Genre: ScreenLinkGetter<GenreScreenParams> = {
    getLink: (params) => ({
        href: `/genre/[genre]/[id]/[type]`,
        as: `/genre/${params.genre}/${params.id}/${params.type}`,
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.GENRE,
};

export type SeeAllScreenParams = { pageId: string; sectionId: string };

const SeeAll: ScreenLinkGetter<SeeAllScreenParams> = {
    getLink: (params) => ({
        href: `/see-all/[pageId]/[sectionId]`,
        as: `/see-all/${params.pageId}/${params.sectionId}`,
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.SEE_ALL,
};

export type PlaylistScreenParams = { playlist: string };

const Playlist: ScreenLinkGetter<PlaylistScreenParams> = {
    getLink: (params) => ({
        href: `/playlists/[playlist]`,
        as: `/playlists/${params.playlist}`,
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.PLAYLIST,
};

const MyPurchases: ScreenLinkGetter = {
    getLink: () => ({ href: '/my-purchases', as: '/my-purchases' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.MY_PURCHASES,
};

const ForgotPassword: ScreenLinkGetter = {
    getLink: () => ({ href: '/forgotpassword', as: '/forgotpassword' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.FORGOT_PASS,
};

const SignIn: ScreenLinkGetter = {
    getLink: () => ({ href: '/signin', as: '/signin' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.SIGN_IN,
};

const SignUp: ScreenLinkGetter = {
    getLink: () => ({ href: '/register', as: '/register' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.SIGN_UP,
};

const SignUpSuccess: ScreenLinkGetter = {
    getLink: () => ({ href: '/signup-success', as: '/signup-success' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.SIGN_UP_SUCCESS,
};

const More: ScreenLinkGetter = {
    getLink: () => ({ href: '/more', as: '/more' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.MORE,
};

export type AccountScreenParams = { subScreen: string };

const Account: ScreenLinkGetter<AccountScreenParams | void> = {
    getLink: (params?: any) => ({
        href: `/account${params?.subScreen ? `/[type]` : ''}`,
        as: `/account${params?.subScreen ? `/${params?.subScreen}` : ''}`,
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.ACCOUNT,
};

const MyContent: ScreenLinkGetter = {
    getLink: () => ({
        href: '/my-content',
        as: '/my-content',
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.MY_CONTENT,
};

const MyList: ScreenLinkGetter = {
    getLink: () => ({
        href: `/my-list`,
        as: `/my-list`,
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.MY_LIST,
};

export type RecordingScreenParams = {
    status: string;
    id: string;
    containingMenuTitle: string;
};

const Recordings: ScreenLinkGetter<RecordingScreenParams | void> = {
    getLink: (params?: any) => ({
        href: `/recordings`,
        as: `/recordings`,
        query: params,
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.RECORDINGS,
};

const RecentlyWatched: ScreenLinkGetter = {
    getLink: () => ({
        href: `/recently-watched`,
        as: `/recently-watched`,
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.RECENTLY_WATCHED,
};

const Landing: ScreenLinkGetter = {
    getLink: () => ({ href: '/landing', as: '/landing' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.LANDING,
};

const SignOut: ScreenLinkGetter = {
    getLink: () => ({ href: '/signout', as: '/signout' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.SIGN_OUT,
};

const Welcome: ScreenLinkGetter = {
    getLink: () => ({ href: '/welcome', as: '/welcome' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.WELCOME,
};

const OnboardingIntro: ScreenLinkGetter = {
    getLink: () => ({ href: '/onboarding-intro', as: '/onboarding-intro' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.ONBOARDING_INTRO,
};

const FallbackPage: ScreenLinkGetter = {
    getLink: () => ({ href: '/fallback-page', as: '/fallback-page' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.FALLBACK,
};

const AdditionalInfo: ScreenLinkGetter = {
    getLink: () => ({ href: '/additional-info', as: '/additional-info' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.ADDITIONAL_INFO,
};

const TermsAndConditions: ScreenLinkGetter = {
    getLink: () => ({ href: '/terms-conditions', as: '/terms-conditions' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.TERMS_CONDITIONS,
};

const EnterPin: ScreenLinkGetter = {
    getLink: () => ({ href: '/enterpin', as: '/enterpin' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.ENTER_PIN,
};

const Language: ScreenLinkGetter = {
    getLink: () => ({ href: '/language', as: '/language' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.LANGUAGE,
};

const TechnicalInfo: ScreenLinkGetter = {
    getLink: () => ({ href: '/technicalInfo', as: '/technicalInfo' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.TECHNICAL_INFO,
};

const Streaming: ScreenLinkGetter = {
    getLink: () => ({ href: '/streaming', as: '/streaming' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.STREAMING,
};

const Theme: ScreenLinkGetter = {
    getLink: () => ({ href: '/theme', as: '/theme' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.THEME,
};

const Service: ScreenLinkGetter = {
    getLink: () => ({ href: '/service', as: '/service' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.SERVICE,
};

export type TextPageScreenParams = { id: string };

const TextPage: ScreenLinkGetter<TextPageScreenParams> = {
    getLink: (params) => ({
        href: `/text-page/[id]`,
        as: `/text-page/${params.id}`,
        query: {
            id: params.id,
        },
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.TEXT_PAGE,
};

export type SubMenuScreenParams = { slug: string };

const SubMenu: ScreenLinkGetter<SubMenuScreenParams> = {
    getLink: (params) => ({
        href: `/sub-menu/[slug]`,
        as: `/sub-menu/${params.slug}`,
        query: {
            slug: params.slug,
        },
    }),
    getScreen: () => {
        throw new Error('not implemented');
    },
};

const ManageDevices: ScreenLinkGetter = {
    getLink: () => ({
        href: `/manage-devices`,
        as: `/manage-devices`,
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.MANAGE_DEVICES,
};

const PasswordReset: ScreenLinkGetter = {
    getLink: () => ({ href: '/password-reset', as: '/password-reset' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.PASSWORD_RESET,
};

const AddSubscription: ScreenLinkGetter = {
    getLink: () => ({ href: '/add-subscription', as: '/add-subscription' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.ADD_SUBSCRIPTION,
};

export type PurchaseSubscriptionScreenParams = { offerId: string };
const PurchaseSubscription: ScreenLinkGetter<PurchaseSubscriptionScreenParams> = {
    getLink: (params) => ({
        href: `/purchase-subscription/[offerId]`,
        as: `/purchase-subscription/${params.offerId}`,
        query: {
            offerId: params.offerId,
        },
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.PURCHASE_SUBSCRIPTION,
};

const PaymentMethod: ScreenLinkGetter = {
    getLink: () => ({
        href: `/payment-method`,
        as: `/payment-method`,
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.PAYMENT_METHOD,
};

const PaymentHistory: ScreenLinkGetter = {
    getLink: () => ({
        href: `/payment-history`,
        as: `/payment-history`,
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.PAYMENT_HISTORY,
};

export type PaymentResultScreenParams = {
    offerType?: OfferType;
    onContinueUrl?: string;
    onCancelUrl?: string;
    onTryAgainUrl?: string;
};

const PaymentResult: ScreenLinkGetter<PaymentResultScreenParams> = {
    getLink: ({
        offerType = OfferType.SUBSCRIPTION,
        onContinueUrl,
        onCancelUrl,
        onTryAgainUrl,
    }) => ({
        href: `/payment-result`,
        as: `/payment-result?offerType=${offerType}&onContinueUrl=${
            onContinueUrl ?? ''
        }&onCancelUrl=${onCancelUrl ?? ''}&onTryAgainUrl=${onTryAgainUrl ?? ''}`,
        query: {
            offerType,
            onContinueUrl: onContinueUrl ?? '',
            onCancelUrl: onCancelUrl ?? '',
            onTryAgainUrl: onTryAgainUrl ?? '',
        },
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.PAYMENT_RESULT,
};

const SubscriptionsList: ScreenLinkGetter<Partial<ReferralAssetData>> = {
    getLink: (params) => {
        const referralAssetType = params.referralAssetType ?? '';
        const referralAssetId = params.referralAssetId ?? '';

        return {
            href: `/subscriptions-list`,
            as: `/subscriptions-list?referralAssetType=${referralAssetType}&referralAssetId=${referralAssetId}`,
            query: {
                referralAssetType,
                referralAssetId,
            },
        };
    },
    getScreen: () => SOTT_DEFAULT_SCREENS.SUBSCRIPTIONS_LIST,
};

const CreatePin: ScreenLinkGetter = {
    getLink: () => ({ href: '/create-pin', as: '/create-pin' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.CREATE_PIN,
};

const AccountDetails: ScreenLinkGetter = {
    getLink: () => ({ href: '/account-details', as: '/account-details' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.ACCOUNT_DETAILS,
};

const DeleteAccount: ScreenLinkGetter = {
    getLink: () => ({
        href: '/delete-account',
        as: '/delete-account',
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.DELETE_ACCOUNT,
};

const AccountSubscription: ScreenLinkGetter = {
    getLink: () => ({ href: '/account-subscription', as: '/account-subscription' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.ACCOUNT_SUBSCRIPTION,
};

const AccountParentalControls: ScreenLinkGetter = {
    getLink: () => ({ href: '/parental-controls', as: '/parental-controls' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.ACCOUNT_PARENTAL_CONTROLS,
};

const MySubscriptions: ScreenLinkGetter = {
    getLink: () => ({ href: '/my-subscriptions', as: '/my-subscriptions' }),
    getScreen: () => SOTT_DEFAULT_SCREENS.MY_SUBSCRIPTIONS,
};

const DeleteAccountSuccess: ScreenLinkGetter = {
    getLink: () => ({
        href: '/delete-account/success',
        as: '/delete-account/success',
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.DELETE_ACCOUNT_SUCCESS,
};

const MvpdSignIn: ScreenLinkGetter = {
    getLink: () => ({
        href: '/mvpd-signin',
        as: '/mvpd-signin',
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.MVPD_SIGNIN,
};

const SecondScreenActivation: ScreenLinkGetter = {
    getLink: () => ({
        href: '/second-screen-activation',
        as: '/second-screen-activation',
    }),
    getScreen: () => SOTT_DEFAULT_SCREENS.MVPD_SIGNIN,
};

// Note that some screens are using "subScreens" parameters and some are not
// If this feels inconsistent then we should rework it to a single solution.
// Currently it is done the way the application was approaching these links,
// if the page rendered the subpages itself, the links were done through the link parameter and etc...
export const SOTT_DEFAULT_WEB_SCREENS = {
    More,
    Home,
    Genre,
    SignUp,
    SignUpSuccess,
    MyList,
    SignIn,
    MvpdSignIn,
    SignOut,
    MyPurchases,
    Search,
    Account,
    Welcome,
    OnboardingIntro,
    AdditionalInfo,
    TermsAndConditions,
    Landing,
    Details,
    Dynarow,
    TvGuide,
    Playback,
    Page,
    NotFound,
    Settings,
    MyContent,
    Recordings,
    Playlist,
    ProfileEdit,
    WhoIsWatching,
    CreateProfile,
    ManageProfiles,
    ForgotPassword,
    RecentlyWatched,
    EnterPin,
    Language,
    TextPage,
    ManageDevices,
    RemoveDevice,
    PasswordReset,
    SeeAll,
    AddSubscription,
    PurchaseSubscription,
    PaymentHistory,
    PaymentMethod,
    PaymentResult,
    SubscriptionsList,
    CreatePin,
    AccountDetails,
    AccountSubscription,
    AccountParentalControls,
    DeleteAccount,
    DeleteAccountSuccess,
    MySubscriptions,
    FallbackPage,
    TechnicalInfo,
    Streaming,
    Theme,
    Service,
    SubMenu,
    SecondScreenActivation,
};

export const SOTT_SCREENS = SOTT_DEFAULT_WEB_SCREENS;

export const PROFILE_DROPDOWN = 'profileDropdown';
export const GUEST_DROPDOWN = 'guestDropdown';
