import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import EPGContainer from '@24i/nxg-sdk-epg/index.web';
import { getFont, useTheme } from '@24i/nxg-sdk-higgs';
import { ActivityIndicator, View } from '@24i/nxg-sdk-quarks';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { dayText } from '@24i/nxg-core-utils';
import DropdownPicker from '@24i/nxg-sdk-gluons/src/components/inputs/DropdownPicker/';
import { useDimensions } from '@24i/nxg-sdk-quantum';
import { isWebMobile } from '@24i/nxg-sdk-higgs/src/utils';

import { defaultGetLandscapeStyles } from '../styles/landscape';
import config from '../config';
import { WebEpgViewProps } from './types';
import getEpgScreenStyles from '../styles';
import PickerButton from '../components/PickerButton';
import { useShouldShowTopNavbar } from '../../../hooks/useShouldShowTopNavbar';

const EpgScreen = (props: WebEpgViewProps) => {
    const {
        data,
        selectedDate,
        onScroll,
        scrollLeft,
        scrollTop,
        pickerDates,
        isLoading,
        isFetching,
        is12HourClock,
        shouldBeLocked,
        onSelectionPress,
        getLandscapeStyles = defaultGetLandscapeStyles,
        onCellPress,
        onWebViewportScroll,
        getStyles = getEpgScreenStyles,
    } = props;
    const { theme } = useTheme();
    const { t } = useTranslation();
    const { viewPortWidth, isLandscape } = useDimensions();
    const { shouldShowTopNavbar } = useShouldShowTopNavbar();
    const styles = getStyles(theme);
    const additionalPickerStyles = useMemo(() => {
        if (!isWebMobile()) return undefined;
        return { flexGrow: isLandscape ? 0.5 : 1 };
    }, [isWebMobile, isLandscape]);

    const getDatePickerText = (datePickerOption: string | string[]) => {
        const day = t(`date.${datePickerOption[0]}`).substring(0, 3);
        const month = t(`date.${datePickerOption[1]}`).substring(0, 3);
        const year = datePickerOption[2];

        return datePickerOption instanceof Array
            ? `${day}, ${month} ${year}`
            : t(`date.${datePickerOption}`);
    };

    const dates = useMemo(
        () =>
            pickerDates.map((day) => {
                const text = dayText(day);
                return getDatePickerText(text);
            }),
        [new Date().getDay(), pickerDates]
    );

    const renderLoadingIndicator = () => (
        <ActivityIndicator
            holderStyles={{
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
            }}
            color={theme.color.textPrimary}
            size="large"
        />
    );

    const renderDropdownPicker = () => (
        <DropdownPicker
            options={dates}
            selectedItem={getDatePickerText(selectedDate.name)}
            onSelectionPress={onSelectionPress}
            additionalButtonStyles={additionalPickerStyles}
            additionalTextStyles={getFont(theme, 'tertiaryButton')}
            iconName="onButtonTertiary-calendar"
            iconSize={22}
        />
    );

    const renderPickerButton = () => (
        <PickerButton
            returnIndex
            options={dates}
            onValueChange={onSelectionPress}
            gradientColors={[
                'rgba(31, 31, 31, 0.1)',
                'rgba(31, 31, 31, 0.4)',
                'rgba(31, 31, 31, 0.8)',
                'rgba(31, 31, 31, 0.7)',
                'rgba(31, 31, 31, 0.4)',
                'rgba(31, 31, 31, 0.1)',
            ]}
            selectedValue={getDatePickerText(selectedDate.name)}
            title={getDatePickerText(selectedDate.name)}
            iconName="onButtonTertiary-calendar"
        />
    );

    if (isEmpty(data)) {
        return renderLoadingIndicator();
    }

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                {shouldShowTopNavbar ? renderDropdownPicker() : renderPickerButton()}
            </View>
            <EPGContainer
                selectedDate={selectedDate.date}
                viewConfig={config}
                data={data}
                isLoading={isLoading}
                isFetching={isFetching}
                onScroll={onScroll}
                scrollLeft={scrollLeft}
                onWebViewportScroll={onWebViewportScroll}
                scrollTop={scrollTop}
                landscapeTheme={getLandscapeStyles(theme, props, viewPortWidth)}
                shouldBeLocked={shouldBeLocked}
                lockedContentTitle={t('asset.blocked.title')}
                onPressCell={onCellPress}
                is12HourClock={is12HourClock}
                currentTimeLabelText={t('date.now')}
                loadingIndicator={renderLoadingIndicator}
            />
        </View>
    );
};

export default overridable(EpgScreen, 'EpgScreen');
