import React, { useLayoutEffect, useCallback, useMemo, useRef } from 'react';
import { FixedSizeList as List, areEqual } from 'react-window';
import { isEngineWeb, isFactorTv } from 'renative';
import { View, Image, Text } from '@24i/nxg-sdk-quarks';
import getStyles from './styles';
import { SidebarProps, SidebarRowProps } from './types';

const IS_STV = isEngineWeb && isFactorTv;

const Sidebar = (props: SidebarProps) => {
    const { scrollTop, height, channels, theme } = props;
    const styles = getStyles(theme);
    const listRef = useRef<List | null>();

    useLayoutEffect(() => listRef.current?.scrollTo(scrollTop), [scrollTop]);

    const Row = useCallback(
        (props: SidebarRowProps) => {
            const { index, style } = props;
            return useMemo(() => {
                const source =
                    typeof channels[index].channelLogo === 'string'
                        ? (channels[index].channelLogo as string)
                        : undefined;
                return (
                    <View style={{ ...style, ...styles.channelCellContainer }} pointerEvents="none">
                        <Image style={styles.logo} source={source} resizeMode="contain" />
                        <Text numberOfLines={1} style={styles.text}>
                            {channels[index].label}
                        </Text>
                    </View>
                );
            }, [areEqual]);
        },
        [channels.length]
    );

    return (
        <List
            className="list"
            width={theme.sidebar.container.width}
            height={height}
            itemCount={channels.length}
            itemData={channels}
            itemSize={theme.cell.container.height}
            style={styles.list}
            ref={listRef}
        >
            {Row}
        </List>
    );
};

export default Sidebar;
