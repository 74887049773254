import { getFont, getMargins } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon';
import { ExtendedViewStyle } from '@24i/nxg-sdk-gluons/src';
import { WebHeaderStyles } from './types';
import { Breakpoint } from '../../../../utils/styles/constants';

const WebHeader = (
    theme: Theme,
    hasMargin?: boolean,
    noVerticalMargin?: boolean
): WebHeaderStyles => {
    const mainContainer: ExtendedViewStyle = {
        flex: 1,
        flexDirection: 'column',
        margin: 'auto',
        paddingTop: 8,
        marginTop: 124,
        marginBottom: 54,
        paddingBottom: 8,
        height: '100%',
    };

    let containerFullWidth = {
        ...mainContainer,
        marginTop: 132,
    };

    let headerContainer: ExtendedViewStyle = {
        flexDirection: 'row',
        marginBottom: 54,
    };

    if (hasMargin) {
        containerFullWidth = {
            ...containerFullWidth,
            ...getMargins(),
        };
    } else {
        // Only header will have default margins
        headerContainer = {
            ...headerContainer,
            marginBottom: 0,
        };
    }

    if (noVerticalMargin) {
        headerContainer = {
            ...headerContainer,
            marginBottom: 0,
        };
        containerFullWidth = {
            ...containerFullWidth,
            marginTop: 0,
        };
    }

    return {
        container: {
            ...mainContainer,
            width: 576,
            [`@media (max-width: ${Breakpoint.SM}px)`]: {
                width: '100%',
                marginHorizontal: 0,
            },
        },
        titleContainer: {
            flexDirection: 'column',
            flex: 1,
            [`@media (max-width: ${Breakpoint.SM}px)`]: {
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'flex-start',
                alignItems: 'center',
            },
        },
        containerFullWidth: {
            ...containerFullWidth,
            [`@media (max-width: ${Breakpoint.SM}px)`]: {
                width: '100%',
            },
        },
        headerContainer,
        headerText: {
            marginTop: 0,
            fontWeight: 'bold',
            marginLeft: 5,
            color: theme.color.textPrimary,
            ...getFont(theme, 'h2'),
            [`@media (max-width: ${Breakpoint.SM}px)`]: {
                ...getFont(theme, 'h1'),
                alignSelf: 'center',
                marginTop: 0,
            },
        },
        headerTextLarge: {
            marginTop: 8,
            fontWeight: 'bold',
            color: theme.color.textPrimary,
            ...getFont(theme, 'h1'),
            [`@media (max-width: ${Breakpoint.SM}px)`]: {
                marginTop: 0,
                flex: 1,
                textAlign: 'center',
                paddingRight: 20,
            },
        },
        backButtonText: {
            color: theme.color.textPrimary,
            opacity: 0.6,
            marginLeft: 4,
            ...getFont(theme, 'h5'),
            [`@media (max-width: ${Breakpoint.SM}px)`]: {
                display: 'none',
            },
        },
        backButtonIconContainer: {
            flex: 1,
        },
        backButtonIcon: {
            color: theme.color.textPrimary,
            paddingBottom: 2,
            opacity: 0.6,
            fontSize: 20,
            marginTop: 3,
            [`@media (max-width: ${Breakpoint.SM}px)`]: {
                opacity: 1,
                paddingBottom: 0,
                marginRight: 64,
                fontSize: 24,
            },
        },
        subheaderText: {
            color: theme.color.textPrimary,
            marginTop: 15,
            marginLeft: 0,
            marginBottom: 8,
            ...getFont(theme, 'h4'),
        },

        actionButton: {
            marginLeft: 'auto',
        },
    };
};

export default WebHeader;
