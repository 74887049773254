import React, { useState, useEffect } from 'react';
import { Dimensions } from 'react-native';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { ThemeIconName } from '@24i/nxg-sdk-photon/src';
import { Interactable, View, ThemeIcon, Text } from '@24i/nxg-sdk-quarks';
import Modal from '@24i/nxg-sdk-gluons/src/components/inputs/ModalPicker/components/ContentModal';
import getPickerButtonStyles from '../styles';
import { PICKER_BUTTON_TEST_IDS } from '../types';

interface PickerButtonProps {
    title: string;
    iconName: ThemeIconName;
    selectedValue: string;
    options: string[];
    returnIndex: boolean;
    gradientColors: string[];
    onValueChange: (value) => void;
}

const PickerButton = ({
    title,
    iconName,
    selectedValue,
    options,
    returnIndex,
    gradientColors,
    onValueChange,
}: PickerButtonProps) => {
    const { theme } = useTheme();
    const styles = getPickerButtonStyles(theme);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deviceHeight, setDeviceHeigth] = useState(Dimensions.get('window').height);

    useEffect(() => {
        const getScreenDimensions = () => setDeviceHeigth(Dimensions.get('window').height);
        Dimensions.addEventListener('change', getScreenDimensions);
        return () => Dimensions.removeEventListener('change', getScreenDimensions);
    }, []);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleValueChange = (value) => {
        setIsModalOpen(false);
        onValueChange(value);
    };

    return (
        <View style={styles.container}>
            <Interactable
                onPress={openModal}
                style={styles.wrapper}
                testID={PICKER_BUTTON_TEST_IDS.EPG_DATE_PICKER}
            >
                <ThemeIcon iconName={iconName} size={20} iconStyles={styles.mainIcon} />
                <Text numberOfLines={1} style={styles.title}>
                    {title}
                </Text>
                <ThemeIcon
                    iconName="onButtonTertiary-dropdown"
                    size={20}
                    iconStyles={styles.dropdownIcon}
                />
            </Interactable>
            <Modal
                returnIndex={returnIndex}
                visible={isModalOpen}
                options={options}
                onCancel={closeModal}
                selectedValue={selectedValue}
                onValueChange={handleValueChange}
                deviceHeight={deviceHeight}
                gradientColors={gradientColors}
                noMargins
            />
        </View>
    );
};

export { getPickerButtonStyles };
export default PickerButton;
