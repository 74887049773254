import { DateTime } from 'luxon';

export const fiveMinutesInMilliseconds = 300000;
export const oneMinuteInMilliSeconds = 60000;
export const webPoleRemindersFrequency = 10000;

export const unixTimeNow = () => DateTime.now().toMillis();

export const getScheduledLocalNotificationDate = (startTime: number): Date =>
    new Date(startTime - fiveMinutesInMilliseconds);

export const brodacastStartsInFiveMinsOrLess = (startTime: number): boolean =>
    unixTimeNow() >= startTime - fiveMinutesInMilliseconds;

export const brodacastHasStarted = (startTime: number): boolean => unixTimeNow() >= startTime;

export const brodacastHasEnded = (endTime: number): boolean => unixTimeNow() >= endTime;

export const getRemainingMinutesForReminder = (startTime: number | undefined): number =>
    startTime ? Math.ceil((startTime - unixTimeNow()) / oneMinuteInMilliSeconds) : 0;
