import { PowerState, AsyncHookResult, LocationProviderInfo } from 'react-native-device-info';
import { DeviceInfoModule } from 'react-native-device-info/lib/typescript/internal/privateTypes';

const DeviceInfo: DeviceInfoModule = {
    getUniqueId: () => 'Web',
    getDeviceType: () => 'Web',
    isTablet: () => false,
    getManufacturer: () => Promise.resolve('Web'),
    getVersion: () => {
        const agent = window?.navigator?.userAgent;
        const start = agent.indexOf('OS ');
        let osVersion = 'unknown';
        if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
            osVersion = Number(agent.substr(start + 3, 3).replace('_', '.')).toString();
        } else if (agent.indexOf('Mac OS X') > -1) {
            osVersion = agent.split('Version/')[1]?.split(' ')[0];
        }
        return osVersion;
    },
    getApplicationName: (): string => {
        throw new Error('Function not implemented.');
    },
    getBrand: (): string => {
        throw new Error('Function not implemented.');
    },
    getBuildNumber: (): string => {
        throw new Error('Function not implemented.');
    },
    getBundleId: (): string => {
        throw new Error('Function not implemented.');
    },
    getDeviceId: (): string => {
        throw new Error('Function not implemented.');
    },
    getManufacturerSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getModel: (): string => {
        throw new Error('Function not implemented.');
    },
    getPowerState: (): Promise<object | PowerState> => {
        throw new Error('Function not implemented.');
    },
    getPowerStateSync: (): object | PowerState => {
        throw new Error('Function not implemented.');
    },
    getReadableVersion: (): string => {
        throw new Error('Function not implemented.');
    },
    getSystemName: (): string => {
        throw new Error('Function not implemented.');
    },
    getSystemVersion: (): string => {
        throw new Error('Function not implemented.');
    },
    hasNotch: (): boolean => {
        throw new Error('Function not implemented.');
    },
    hasSystemFeature: (_feature: string): Promise<boolean> => {
        throw new Error('Function not implemented.');
    },
    hasSystemFeatureSync: (_feature: string): boolean => {
        throw new Error('Function not implemented.');
    },
    isLandscape: (): Promise<boolean> => {
        throw new Error('Function not implemented.');
    },
    isLandscapeSync: (): boolean => {
        throw new Error('Function not implemented.');
    },
    supported32BitAbis: (): Promise<string[]> => {
        throw new Error('Function not implemented.');
    },
    supported32BitAbisSync: (): string[] => {
        throw new Error('Function not implemented.');
    },
    supported64BitAbis: (): Promise<string[]> => {
        throw new Error('Function not implemented.');
    },
    supported64BitAbisSync: (): string[] => {
        throw new Error('Function not implemented.');
    },
    supportedAbis: (): Promise<string[]> => {
        throw new Error('Function not implemented.');
    },
    supportedAbisSync: (): string[] => {
        throw new Error('Function not implemented.');
    },
    useBatteryLevel: (): number | null => {
        throw new Error('Function not implemented.');
    },
    useBatteryLevelIsLow: (): number | null => {
        throw new Error('Function not implemented.');
    },
    useDeviceName: (): AsyncHookResult<string> => {
        throw new Error('Function not implemented.');
    },
    useFirstInstallTime: (): AsyncHookResult<number> => {
        throw new Error('Function not implemented.');
    },
    useHasSystemFeature: (_feature: string): AsyncHookResult<boolean> => {
        throw new Error('Function not implemented.');
    },
    useIsEmulator: (): AsyncHookResult<boolean> => {
        throw new Error('Function not implemented.');
    },
    usePowerState: (): object | PowerState => {
        throw new Error('Function not implemented.');
    },
    useManufacturer: (): AsyncHookResult<string> => {
        throw new Error('Function not implemented.');
    },
    useIsHeadphonesConnected: (): AsyncHookResult<boolean> => {
        throw new Error('Function not implemented.');
    },
    getAndroidId: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getAndroidIdSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getApiLevel: (): Promise<number> => {
        throw new Error('Function not implemented.');
    },
    getApiLevelSync: (): number => {
        throw new Error('Function not implemented.');
    },
    getAvailableLocationProviders: (): Promise<LocationProviderInfo> => {
        throw new Error('Function not implemented.');
    },
    getAvailableLocationProvidersSync: (): LocationProviderInfo => {
        throw new Error('Function not implemented.');
    },
    getBaseOs: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getBaseOsSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getBatteryLevel: (): Promise<number> => {
        throw new Error('Function not implemented.');
    },
    getBatteryLevelSync: (): number => {
        throw new Error('Function not implemented.');
    },
    getBootloader: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getBootloaderSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getBuildId: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getBuildIdSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getCarrier: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getCarrierSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getCodename: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getCodenameSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getDevice: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getDeviceName: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getDeviceNameSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getDeviceSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getDeviceToken: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getDisplay: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getDisplaySync: (): string => {
        throw new Error('Function not implemented.');
    },
    getFingerprint: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getFingerprintSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getFirstInstallTime: (): Promise<number> => {
        throw new Error('Function not implemented.');
    },
    getFirstInstallTimeSync: (): number => {
        throw new Error('Function not implemented.');
    },
    getFontScale: (): Promise<number> => {
        throw new Error('Function not implemented.');
    },
    getFontScaleSync: (): number => {
        throw new Error('Function not implemented.');
    },
    getFreeDiskStorage: (): Promise<number> => {
        throw new Error('Function not implemented.');
    },
    getFreeDiskStorageOld: (): Promise<number> => {
        throw new Error('Function not implemented.');
    },
    getFreeDiskStorageSync: (): number => {
        throw new Error('Function not implemented.');
    },
    getFreeDiskStorageOldSync: (): number => {
        throw new Error('Function not implemented.');
    },
    getHardware: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getHardwareSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getHost: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getHostSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getIncremental: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getIncrementalSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getInstallerPackageName: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getInstallerPackageNameSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getInstallReferrer: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getInstallReferrerSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getInstanceId: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getInstanceIdSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getIpAddress: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getIpAddressSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getLastUpdateTime: (): Promise<number> => {
        throw new Error('Function not implemented.');
    },
    getLastUpdateTimeSync: (): number => {
        throw new Error('Function not implemented.');
    },
    getMacAddress: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getMacAddressSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getMaxMemory: (): Promise<number> => {
        throw new Error('Function not implemented.');
    },
    getMaxMemorySync: (): number => {
        throw new Error('Function not implemented.');
    },
    getPhoneNumber: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getPhoneNumberSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getPreviewSdkInt: (): Promise<number> => {
        throw new Error('Function not implemented.');
    },
    getPreviewSdkIntSync: (): number => {
        throw new Error('Function not implemented.');
    },
    getProduct: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getProductSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getSecurityPatch: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getSecurityPatchSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getSerialNumber: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getSerialNumberSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getSystemAvailableFeatures: (): Promise<string[]> => {
        throw new Error('Function not implemented.');
    },
    getSystemAvailableFeaturesSync: (): string[] => {
        throw new Error('Function not implemented.');
    },
    getTags: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getTagsSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getTotalDiskCapacity: (): Promise<number> => {
        throw new Error('Function not implemented.');
    },
    getTotalDiskCapacityOld: (): Promise<number> => {
        throw new Error('Function not implemented.');
    },
    getTotalDiskCapacitySync: (): number => {
        throw new Error('Function not implemented.');
    },
    getTotalDiskCapacityOldSync: (): number => {
        throw new Error('Function not implemented.');
    },
    getTotalMemory: (): Promise<number> => {
        throw new Error('Function not implemented.');
    },
    getTotalMemorySync: (): number => {
        throw new Error('Function not implemented.');
    },
    getType: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getTypeSync: (): string => {
        throw new Error('Function not implemented.');
    },
    getUsedMemory: (): Promise<number> => {
        throw new Error('Function not implemented.');
    },
    getUsedMemorySync: (): number => {
        throw new Error('Function not implemented.');
    },
    getUserAgent: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
    getUserAgentSync: (): string => {
        throw new Error('Function not implemented.');
    },
    hasGms: (): Promise<boolean> => {
        throw new Error('Function not implemented.');
    },
    hasGmsSync: (): boolean => {
        throw new Error('Function not implemented.');
    },
    hasHms: (): Promise<boolean> => {
        throw new Error('Function not implemented.');
    },
    hasHmsSync: (): boolean => {
        throw new Error('Function not implemented.');
    },
    isAirplaneMode: (): Promise<boolean> => {
        throw new Error('Function not implemented.');
    },
    isAirplaneModeSync: (): boolean => {
        throw new Error('Function not implemented.');
    },
    isBatteryCharging: (): Promise<boolean> => {
        throw new Error('Function not implemented.');
    },
    isBatteryChargingSync: (): boolean => {
        throw new Error('Function not implemented.');
    },
    isCameraPresent: (): Promise<boolean> => {
        throw new Error('Function not implemented.');
    },
    isCameraPresentSync: (): boolean => {
        throw new Error('Function not implemented.');
    },
    isEmulator: (): Promise<boolean> => {
        throw new Error('Function not implemented.');
    },
    isEmulatorSync: (): boolean => {
        throw new Error('Function not implemented.');
    },
    isHeadphonesConnected: (): Promise<boolean> => {
        throw new Error('Function not implemented.');
    },
    isHeadphonesConnectedSync: (): boolean => {
        throw new Error('Function not implemented.');
    },
    isLocationEnabled: (): Promise<boolean> => {
        throw new Error('Function not implemented.');
    },
    isLocationEnabledSync: (): boolean => {
        throw new Error('Function not implemented.');
    },
    isPinOrFingerprintSet: (): Promise<boolean> => {
        throw new Error('Function not implemented.');
    },
    isPinOrFingerprintSetSync: (): boolean => {
        throw new Error('Function not implemented.');
    },
    syncUniqueId: (): Promise<string> => {
        throw new Error('Function not implemented.');
    },
};

export default DeviceInfo;
