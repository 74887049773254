// TODO implement
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { MoreScreenAuthSectionProps, MoreScreenAuthSectionReturnType } from '../types';

const useViewModel = (props: MoreScreenAuthSectionProps): MoreScreenAuthSectionReturnType => {
    const signInEnabled = useFeature('signIn');

    return {
        isSignInEnabled: signInEnabled?.enabled || signInEnabled === undefined,
        ...props,
    };
};

export { useViewModel };
