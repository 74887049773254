import { useTranslation } from 'react-i18next';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View } from '@24i/nxg-sdk-quarks';
import React, { ReactElement, useState } from 'react';
import DropdownPicker from '@24i/nxg-sdk-gluons/src/components/inputs/DropdownPicker/';
import { useStore } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import getEpisodePickerStyles from '../styles';
import { EPISODE_PICKER_TEST_IDS, getEpisodePickerTestID } from '../test-utils';
import { DETAILS_SCREEN_TEST_IDS } from '../../../test-utils';
import { EpisodePickerProps, EpisodePickerStyles } from '../types';
import EpisodePickerItem from '../../EpisodePickerItem';
import { formatLocalizedSeasonName } from '../../utils';

const EpisodePicker = (props: EpisodePickerProps): ReactElement => {
    const {
        styles: getStyles = getEpisodePickerStyles,
        isNewsSeries,
        seasons,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        handleEpisodePress = () => {},
        hideTitle,
    } = props;

    const [selectedSeason, setSelectedSeason] = useState(0);
    const season = seasons[selectedSeason];
    const { theme } = useTheme();
    const { t } = useTranslation();
    const styles = getStyles(theme) as EpisodePickerStyles;
    const { userData } = useStore();
    const formatSeasonName = formatLocalizedSeasonName(t);

    const getSeasonTestId = (index) =>
        getEpisodePickerTestID(EPISODE_PICKER_TEST_IDS.SEASONS_DROPDOWN_ITEM_BUTTON, {
            index,
        });

    if (!season) return <></>;

    return (
        <View style={styles.episodePickerContainer}>
            <View style={styles.seasonPickerContainer}>
                {!isNewsSeries && (
                    <DropdownPicker
                        selectedItem={formatSeasonName(season)}
                        options={seasons.map(formatSeasonName)}
                        onSelectionPress={setSelectedSeason}
                        buttonTestID={DETAILS_SCREEN_TEST_IDS.SEASON_PICKER}
                        buttonKey={season.name}
                        getItemsTestID={getSeasonTestId}
                        itemsPrefixKey="seasonPickerDropdown-"
                    />
                )}
            </View>
            <View>
                {season.episodes.map((item, index) => (
                    <EpisodePickerItem
                        styles={styles}
                        key={`${item?.id}-${item?.type}-${item?.title}`}
                        handleEpisodePress={handleEpisodePress}
                        index={index}
                        item={item}
                        hideTitle={hideTitle}
                        isSignedIn={!!userData}
                    />
                ))}
            </View>
        </View>
    );
};

export { getEpisodePickerStyles };
export default overridable(EpisodePicker, 'EpisodePicker');
