import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { ScreenProps } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { log } from '@24i/nxg-core-utils/src/logger';
import { Profile } from '@24i/nxg-sdk-photon/src';
import { useSwitchProfile } from '@24i/nxg-sdk-smartott/src/hooks/useSwitchProfile';
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';

import { useStore } from '../../../context/ApplicationStore';
import { SharedViewModelReturnType } from '../types';

const useShared = (props: ScreenProps): SharedViewModelReturnType => {
    const [profiles, setProfiles] = useState<Profile[]>([]);
    const [isProfileLoading, setIsProfileLoading] = useState(false);
    const isSelectProfilesEnabled = useFeature('accounts')?.select_profiles;
    const userDataClient = useUserData();

    const { userData } = useStore();
    const { switchProfile } = useSwitchProfile();

    const adobePrimetimeEnabled = useFeature('adobePrimetime')?.enabled;
    const signInEnabled = useFeature('signIn');

    // Get user's profiles and their data
    // Condition - a user must be logged in to do so
    const getUserProfiles = () => {
        setIsProfileLoading(true);
        if (userData)
            userDataClient
                .fetchUserProfiles()
                .then((usersProfiles) => {
                    setProfiles(usersProfiles);
                    setIsProfileLoading(false);
                })
                .catch((err) => {
                    log(err);
                    setIsProfileLoading(false);
                });
        else {
            setProfiles([]);
            setIsProfileLoading(false);
        }
    };

    // Send the information about new selected profile to the backend
    const selectProfile = (newSelectedProfile: Profile) => {
        switchProfile(newSelectedProfile);
    };

    const showProfilePicker =
        isSelectProfilesEnabled &&
        !isEmpty(userData) &&
        props?.route?.params?.showProfilePicker !== false;

    const isSignInEnabled = signInEnabled?.enabled || signInEnabled === undefined;

    const showBottomAuthSection = adobePrimetimeEnabled || (isSignInEnabled && !userData);

    return {
        // return from model
        profiles,
        selectProfile,
        isLoading: isProfileLoading,
        showBottomAuthSection,
        showProfilePicker: !!showProfilePicker,
        // just use in the model (do no return)
        getUserProfiles,
        isProfileLoading,
    };
};

export default useShared;
