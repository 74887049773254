import React, { useState, FunctionComponent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import PackshotGrid, { GridItem } from '@24i/nxg-sdk-gluons/src/components/containers/PackshotGrid';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { Interactable, Text, View, ScrollView } from '@24i/nxg-sdk-quarks';
import { ASSET_TYPE, getIsNewsSeries } from '@24i/nxg-sdk-photon';
import {
    mapAssetToGridItem,
    findAssetByGridItem,
} from '@24i/nxg-sdk-gluons/src/components/containers/PackshotGrid/View/utils';

import { SOTT_DEFAULT_WEB_SCREENS } from '../../../../../navigation/constants';
import { DETAILS_SCREEN_TEST_IDS } from '../../../test-utils';
import { BottomPartProps } from '../types';
import { getBottomPartStyles } from '../styles';
import EpisodePicker from '../../EpisodePicker';
import DetailsTab from '../../DetailsTab';

export const defaultRenderEpisodePicker = (props) => <EpisodePicker {...props} />;
export const defaultRenderPackshotGrid = (props) => <PackshotGrid {...props} />;
export const defaultRenderDetailsTab = (props) => <DetailsTab {...props} />;

const BottomPart: FunctionComponent<BottomPartProps> = (props) => {
    const {
        asset,
        seasons = [],
        related,
        renderEpisodePicker = defaultRenderEpisodePicker,
        renderPackshotGrid = defaultRenderPackshotGrid,
        renderDetailsTab = defaultRenderDetailsTab,
        renderTabItems = (defaultTabItems) => defaultTabItems,
        onPlay,
        onRelatedPress,
        extraItems,
        styles: getStyles = getBottomPartStyles,
    } = props;

    const mightHaveSeasons = ASSET_TYPE.SERIES || asset?.type === ASSET_TYPE.PODCAST_SERIES;
    const [pickerIndex, setPickerIndex] = useState<number>(0);
    const [tabPressed, setTabPressed] = useState<null | number>(null);
    const router = useRouter();
    const { t } = useTranslation(['sott']);
    const { theme } = useTheme();
    const styles = getStyles(theme);
    const itemsRef = useRef<HTMLDivElement[]>([]);
    const isNewsSeries = getIsNewsSeries(asset);

    const handleRelatedPress = (item) => {
        const callback = () => {
            const detailsLink = SOTT_DEFAULT_WEB_SCREENS.Details.getLink({
                type: item.type,
                id: item.id,
            });
            router.push(detailsLink.href, detailsLink.as);
        };

        if (onRelatedPress) {
            return onRelatedPress(item, callback);
        }
        return callback();
    };

    const handleOnPlayExtraItemPress = (item: GridItem, isStartOver = false) => {
        const selectedAsset = extraItems && findAssetByGridItem(item, extraItems);

        if (selectedAsset) onPlay(selectedAsset, isStartOver);
    };

    const renderRelated = () => (
        <View style={styles.bottomPart} testID={DETAILS_SCREEN_TEST_IDS.RELATED_CONTAINER}>
            {renderPackshotGrid({
                items: related?.map((item) => mapAssetToGridItem(item)),
                onItemPress: ({ asset: item }) => handleRelatedPress(item),
            })}
        </View>
    );

    const renderExtras = () => (
        <View style={styles.bottomPart} testID={DETAILS_SCREEN_TEST_IDS.EXTRAS_CONTAINER}>
            {renderPackshotGrid({
                actionIcon: 'play',
                items: extraItems?.map((item) => mapAssetToGridItem(item)),
                onItemPress: ({ asset: item }) => handleOnPlayExtraItemPress(item),
            })}
        </View>
    );

    const defaultTabItems = [
        {
            label: isNewsSeries ? t('asset.tab.latest') : t('asset.tab.episodes'),
            content:
                mightHaveSeasons && seasons && seasons.length > 0
                    ? renderEpisodePicker({
                          isNewsSeries,
                          seasons,
                          handleEpisodePress: (episode, _e, isStartOver = false) => {
                              onPlay(episode, isStartOver);
                          },
                          hideTitle: true,
                      })
                    : null,
            testID: DETAILS_SCREEN_TEST_IDS.EPISODES_TAB,
        },
        {
            label: t('asset.tab.trailers'),
            content: extraItems && extraItems?.length > 0 ? renderExtras() : null,
            testID: DETAILS_SCREEN_TEST_IDS.TRAILERS_EXTRAS_TAB,
        },
        {
            label: t('asset.tab.related'),
            content: related && related.length > 0 ? renderRelated() : null,
            testID: DETAILS_SCREEN_TEST_IDS.MORE_LIKE_THIS_TAB,
        },
        {
            label: t('asset.tab.details'),
            content:
                (asset.type === ASSET_TYPE.MOVIE ||
                    asset.type === ASSET_TYPE.SERIES ||
                    asset.type === ASSET_TYPE.PODCAST_SERIES ||
                    asset.type === ASSET_TYPE.LIVE_EVENT) &&
                ((asset.genres && asset.genres.length > 0) || (asset.crew && asset.crew.length > 0))
                    ? renderDetailsTab({ asset })
                    : null,
            testID: DETAILS_SCREEN_TEST_IDS.DETAILS_TAB,
        },
    ];

    const filteredOptions =
        (renderTabItems &&
            renderTabItems(defaultTabItems).filter((item) => item?.content !== null)) ||
        [];

    if (!filteredOptions.length) return <></>;

    return (
        <View
            style={[
                styles.bottomPartContainer,
                {
                    backgroundImage: `linear-gradient(${theme.color.darker3}, ${theme.color.background1})`,
                },
            ]}
        >
            <ScrollView
                showsHorizontalScrollIndicator={false}
                horizontal
                style={styles.contentPickerContainer}
            >
                {filteredOptions.map((item, index) => {
                    const isTabActive = index === pickerIndex;
                    const isTabPressed = index === tabPressed;
                    return (
                        <div
                            key={item.label}
                            ref={(el) => {
                                if (el) {
                                    itemsRef.current[index] = el;
                                }
                            }}
                        >
                            <Interactable
                                key={item.label}
                                onPress={() => setPickerIndex(index)}
                                onPressIn={() => setTabPressed(index)}
                                onPressOut={() => setTabPressed(null)}
                                style={[
                                    {
                                        borderBottomColor: isTabActive
                                            ? theme.color.buttonPrimary
                                            : 'transparent',
                                    },
                                    styles.contentPickerItem,
                                ]}
                                testID={item.testID}
                            >
                                <Text
                                    style={[
                                        !isTabActive && !isTabPressed && styles.inactiveTabText,
                                        isTabPressed && styles.pressedTabText,
                                        styles.contentPickerItemText,
                                    ]}
                                >
                                    {item.label}
                                </Text>
                            </Interactable>
                        </div>
                    );
                })}
            </ScrollView>
            <View style={styles.contentPickerHorizontalLine} />
            {filteredOptions[pickerIndex]?.content}
        </View>
    );
};

export { getBottomPartStyles };
export default overridable(BottomPart, 'BottomPart');
