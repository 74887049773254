import { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
    Asset,
    isBroadcast,
    isEpisode,
    isMovie,
    isSeries,
    isChannel,
    isLiveEvent,
    getIsPodcastSeries,
    getIsNewsSeries,
} from '@24i/nxg-sdk-photon';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';
import useContinueWatchingQuery from '../query/continueWatching/useContinueWatchingQuery';

const filterBySerie = (cwEpi: Asset, seriesId: string) => cwEpi.series === seriesId;

export const useAssetHasProgress = (asset: Asset | undefined) => {
    const { data: continueWatchingPlaylist } = useContinueWatchingQuery();
    const contentDataClient = useContentData();

    const [hasProgress, setHasProgress] = useState<boolean | null>(null);

    const checkProgress = useCallback(async () => {
        if (!asset) {
            return setHasProgress(false);
        }

        const isNewsSeries = getIsNewsSeries(asset);

        if (isSeries(asset) || getIsPodcastSeries(asset) || isNewsSeries) {
            // Check if there are episodes in progress
            const episodesInProgress = continueWatchingPlaylist?.filter((cwAsset) =>
                filterBySerie(cwAsset, asset.id)
            );

            if (isNewsSeries) {
                // In the case of news series, I need to check if the latest episode exclusively is in progress
                const latestNewsEpisode = await contentDataClient.fetchLatestNewsEpisode(asset.id);

                return setHasProgress(
                    Boolean(
                        episodesInProgress?.some((episode) => episode.id === latestNewsEpisode?.id)
                    )
                );
            }

            return setHasProgress(!isEmpty(episodesInProgress));
        }
        if (
            (isMovie(asset) || isEpisode(asset)) &&
            asset?.continueWatchingOffset &&
            asset?.duration
        ) {
            return setHasProgress(asset.continueWatchingOffset > 0 && asset.duration > 0);
        }

        if (isChannel(asset) || isLiveEvent(asset)) {
            return setHasProgress(false);
        }

        if (isBroadcast(asset)) {
            return setHasProgress(!(asset.progress == null) && asset.progress >= 0);
        }

        return setHasProgress(false);
    }, [asset, continueWatchingPlaylist, contentDataClient]);

    useEffect(() => {
        checkProgress();
    }, [checkProgress]);

    return { assetHasProgress: Boolean(hasProgress) };
};
