import React, { memo } from 'react';
import { BannerCarouselItemTypes } from '../../types';
import BannerCarouselItemView from './View';
import { useViewModel } from './viewModel';

const BannerCarousel = memo((props: Omit<BannerCarouselItemTypes, 'isObscured'>) => {
    const viewProps = useViewModel(props);
    return <BannerCarouselItemView {...viewProps} />;
});

BannerCarousel.displayName = 'BannerCarousel';

export default BannerCarousel;
