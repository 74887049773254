import { Storage } from '@24i/nxg-sdk-quantum';
import { isFactorTv, isPlatformWeb } from 'renative';
import { getRuntimeConfig } from '@24i/nxg-sdk-smartott/src/Application/initApp';
import deepmerge from 'deepmerge';
import * as i18next from 'i18next';
import {
    AppSettings,
    AppSettingsDataClient,
    AppStructureDataClient,
    DevicesManagementClient,
    MenuItem,
    Profile,
    QUERY_KEYS,
    Theme,
    ThemeDataClient,
    User,
    UserDataClient,
    RemindersClient,
    PRODUCT_SCREENS,
    ACCESS_MODE,
    PurchaseDataClient,
} from '@24i/nxg-sdk-photon';
import { preloadImage } from '@24i/nxg-sdk-quarks/src/components/Image';
import { Source } from '@24i/nxg-sdk-quarks/src/components/Image/types';
import { QueryClient } from 'react-query';
import {
    SOTT_DEFAULT_ROOT_SCREENS,
    SOTT_DEFAULT_SCREENS,
    SOTT_DEFAULT_WEB_SCREENS,
} from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { MANAGE_DEVICES_MODES } from '@24i/nxg-sdk-smartott/src/screens/ManageDevicesScreen/constants';
import {
    AppStartActions,
    AppStartActionSequence,
    AppStartClient,
    AppStartDecisionFunction,
    CustomActions,
} from '@24i/nxg-sdk-smartott/src/context/AppStartContext/types';
import { ApplicationStates } from '@24i/nxg-sdk-smartott/src/context/AppStartContext/constants';
import { UPSERT_PROFILE_MODES } from '@24i/nxg-sdk-smartott/src/screens/UpsertProfileScreen/constants';
import {
    AppNavigationClient,
    NavConfig,
    NavScreenConfig,
} from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { ContextType } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import {
    brodacastHasStarted,
    brodacastStartsInFiveMinsOrLess,
} from '@24i/nxg-sdk-smartott/src/utils/reminderUtils';
import { loadAnalytics } from '@24i/nxg-sdk-smartott-shared/src/analytics';
import { isLocalConfig } from '@24i/adobe-primetime/src/adobePrimetimeLocalConfig';
import { adobePrimetimeJSDevDebugLogger } from '@24i/adobe-primetime/src/shared/utils';
import {
    fetch,
    getUrl,
    goTo,
    isAllowedByScreenMode,
    isRedirectFromOnboardingPaymentGate,
    isRedirectFromOnboardingIntro,
    parseUrl,
    getParams,
} from './utils';

type RequiredClients = {
    purchaseDataClient: PurchaseDataClient;
    themeDataClient: ThemeDataClient;
    appSettingsDataClient: AppSettingsDataClient;
    userDataClient: UserDataClient;
    devicesManagementClient: DevicesManagementClient;
    appStructureDataClient: AppStructureDataClient;
    appNavigationClient: AppNavigationClient<unknown>;
    remindersClient: RemindersClient;
};

const checkConditions = async (
    requiredConditions: AppStartDecisionFunction<unknown>[],
    queryClient,
    store: ContextType,
    appState: ApplicationStates,
    currentPageConfig: NavScreenConfig | null
) => {
    const results = await Promise.all(
        requiredConditions.map((fetchResult) =>
            fetchResult(queryClient, store, appState, currentPageConfig)
        )
    );

    return results.some(Boolean);
};

export class DefaultAppStartClient implements AppStartClient {
    clients: RequiredClients;

    welcomeScreenSeen = false;

    onBoardingIntroScreenSeen = false;

    onAdditionalInfoScreenSeen = false;

    constructor(clients: RequiredClients) {
        this.clients = clients;
    }

    onLogout = async () => {
        this.onBoardingIntroScreenSeen = false;
        this.onAdditionalInfoScreenSeen = false;
    };

    fetchUser = async (queryClient: QueryClient): Promise<User | undefined | null> =>
        fetch(QUERY_KEYS.user, this.clients.userDataClient.fetchUser, queryClient);

    fetchTheme = async (queryClient: QueryClient): Promise<Theme | undefined> =>
        fetch(QUERY_KEYS.appTheme, this.clients.themeDataClient.fetchTheme, queryClient);

    fetchProfiles = async (queryClient: QueryClient): Promise<Profile[] | undefined> =>
        fetch(QUERY_KEYS.profiles, this.clients.userDataClient.fetchUserProfiles, queryClient);

    fetchConfig = async (queryClient: QueryClient): Promise<AppSettings | undefined> => {
        const fetchAndMergeConfig = async () => {
            const config = await this.clients.appSettingsDataClient.fetchServiceConfig();
            const runtimeFeatures = getRuntimeConfig('features');

            return deepmerge<AppSettings>({ features: runtimeFeatures }, config, {
                arrayMerge: (d, s) => s,
            });
        };

        return fetch(QUERY_KEYS.config, fetchAndMergeConfig, queryClient);
    };

    fetchMenu = async (queryClient: QueryClient): Promise<MenuItem[] | undefined> => {
        const config = await this.fetchConfig(queryClient);

        const fetchMenu = async () =>
            this.clients.appStructureDataClient.fetchMenu(
                config?.features?.menus?.main || undefined,
                config
            );

        return fetch(QUERY_KEYS.menu, fetchMenu, queryClient);
    };

    fetchSplashImages = async (theme: Theme | undefined): Promise<void> => {
        const sources: Source[] = [theme?.logo, theme?.backgroundImage]
            .filter((uri) => typeof uri === 'string')
            .map((uri) => ({ uri: uri as string }));
        if (sources.length) preloadImage(sources);
    };

    fetchNavigationConfig = async (
        queryClient: QueryClient,
        t: i18next.TFunction,
        params: Record<string, unknown>
    ): Promise<NavConfig | undefined> => {
        const [config, user, profiles, menuConfig] = await Promise.all([
            this.fetchConfig(queryClient),
            this.fetchUser(queryClient),
            this.fetchProfiles(queryClient),
            this.fetchMenu(queryClient),
        ]);
        await loadAnalytics(user?.id as string);

        const runtimeMenu = getRuntimeConfig('menu');

        return fetch(
            QUERY_KEYS.navigationConfig,
            () => {
                if (!config || !menuConfig) {
                    return undefined;
                }

                return this.clients.appNavigationClient.getNavigationConfig(
                    menuConfig,
                    runtimeMenu,
                    {
                        t,
                        user,
                        profiles,
                        selectedProfile: undefined,
                        features: config?.features,
                        params,
                    }
                );
            },
            queryClient
        );
    };

    fetchWelcomeScreens = async (queryClient: QueryClient, id: string) =>
        fetch(
            QUERY_KEYS.welcomeScreens,
            () => this.clients.appSettingsDataClient.fetchWelcomeScreen(id),
            queryClient
        );

    fetchTermsAndConditions = async (queryClient: QueryClient) =>
        fetch(
            QUERY_KEYS.termsAndConditions,
            () => this.clients.appSettingsDataClient.fetchTermsAndConditions(),
            queryClient
        );

    initApplication = async (
        queryClient: QueryClient,
        t: i18next.TFunction,
        params: Record<string, unknown>
    ) => {
        const [_config, fetchedTheme, _user, _profiles] = await Promise.all([
            this.fetchConfig(queryClient),
            this.fetchTheme(queryClient),
            this.fetchUser(queryClient),
            this.fetchProfiles(queryClient),
        ]);

        await this.fetchSplashImages(fetchedTheme);
        await this.fetchMenu(queryClient);
        await this.fetchNavigationConfig(queryClient, t, params);
    };

    goToWelcomePage = async (queryClient: QueryClient) => {
        if (isPlatformWeb) return false;
        const config = await this.fetchConfig(queryClient);
        const user = await this.fetchUser(queryClient);
        const welcomeScreenEnabled = config?.features?.welcomeScreens?.enabled;
        if (!welcomeScreenEnabled || user) return false;
        const screens = config?.features?.welcomeScreens?.screens;
        const [first] = screens || [];
        const setOfWelcomeScreens = first.welcomeScreenId;
        const welcomeScreensAssets = await this.fetchWelcomeScreens(
            queryClient,
            setOfWelcomeScreens || ''
        );

        // if there is a setting to show welcome only once then take result from storage which
        // lasts for the whole time the app is installed.
        // Otherwise take result from the class variable which lasts only for one app session
        const getWelcomeScreenResult = async () => {
            const welcomeScreenSeenInThePast = await Storage.getItem('welcomeSeen');
            const welcomeScreenSeenInThisSession = this.welcomeScreenSeen;
            const showOnce = welcomeScreensAssets?.show === 'once';
            if (showOnce) return !welcomeScreenSeenInThePast;
            return !welcomeScreenSeenInThisSession;
        };

        const result = await getWelcomeScreenResult();
        if (result) {
            this.welcomeScreenSeen = true;
            await Storage.setItem('welcomeSeen', Date.now().toString());
        }
        return result;
    };

    goToLandingPage = async (
        queryClient: QueryClient,
        _store,
        _appState,
        currentPageConfig: NavScreenConfig | null
    ) => {
        const user = await this.fetchUser(queryClient);
        const config = await this.fetchConfig(queryClient);
        const landingScreenEnabled = config?.features?.landingScreen?.enabled;
        const externalLandingScreenUrl = config?.features?.landingScreen?.externalUrl;
        const url = await getUrl();

        if (
            isAllowedByScreenMode(config, currentPageConfig?.accessMode) &&
            currentPageConfig?.name !== SOTT_DEFAULT_SCREENS.DELETE_ACCOUNT_SUCCESS
        ) {
            return false;
        }

        if (isPlatformWeb && !user && landingScreenEnabled) {
            if (externalLandingScreenUrl != null) return { externalLandingScreenUrl };
            return true;
        }

        return Boolean(!user && landingScreenEnabled && !url);
    };

    goToSignInPage = async (
        queryClient: QueryClient,
        _store,
        _appState,
        currentPageConfig: NavScreenConfig | null
    ) => {
        const user = await this.fetchUser(queryClient);
        const config = await this.fetchConfig(queryClient);
        // If guest mode is on we don't ever want to show login unless the access mode is strictly sign in
        // or it is a root page
        if (
            config?.features?.guestMode?.enabled &&
            (currentPageConfig?.accessMode !== ACCESS_MODE.USER ||
                currentPageConfig.name === SOTT_DEFAULT_SCREENS.INDEX)
        ) {
            return false;
        }
        if (isAllowedByScreenMode(config, currentPageConfig?.accessMode)) {
            return false;
        }
        if (!config?.features?.signIn?.enabled && !config?.features?.guestMode?.enabled) {
            return false;
        }

        return !user;
    };

    goToOnboardingIntroPage = async (
        queryClient: QueryClient,
        store: ContextType,
        appState: ApplicationStates,
        currentPageConfig: NavScreenConfig | null
    ) => {
        if (this.onBoardingIntroScreenSeen) return false;
        const requiredConditions = [this.goToCreateProfile, this.goToEditProfile];
        const config = await this.fetchConfig(queryClient);
        const onboardingIntroEnabled = config?.features.onboardingIntro?.enabled;
        const user = await this.fetchUser(queryClient);
        if (!user || !onboardingIntroEnabled) return false;

        this.onBoardingIntroScreenSeen = true;

        const result = await checkConditions(
            requiredConditions,
            queryClient,
            store,
            appState,
            currentPageConfig
        );
        if (result) {
            this.onAdditionalInfoScreenSeen = true;
        }
        return result;
    };

    goToAdditionalInfoPage = async (
        queryClient: QueryClient,
        store: ContextType,
        appState: ApplicationStates,
        currentPageConfig: NavScreenConfig | null
    ) => {
        const requiredConditions = [
            this.goToTermsAndConditionsPage,
            this.goToEnterPinPage,
            this.goToCreatePinPage,
            this.goToRemoveDevicePage,
        ];
        if (this.onAdditionalInfoScreenSeen) return false;

        const config = await this.fetchConfig(queryClient);
        const additionalInfoEnabled = config?.features.additionalInfo?.enabled;

        const user = await this.fetchUser(queryClient);

        if (!user || !additionalInfoEnabled) return false;
        this.onAdditionalInfoScreenSeen = true;
        return checkConditions(requiredConditions, queryClient, store, appState, currentPageConfig);
    };

    goToTermsAndConditionsPage = async (queryClient: QueryClient) => {
        const user = await this.fetchUser(queryClient);
        const config = await this.fetchConfig(queryClient);
        const termAndConditionsEnabled = config?.features?.onboardingTermsAndConditions?.enabled;
        if (!user || !termAndConditionsEnabled) return false;
        if (!user) return false;
        const termsAndConditions = await this.fetchTermsAndConditions(queryClient);
        const termsAndConditionsAccepted =
            termsAndConditions &&
            (await this.clients.appSettingsDataClient.isTermsAndConditionsAccepted(
                user.id,
                termsAndConditions
            ));
        return !termsAndConditionsAccepted;
    };

    goToCreateProfile = async (queryClient: QueryClient) => {
        const user = await this.fetchUser(queryClient);
        const config = await this.fetchConfig(queryClient);
        const profileSelectionEnabled = config?.features.accounts?.select_profiles;

        if (!user || !profileSelectionEnabled) {
            return false;
        }

        const profiles = await this.fetchProfiles(queryClient);

        return (profiles || []).length === 0;
    };

    goToEditProfile = async (queryClient: QueryClient) => {
        const user = await this.fetchUser(queryClient);
        const config = await this.fetchConfig(queryClient);
        const profileSelectionEnabled = config?.features.accounts?.select_profiles;

        if (!user || !user.id || !profileSelectionEnabled) {
            return false;
        }

        const shouldShowProfileOnboarding =
            await this.clients.appSettingsDataClient.shouldShowProfileOnboarding(user.id);
        const profiles = await this.fetchProfiles(queryClient);

        if (shouldShowProfileOnboarding && profiles?.length === 1) {
            return profiles[0];
        }

        return false;
    };

    goToRemoveDevicePage = async (queryClient: QueryClient) => {
        const user = await this.fetchUser(queryClient);
        const config = await this.fetchConfig(queryClient);
        const enabled = config?.features?.deviceManagement?.enabled;
        if (!user || !enabled) return false;
        const limitReached = await this.clients.devicesManagementClient.checkDeviceLimit();
        return !limitReached;
    };

    goToEnterPinPage = async (queryClient: QueryClient, store: ContextType) => {
        const user = await this.fetchUser(queryClient);
        const config = await this.fetchConfig(queryClient);
        const pinProtectionEnabled = config?.features?.pinProtection;
        const profileSelectionEnabled = config?.features.accounts?.select_profiles;
        if (!user || !profileSelectionEnabled || !pinProtectionEnabled) return false;
        const profiles = await this.fetchProfiles(queryClient);
        if (!profiles) return false;
        queryClient.setQueryData(QUERY_KEYS.profiles, profiles);
        return profiles.length === 1 && profiles[0]?.isPinProtected && !store.selectedProfile;
    };

    goToWhoIsWatchingPage = async (queryClient: QueryClient, store: ContextType) => {
        const user = await this.fetchUser(queryClient);
        const config = await this.fetchConfig(queryClient);
        const profileSelectionEnabled = config?.features.accounts?.select_profiles;
        if (!user || !profileSelectionEnabled) return false;
        const profiles = await this.fetchProfiles(queryClient);
        if (!profiles) return false;
        queryClient.setQueryData(QUERY_KEYS.profiles, profiles);
        return profiles.length > 1 && !store.selectedProfile;
    };

    goToHomePage = async (
        queryClient: QueryClient,
        _store,
        _appState,
        currentPageConfig: NavScreenConfig | null
    ) => {
        // Handling situation where user is logged in but we force navigation to a page that shouldn't display to a logged user
        const user = await this.fetchUser(queryClient);
        if (currentPageConfig?.accessMode === ACCESS_MODE.ANONYMOUS_ONLY && user) {
            return {
                expectedLocation: currentPageConfig?.correspondingProductScreen,
                shouldSkip: true,
            };
        }
        return true;
    };

    goToCreatePinPage = async (queryClient: QueryClient) => {
        const user = await this.fetchUser(queryClient);
        const config = await this.fetchConfig(queryClient);
        const pinProtectionEnabled = config?.features?.pinProtection?.enabled;
        if (!user || !pinProtectionEnabled) return false;
        return !!user && !user.isPinSet;
    };

    goToAddSubscriptionPage = async (queryClient: QueryClient) => {
        const user = await this.fetchUser(queryClient);
        const config = await this.fetchConfig(queryClient);
        const subscriptionsEnabled = config?.features?.onboardingSubscriptions?.enabled;
        if (!user || !subscriptionsEnabled) return false;

        const userProducts = await this.clients.purchaseDataClient?.fetchUserProducts();
        const hasUserSubscription = userProducts ? userProducts.length > 0 : false;
        return (
            !hasUserSubscription &&
            (await this.clients.appSettingsDataClient.shouldShowAddSubscription(user.id))
        );
    };

    goToBroadcastFromReminder = async (queryClient: QueryClient, store: ContextType) => {
        if (isPlatformWeb) return false;

        const config = await this.fetchConfig(queryClient);
        const remindersEnabled = config?.features?.reminders?.enabled;
        if (!remindersEnabled) return false;

        // check for active reminder - do not use asset passed to onNotification when app is killed.
        // onNotification action should only be used when the appState is background or forground
        // since we do not run the start up sequence in those states
        const reminders = await this.clients.remindersClient.getReminders();

        if (!reminders) {
            return false;
        }

        const { id } = store.selectedProfile || {};

        if (!id) {
            return false;
        }

        const activeReminder = reminders.find((reminder) => {
            const programStartsInFiveMinutes =
                brodacastStartsInFiveMinsOrLess(reminder.start) &&
                !brodacastHasStarted(reminder.start);
            return reminder.profileId === id && programStartsInFiveMinutes;
        });

        if (!activeReminder) {
            return false;
        }

        // delete the reminder so the user isn't navigated to the broadcast
        // on opening the app again
        await this.clients.remindersClient.deleteReminderForBroadcast(activeReminder?.assetId, id);

        return activeReminder;
    };

    goToDetailsPage = async () => {
        if (isPlatformWeb) return false;
        const url = await getUrl();
        if (url && url.includes('details/')) {
            return true;
        }
        return false;
    };

    goToPlayerPage = async () => {
        if (isPlatformWeb) return false;
        const url = await getUrl();
        if (url && url.includes('player/')) {
            return true;
        }
        return false;
    };

    goToMvpdSignIn = async (queryClient: QueryClient) => {
        const config = await this.fetchConfig(queryClient);
        const adobePrimetimeEnabled = config?.features?.adobePrimetime?.enabled;
        const landingScreenEnabled = config?.features?.landingScreen?.enabled;
        const guestModeEnabled = config?.features?.guestMode?.enabled;

        if (isPlatformWeb && !landingScreenEnabled) return false;
        if (adobePrimetimeEnabled) {
            if (
                isLocalConfig &&
                (landingScreenEnabled || (!landingScreenEnabled && !guestModeEnabled))
            )
                return true;
            if (__DEV__) adobePrimetimeJSDevDebugLogger(0);
            return false;
        }
        return false;
    };
}

export const defaultActions: AppStartActions = {
    goToWelcomePage: (navigation, router) => {
        goTo(
            navigation,
            router,
            SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN,
            SOTT_DEFAULT_SCREENS.WELCOME
        );
    },
    goToLandingPage: (navigation, router, _initialUrl, payload) => {
        /*
        For apps using external landing pages (e.g. BHD), I need to allow to go from the external landing page to the product's auth pages.
        Instead of altering and further complicating the original sequence, the redirection to signin and signup pages is handled here.
        The actual external redirection takes place in the screen itself instead of here to make things smoother (show loader instead of app layout)
        */
        if (typeof payload?.externalLandingScreenUrl === 'string' && router && isPlatformWeb) {
            if (router.route === '/signin') {
                goTo(
                    navigation,
                    router,
                    SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN,
                    SOTT_DEFAULT_SCREENS.SIGN_IN,
                    SOTT_DEFAULT_WEB_SCREENS.SignIn.getLink()
                );
                return;
            }

            if (router.route === '/register') {
                goTo(
                    navigation,
                    router,
                    SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN,
                    SOTT_DEFAULT_SCREENS.SIGN_UP,
                    SOTT_DEFAULT_WEB_SCREENS.SignUp.getLink()
                );
                return;
            }
        }
        goTo(
            navigation,
            router,
            SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN,
            SOTT_DEFAULT_SCREENS.LANDING,
            SOTT_DEFAULT_WEB_SCREENS.Landing.getLink()
        );
    },
    goToSignInPage: (navigation, router) => {
        goTo(
            navigation,
            router,
            SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN,
            SOTT_DEFAULT_SCREENS.SIGN_IN,
            SOTT_DEFAULT_WEB_SCREENS.SignIn.getLink()
        );
    },
    goToCreateProfile: (navigation, router, _initialUrl) => {
        let queryParams: {
            onboarding: boolean;
            mode?: UPSERT_PROFILE_MODES;
        } = { onboarding: true };

        if (!isPlatformWeb) {
            queryParams = { ...queryParams, mode: UPSERT_PROFILE_MODES.CREATE };
        }

        goTo(
            navigation,
            router,
            SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN,
            SOTT_DEFAULT_SCREENS.UPSERT_PROFILE,
            SOTT_DEFAULT_WEB_SCREENS.CreateProfile.getLink(),
            queryParams
        );
    },
    goToEditProfile: (navigation, router, _initialUrl, payload) => {
        const profileId = payload?.id ? String(payload.id) : undefined;
        let queryParams: {
            profileId?: string;

            onboarding: boolean;
            mode?: UPSERT_PROFILE_MODES;
        } = {
            profileId,
            onboarding: true,
        };

        if (!isPlatformWeb) {
            queryParams = { ...queryParams, mode: UPSERT_PROFILE_MODES.EDIT };
        }

        goTo(
            navigation,
            router,
            SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN,
            SOTT_DEFAULT_SCREENS.UPSERT_PROFILE,
            SOTT_DEFAULT_WEB_SCREENS.ProfileEdit.getLink({ profileId: profileId || '' }),
            queryParams
        );
    },
    goToOnboardingIntroPage: (navigation, router) => {
        goTo(
            navigation,
            router,
            SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN,
            SOTT_DEFAULT_SCREENS.ONBOARDING_INTRO,
            SOTT_DEFAULT_WEB_SCREENS.OnboardingIntro.getLink()
        );
    },
    goToAdditionalInfoPage: (navigation, router) => {
        if (router?.asPath && isRedirectFromOnboardingPaymentGate(router.asPath)) {
            goTo(navigation, router, undefined, undefined, {
                as: router.asPath,
                href: router.asPath,
            });
            return;
        }
        goTo(
            navigation,
            router,
            SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN,
            SOTT_DEFAULT_SCREENS.ADDITIONAL_INFO,
            SOTT_DEFAULT_WEB_SCREENS.AdditionalInfo.getLink()
        );
    },
    goToTermsAndConditionsPage: (navigation, router) => {
        goTo(
            navigation,
            router,
            SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN,
            SOTT_DEFAULT_SCREENS.TERMS_CONDITIONS,
            SOTT_DEFAULT_WEB_SCREENS.TermsAndConditions.getLink()
        );
    },
    goToRemoveDevicePage: (navigation, router) => {
        goTo(
            navigation,
            router,
            SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN,
            SOTT_DEFAULT_SCREENS.REMOVE_DEVICE,
            SOTT_DEFAULT_WEB_SCREENS.RemoveDevice.getLink(),
            { mode: MANAGE_DEVICES_MODES.ONBOARDING }
        );
    },
    goToWhoIsWatchingPage: (navigation, router) => {
        goTo(
            navigation,
            router,
            SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN,
            SOTT_DEFAULT_SCREENS.WHO_IS_WATCHING,
            SOTT_DEFAULT_WEB_SCREENS.WhoIsWatching.getLink()
        );
    },
    goToEnterPinPage: (navigation, router) => {
        goTo(
            navigation,
            router,
            SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN,
            SOTT_DEFAULT_SCREENS.ENTER_PIN,
            SOTT_DEFAULT_WEB_SCREENS.EnterPin.getLink()
        );
    },
    goToHomePage: (navigation, router, initialUrl, payload) => {
        let actionsObject: CustomActions = { shouldSkip: false };
        if (payload) {
            actionsObject = {
                expectedProductScreen: payload?.expectedProductScreen as
                    | PRODUCT_SCREENS
                    | undefined,
                shouldSkip: payload?.shouldSkip as boolean,
            };
        }

        if (
            initialUrl &&
            initialUrl.as !== '/' &&
            !actionsObject.shouldSkip &&
            !isRedirectFromOnboardingPaymentGate(initialUrl.as) &&
            !isRedirectFromOnboardingIntro(initialUrl.as)
        ) {
            goTo(navigation, router, undefined, undefined, initialUrl);
            return;
        }
        goTo(
            navigation,
            router,
            SOTT_DEFAULT_ROOT_SCREENS.MENU_STACK_SCREEN,
            SOTT_DEFAULT_SCREENS.HOME,
            SOTT_DEFAULT_WEB_SCREENS.Home.getLink()
        );
    },

    goToCreatePinPage: (navigation, router) => {
        goTo(
            navigation,
            router,
            SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN,
            SOTT_DEFAULT_SCREENS.CREATE_PIN,
            SOTT_DEFAULT_WEB_SCREENS.CreatePin.getLink()
        );
    },

    goToAddSubscriptionPage: (navigation, router) => {
        if (router?.asPath && isRedirectFromOnboardingPaymentGate(router.asPath)) {
            goTo(navigation, router, undefined, undefined, {
                as: router.asPath,
                href: router.asPath,
            });
            return;
        }
        goTo(
            navigation,
            router,
            SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN,
            SOTT_DEFAULT_SCREENS.ADD_SUBSCRIPTION,
            SOTT_DEFAULT_WEB_SCREENS.AddSubscription.getLink()
        );
    },
    goToBroadcastFromReminder: (navigation, _router, _initialUrl, payload) => {
        if (payload) {
            const { assetId: id, assetType: type, channelId, start, end } = payload;
            const asset = {
                id,
                type,
                channelId,
                start,
                end,
            };
            goTo(
                navigation,
                undefined,
                SOTT_DEFAULT_ROOT_SCREENS.MENU_STACK_SCREEN,
                SOTT_DEFAULT_SCREENS.HOME,
                undefined,
                {
                    screen: SOTT_DEFAULT_SCREENS.DETAILS,
                    params: {
                        id,
                        type,
                        asset,
                    },
                }
            );
        }
    },
    goToMvpdSignIn: (navigation, router) => {
        goTo(
            navigation,
            router,
            SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN,
            SOTT_DEFAULT_SCREENS.MVPD_SIGNIN,
            SOTT_DEFAULT_WEB_SCREENS.MvpdSignIn.getLink(),
            { didInitialise: false, shouldSignOut: false }
        );
    },
    goToDetailsPage: async (navigation, _router, _initialUrl, payload) => {
        const url = await getUrl();
        if (url?.includes('details/') && navigation) {
            const { type, id } = parseUrl(url);
            if (id && type) {
                navigation.reset({
                    index: 0,
                    routes: [
                        {
                            name: SOTT_DEFAULT_ROOT_SCREENS.MENU_STACK_SCREEN,
                            params: {
                                screen: 'home',
                                params: {
                                    screen: SOTT_DEFAULT_SCREENS.DETAILS,
                                    params: {
                                        id,
                                        type,
                                    },
                                },
                            },
                        },
                    ],
                });
            }
        } else if (payload) {
            const { assetId, assetType } = payload;
            goTo(
                navigation,
                undefined,
                SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN,
                SOTT_DEFAULT_SCREENS.HOME,
                undefined,
                {
                    screen: SOTT_DEFAULT_SCREENS.DETAILS,
                    params: {
                        assetId,
                        assetType,
                    },
                }
            );
        }
    },

    goToPlayerPage: async (navigation, _router, _initialUrl, payload, path) => {
        const { type, id } = await getParams(path);
        if (type && id && navigation) {
            goTo(
                navigation,
                undefined,
                SOTT_DEFAULT_ROOT_SCREENS.OTHER_SCREENS_STACK_SCREEN,
                SOTT_DEFAULT_SCREENS.PLAYBACK,
                undefined,
                {
                    assetId: id,
                    assetType: type,
                }
            );
        }
    },
};

export const defaultSequence: AppStartActionSequence = isFactorTv
    ? ['goToLandingPage', 'goToSignInPage', 'goToTermsAndConditionsPage', 'goToHomePage']
    : [
          'goToWelcomePage',
          'goToMvpdSignIn',
          'goToLandingPage',
          'goToSignInPage',
          'goToOnboardingIntroPage',
          'goToAdditionalInfoPage',
          'goToCreatePinPage',
          'goToCreateProfile',
          'goToEditProfile',
          'goToAddSubscriptionPage',
          'goToTermsAndConditionsPage',
          'goToRemoveDevicePage',
          'goToEnterPinPage',
          'goToWhoIsWatchingPage',
          'goToBroadcastFromReminder',
          'goToDetailsPage',
          'goToPlayerPage',
          'goToHomePage',
      ];
