import { Ratio } from '@24i/nxg-sdk-higgs';
import { getTelegramWebApp } from '@24i/nxg-sdk-higgs/src/utils';
import { SidebarStyles } from '../types';

const getStyles = (theme: any): SidebarStyles => ({
    list: {
        ...theme.sidebar.container,
        width: getTelegramWebApp() ? Ratio(88) : theme.sidebar.container.width,
        position: 'absolute',
        overflow: 'hidden',
        zIndex: 25,
    },
    channelCellContainer: {
        display: 'flex',
        marginHorizontal: Ratio(25),
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    text: {
        ...theme.sidebar.text,
    },
    logo: {
        ...theme.sidebar.logo,
        objectFit: 'contain',
        maxHeight: theme.cell.height,
    },
});

export default getStyles;
