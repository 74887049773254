import { Theme } from '@24i/nxg-sdk-photon';
import { getFont } from '@24i/nxg-sdk-higgs';

const getStyles = (theme: Theme) => ({
    pickerRow: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 16,
        marginHorizontal: 8,
        borderBottomWidth: 0.5,
        borderBottomColor: theme.color.contrast4,
    },
    pickerTitleText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h4'),
        paddingTop: 12,
    },
    currentLanguage: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    pickerButtonText: {
        color: theme.color.textPrimary,
        ...getFont(theme, 'h4'),
        paddingTop: 12,
    },
    expandMoreIcon: {
        paddingLeft: 4,
        opacity: 0.8,
        paddingTop: 12,
    },
    modalContent: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textContent: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    pickerItem: {
        color: theme.color.textPrimary,
        fontWeight: 'bold',
        opacity: 0.5,
        ...getFont(theme, 'h3'),
        paddingVertical: 12,
    } as const,
    selectedPickerItem: {
        color: theme.color.textPrimary,
        fontWeight: 'bold',
        ...getFont(theme, 'h3'),
        paddingVertical: 12,
    } as const,
    pickerSubtitleItem: {
        color: theme.color.textPrimary,
        opacity: 0.3,
        ...getFont(theme, 'h5'),
        paddingVertical: 12,
    } as const,
    selectedPickerSubtitleItem: {
        color: theme.color.textPrimary,
        opacity: 0.6,
        ...getFont(theme, 'h5'),
        paddingVertical: 12,
    } as const,
    scrollContainer: {
        margin: 'auto',
    },
});

export default getStyles;
