/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import { Text, View, Image } from '@24i/nxg-sdk-quarks';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { useTranslation } from 'react-i18next';
import { dateTimeText } from '@24i/nxg-core-utils';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { ELEMENT_PLACEMENT } from '@24i/nxg-sdk-photon/src';
import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { TitleViewProps } from '@24i/nxg-sdk-smartott/src/components/TitleView/types';
import AssetLabel from '@24i/nxg-sdk-smartott/src/components/AssetLabel';
import {
    getHeroBannerTestID,
    HERO_BANNER_TEST_IDS,
} from '@24i/nxg-sdk-smartott/src/components/BannerCarousel/test-utils';
import RateItem from '../components/RateItem';
import TextWithSeparator, { SEPARATORS } from '../components/TextWithSeparator';
import getTitleViewStyles, { getPlayerTitleView } from '../styles';
// @ts-ignore There is no general index for this component.
import Classifications from '../../Classifications';

const TitleView = (props: TitleViewProps): JSX.Element => {
    const {
        title = null,
        subtitle = null,
        rating = null,
        classifications = null,
        styles: getStyles = getTitleViewStyles,
        isInCarousel = false,
        startsAt = null,
        endsAt = null,
        style = {},
        children = null,
        channelLogo = null,
        infoLabel = null,
        childrenPlacement = null,
        testID = '',
        metadataTestID = '',
        isLiveEvent = false,
        assetLabel = null,
        index = 0,
        displayTimeOnly = false,
        showMetadata = true,
        variant,
        subtitleFontSize = 'h4',
    } = props;

    const { t } = useTranslation(['sott']);
    const { theme } = useTheme();
    const styles = getStyles(theme, variant);
    const showRatings = useFeature('row_item_show_rating', { enabled: true })?.enabled;
    const ratingNumber = Number(rating);
    const isRatingValid = ratingNumber && ratingNumber >= 0 && ratingNumber <= 5;
    const shouldShowRatings = isRatingValid && showRatings;

    const getTimeText = (start: number, end: number, is12HourClock: boolean): string => {
        let text = dateTimeText(
            new Date(start * 1000),
            new Date(end * 1000),
            is12HourClock,
            displayTimeOnly,
            isLiveEvent
        );
        if (isLiveEvent) {
            text = `${t('asset.liveEvent', { count: 1 })} · ${text}`;
        }
        return text;
    };

    return (
        <View style={[styles.holder, style]}>
            {infoLabel && (
                <View style={styles.infoLabelContainer}>
                    <Text style={styles.infoLabelText}>{infoLabel}</Text>
                </View>
            )}
            {!!channelLogo && (
                <Image
                    source={{ uri: channelLogo }}
                    resizeMode="contain"
                    style={styles.channelLogo}
                />
            )}
            {!!assetLabel && (
                <View
                    style={styles.assetLabelContainer}
                    testID={getHeroBannerTestID(HERO_BANNER_TEST_IDS.TAG, {
                        scopeIndex: index + 1,
                    })}
                >
                    <AssetLabel text={assetLabel} />
                </View>
            )}
            {showMetadata && (
                <Text style={styles.title} numberOfLines={isInCarousel ? 2 : 3} testID={testID}>
                    {title}
                </Text>
            )}

            <View>
                {!!!childrenPlacement && children}
                {!!startsAt && !!endsAt && (
                    <View testID={metadataTestID} style={styles.timeTextContainer}>
                        {showMetadata && (
                            <Text style={styles.timeText}>
                                {getTimeText(startsAt, endsAt, false)}
                            </Text>
                        )}
                        {endsAt * 1000 > +new Date() && startsAt * 1000 < +new Date() && (
                            <Text style={styles.liveBadge} numberOfLines={1}>
                                {t('asset.liveBadge')}
                            </Text>
                        )}
                    </View>
                )}
                {!!(showMetadata && (!!subtitle?.length || isRatingValid) && !classifications) && (
                    <TextWithSeparator
                        separator={SEPARATORS.DOT}
                        variant={variant}
                        subtitleFontSize={subtitleFontSize}
                    >
                        {subtitle}
                        {shouldShowRatings && <RateItem rating={ratingNumber} />}
                    </TextWithSeparator>
                )}
                {!!(showMetadata && (!!subtitle?.length || isRatingValid) && classifications) && (
                    <Classifications classifications={classifications}>
                        <TextWithSeparator
                            separator={SEPARATORS.DOT}
                            variant={variant}
                            subtitleFontSize={subtitleFontSize}
                        >
                            {subtitle}
                            {shouldShowRatings && <RateItem rating={ratingNumber} />}
                        </TextWithSeparator>
                    </Classifications>
                )}
            </View>
            {childrenPlacement === ELEMENT_PLACEMENT.BELLOW && children}
        </View>
    );
};

const PlayerTitleView = (props) => <TitleView {...props} styles={getPlayerTitleView} />;

export { PlayerTitleView, getPlayerTitleView as getPlayerTitleViewStyles, getTitleViewStyles };
export default overridable(TitleView, 'TitleView');
