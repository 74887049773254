import { ReactNode } from 'react';

export interface WithWebHeaderProps {
    webHeaderTitle?: string | null;
    backButtonTitle?: string | null;
    renderActionButton?: () => ReactNode;
    fullWidth?: boolean;
    hasMargin?: boolean;
    /* these force serve the purpose of extending the usage of webHeader to "special situations"
    where the header is not to be shown by default (due to it's primary usage) but are required
    to be shown elsewhere. */
    forceShowHeader?: boolean;
    forceDisable?: boolean;
    onBackPressOverride?: () => void;
    variant?: 'sticky';
    noVerticalMargin?: boolean;
    isLoading?: boolean;
    isBackButtonHeaderText?: boolean;
}

export enum WEB_HEADER_TEST_IDS {
    PAGE_TITLE = 'page_title',
}
