import { getFont } from '@24i/nxg-sdk-higgs';
import { Theme } from '@24i/nxg-sdk-photon/src';
import { LanguageScreenStyles } from '../types';

const getSettingsLanguageStyles = (theme: Theme): LanguageScreenStyles => ({
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingVertical: 0,
        height: 'auto',
        width: '70%',
        marginBottom: 10,
    },
    buttonContainerLandscape: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingVertical: 0,
        height: 'auto',
        width: '70%',
        marginHorizontal: 32,
        marginBottom: 10,
    },
    buttonText: {
        marginHorizontal: 8,
        marginBottom: 0,
        ...getFont(theme, 'h4'),
    },
    buttonIcon: {
        fontSize: getFont(theme, 'h4').fontSize,
        marginHorizontal: 0,
        marginRight: 8,
    },
    selectContainer: {
        marginTop: 40,
        alignSelf: 'stretch',
    },
});

export default getSettingsLanguageStyles;
