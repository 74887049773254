import { ANALYTICS_TRIGGERS } from '@24i/nxg-sdk-smartott-shared/src/analytics/constants';
import { SOTT_DEFAULT_SCREENS } from '@24i/nxg-sdk-smartott/src/navigation/constants';
import { VideoStartResult, Payload } from '../types';
import { EVENTS } from '../constants';
import { mapBase } from './mapBase';

export const mapVideoStart = (
    triggerName: ANALYTICS_TRIGGERS,
    payload: Payload
): VideoStartResult => {
    const assetDuration = payload.payload.duration;
    const cwOffset = payload.payload.asset.continueWatchingOffset || 0;
    let progressPct = 0;
    if (assetDuration !== 0 && cwOffset !== 0)
        progressPct = Math.round(parseFloat(((cwOffset / assetDuration) * 100).toFixed(2)));
    return {
        ...mapBase(payload),
        action: EVENTS.VIDEO_START,
        event_trigger: triggerName,
        thing_id: payload.payload.asset?.id || '',
        duration: assetDuration,
        progress_pct: progressPct,
        source_page: SOTT_DEFAULT_SCREENS.PLAYBACK,
        source_rail: payload.payload.asset.sectionLabel,
    };
};
