import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useTheme } from '@24i/nxg-sdk-higgs';
import { View } from '@24i/nxg-sdk-quarks';
import { overridable } from '@24i/nxg-sdk-gluons/src/context/ComponentOverrides';
import { NavScreenConfigWeb } from '@24i/nxg-sdk-gluons/src/clients/AppNavigationClient';
import { isWebView, getTelegramWebAppBottomSafeAreaInset } from '@24i/nxg-sdk-higgs/src/utils';
import { getBottomTabBarStyles } from './styles/index.web';
import { TopBarMenuItem } from '../../../components/MenuItem';
import { useMenuItemPress } from '../../utils';

const DEFAULT_TAB_BAR_HEIGHT = 51;
const WEB_VIEW_TAB_BAR_HEIGHT = 85;
const WEB_VIEW_TAB_BAR_MARGIN = '-20px';

// TODO: Type
const BottomTabBar = ({ styles: getStyles = getBottomTabBarStyles, menuItems = [] }: any) => {
    const router = useRouter();
    const { theme } = useTheme();
    const bottomMenuRef = useRef(null);
    const { t } = useTranslation(['sott']);
    const onPress = useMenuItemPress();
    const isInWebView = isWebView();
    const [bottom, setBottom] = useState('0px');
    const safeAreaInsetBottom = getTelegramWebAppBottomSafeAreaInset();
    const height = isInWebView ? WEB_VIEW_TAB_BAR_HEIGHT : DEFAULT_TAB_BAR_HEIGHT;

    const styles = getStyles(theme);

    useEffect(() => {
        const onScroll = () => {
            const {
                documentElement: { scrollTop, clientHeight, scrollHeight },
            } = document;
            const scrollValue = scrollTop + clientHeight - scrollHeight;
            if (scrollValue < 0) {
                setBottom('0px');
            } else {
                setBottom(
                    `${scrollValue > safeAreaInsetBottom ? -safeAreaInsetBottom : -scrollValue}px`
                );
            }
        };

        if (safeAreaInsetBottom > 0) {
            window.addEventListener('scroll', onScroll);
        }
        return () => window.removeEventListener('scroll', onScroll);
    }, [safeAreaInsetBottom]);

    const getMenuItemComponent = (menuItem: NavScreenConfigWeb, index) => {
        const { icon, menuTitle, name, optionsWeb: options } = menuItem;

        let titleLabel;
        if (options?.showTitle !== false) {
            const lowerCaseName = name.toLowerCase();
            if (lowerCaseName === 'searching' || lowerCaseName === 'search') {
                titleLabel = t(`search.title`);
            } else {
                titleLabel = t(`menu.${menuTitle}`);
            }
        } else {
            titleLabel = '';
        }

        return (
            <View key={`bottommenu-link-${name}`}>
                <TopBarMenuItem
                    key={`bottommenu-${menuTitle}`}
                    title={titleLabel}
                    icon={icon}
                    index={index}
                    active={router.asPath.includes(name)}
                    spatial
                    onPress={() => onPress(menuItem)}
                    variant="bottom-menu"
                />
            </View>
        );
    };

    return (
        <View
            ref={bottomMenuRef}
            style={{
                ...styles.menuContainer,
                height,
                bottom,
            }}
        >
            <View style={{ ...styles.sectionContainer, maxHeight: height }}>
                <View
                    style={{
                        ...styles.section,
                        ...(safeAreaInsetBottom && {
                            marginTop: WEB_VIEW_TAB_BAR_MARGIN,
                        }),
                    }}
                >
                    {menuItems.map(getMenuItemComponent)}
                </View>
            </View>
        </View>
    );
};

export default overridable(BottomTabBar, 'BottomTabBar');
